import React from "react";
import {
  Box,
  Typography,
  Link,
  TextField,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
} from "@mui/material";
import { Language, Check } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const AppearanceSettings = ({
  brandColor,
  onBrandColorChange,
  chartStyle,
  onChartStyleChange,
  cookieBanner,
  onCookieBannerChange,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mt: 4 }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          {t("settings.appearance.title")}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {t("settings.appearance.subtitle")}
        </Typography>
        <Link
          href="dashboard.untitledui.com"
          target="_blank"
          sx={{
            color: "text.secondary",
            display: "flex",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          dashboard.untitledui.com
          <Box component="span" sx={{ fontSize: "1.2rem" }}>
            ↗
          </Box>
        </Link>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle1" gutterBottom>
          {t("settings.appearance.brandColor.title")}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {t("settings.appearance.brandColor.subtitle")}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, mt: 1 }}>
          <Box
            sx={{
              width: 42,
              height: 42,
              borderRadius: 1,
              bgcolor: brandColor,
              cursor: "pointer",
            }}
          />
          <TextField
            value={brandColor}
            onChange={(e) => onBrandColorChange(e.target.value)}
            size="small"
            sx={{ width: 120 }}
          />
        </Box>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle1" gutterBottom>
          {t("settings.appearance.charts.title")}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {t("settings.appearance.charts.subtitle")}
        </Typography>
        <Link href="#" sx={{ color: "primary.main", display: "block", mb: 2 }}>
          {t("settings.appearance.charts.viewExamples")}
        </Link>
        <Grid container spacing={2}>
          {["default", "simplified", "custom"].map((style) => (
            <Grid item xs={12} sm={4} key={style}>
              <Card
                variant="outlined"
                sx={{
                  cursor: "pointer",
                  border: (theme) =>
                    chartStyle === style
                      ? `2px solid ${theme.palette.primary.main}`
                      : "1px solid rgba(0, 0, 0, 0.12)",
                  position: "relative",
                }}
                onClick={() => onChartStyleChange(style)}
              >
                <CardContent>
                  {chartStyle === style && (
                    <Check
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        color: "primary.main",
                      }}
                    />
                  )}
                  <Box
                    sx={{
                      height: 100,
                      bgcolor: "action.hover",
                      borderRadius: 1,
                      mb: 1,
                    }}
                  />
                  <Typography variant="subtitle2">
                    {t(`settings.appearance.charts.${style}.title`)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t(`settings.appearance.charts.${style}.desc`)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle1" gutterBottom>
          {t("settings.appearance.language.title")}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {t("settings.appearance.language.subtitle")}
        </Typography>
        <Select
          value={t("settings.appearance.language.value")}
          onChange={(e) => console.log(e.target.value)}
          size="small"
          sx={{ minWidth: 200, mt: 1 }}
          startAdornment={<Language sx={{ mr: 1, ml: -0.5 }} />}
        >
          <MenuItem value="en">English (UK)</MenuItem>
          <MenuItem value="pt">Português</MenuItem>
          <MenuItem value="es">Español</MenuItem>
        </Select>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle1" gutterBottom>
          {t("settings.appearance.cookies.title")}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {t("settings.appearance.cookies.subtitle")}
        </Typography>
        <Link href="#" sx={{ color: "primary.main", display: "block", mb: 2 }}>
          {t("settings.appearance.cookies.viewExamples")}
        </Link>
        <Grid container spacing={2}>
          {["default", "simplified", "none"].map((style) => (
            <Grid item xs={12} sm={4} key={style}>
              <Card
                variant="outlined"
                sx={{
                  cursor: "pointer",
                  border: (theme) =>
                    cookieBanner === style
                      ? `2px solid ${theme.palette.primary.main}`
                      : "1px solid rgba(0, 0, 0, 0.12)",
                  position: "relative",
                }}
                onClick={() => onCookieBannerChange(style)}
              >
                <CardContent>
                  {cookieBanner === style && (
                    <Check
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        color: "primary.main",
                      }}
                    />
                  )}
                  <Box
                    sx={{
                      height: 100,
                      bgcolor: "action.hover",
                      borderRadius: 1,
                      mb: 1,
                    }}
                  />
                  <Typography variant="subtitle2">
                    {t(`settings.appearance.cookies.${style}.title`)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t(`settings.appearance.cookies.${style}.desc`)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default AppearanceSettings;
