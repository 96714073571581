import React from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ProcessingGuide = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        p: 4,
        gap: 3,
        minHeight: '60vh'
      }}
    >
      <CircularProgress size={80} thickness={4} />
      
      <Typography variant="h5" component="h2" sx={{ mt: 3 }}>
        {t('processingGuide.title')}
      </Typography>
      
      <Typography variant="body1" color="text.secondary" sx={{ maxWidth: '600px', mb: 2 }}>
        {t('processingGuide.description')}
      </Typography>

      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => navigate('/dashboard')}
        sx={{ mt: 2 }}
      >
        {t('processingGuide.goToDashboard')}
      </Button>
    </Box>
  );
};

export default ProcessingGuide;
