import { useState, useEffect } from 'react';
import { ref, get, child, getDatabase } from 'firebase/database';
import { useProperty } from '../contexts/PropertyContext';

export const usePropertyAccess = (propertyId) => {
  const [accessData, setAccessData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentLanguage } = useProperty();

  useEffect(() => {
    const fetchAccessData = async () => {
      setLoading(true);
      setError(null);
      setAccessData(null);

      if (!propertyId || propertyId === 'undefined') {
        console.error('Invalid propertyId:', propertyId);
        setError('Property ID is required');
        setLoading(false);
        return;
      }

      try {
        console.log('Fetching access data for property:', propertyId);
        
        const dbRef = ref(getDatabase());
        const propertyPath = `properties/${propertyId}`;
        
        console.log('Fetching from path:', propertyPath);
        const propertySnapshot = await get(child(dbRef, propertyPath));
        
        if (propertySnapshot.exists()) {
          const propertyData = propertySnapshot.val();
          console.log('Property data found:', {
            hasAccessKey: !!propertyData.accessKey,
            hasLanguages: !!propertyData.languages,
            currentLanguage
          });
          
          if (propertyData.accessKey) {
            // Estrutura os dados de acesso
            const accessInfo = {
              wifi: {
                networkName: propertyData.accessKey.networkName,
                networkPassword: propertyData.accessKey.networkPassword,
              },
              electronicKey: {
                code: propertyData.accessKey.eKey,
                instructions: propertyData.languages?.[currentLanguage]?.property_config?.electronicKey?.instructions || [],
                notes: propertyData.languages?.[currentLanguage]?.property_config?.electronicKey?.notes || [],
              }
            };

            console.log('Access data structured:', {
              hasWifi: !!accessInfo.wifi.networkName,
              hasEKey: !!accessInfo.electronicKey.code,
              instructionsCount: accessInfo.electronicKey.instructions.length
            });

            setAccessData(accessInfo);
          } else {
            console.warn('No access key found for property:', propertyId);
            setError('No access information available');
          }
        } else {
          console.error('Property not found:', propertyId);
          setError('Property not found');
        }
      } catch (err) {
        console.error('Error fetching access data:', {
          error: err.message,
          propertyId
        });
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAccessData();
  }, [propertyId, currentLanguage]);

  return { accessData, loading, error };
};
