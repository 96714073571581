import React from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  Chip,
  Avatar,
  Divider,
  Stack,
  useTheme,
} from "@mui/material";
import {
  People as PeopleIcon,
  Sync as SyncIcon,
  Edit as EditIcon,
  CheckCircleOutline,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomTooltip from "../common/CustomTooltip";
import { useProperty } from "../../contexts/PropertyContext";
import ConfirmationDialog from "../common/ConfirmationDialog";

const GuideCard = ({
  guide,
  onManageUsers,
  onSync,
  onSelectGuide,
  currentPropertyId,
  onDelete,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { properties } = useProperty();
  const property = properties?.[guide.id];
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState(false);

  const handleSyncClick = (e) => {
    e.stopPropagation();
    setOpenConfirmation(true);
  };

  const handleConfirmationClose = (confirmed) => {
    setOpenConfirmation(false);
    if (confirmed) {
      onSync(guide);
    }
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setOpenDeleteConfirmation(true);
  };

  const handleDeleteConfirmationClose = (confirmed) => {
    setOpenDeleteConfirmation(false);
    if (confirmed) {
      onDelete(guide);
    }
  };

  return (
    <Card
      sx={{
        height: "100%",
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        border:
          currentPropertyId === guide.id
            ? `2px solid ${theme.palette.primary.main}`
            : "none",
        transform: currentPropertyId === guide.id ? "scale(1.02)" : "none",
        position: "relative",
        "&:hover": {
          transform: "scale(1.02)",
          boxShadow: 3,
        },
      }}
      onClick={() => onSelectGuide(guide.id)}
    >
      {currentPropertyId === guide.id && (
        <CheckCircleOutline
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            zIndex: 1,
            color: theme.palette.primary.main,
          }}
        />
      )}
      <CardContent sx={{ p: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          {guide.image && (
            <Avatar
              variant="rounded"
              src={guide.image}
              sx={{
                width: 80,
                height: 80,
                mr: 2,
                borderRadius: 2,
              }}
            />
          )}

          <Box flex={1} sx={{ minWidth: 0 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  mr: 2,
                  whiteSpace: "normal",
                }}
              >
                {guide.title}
              </Typography>
            </Box>
            {property?.status && (
              <Chip
                label={property.status}
                color="default"
                size="small"
                sx={{ mb: 1 }}
              />
            )}
          </Box>
        </Box>

        <Box sx={{ position: "relative", mb: 2 }}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxHeight: "3em",
            }}
          >
            {guide.description && guide.description.length > 200
              ? `${guide.description.slice(0, 200)}...`
              : guide.description || "Sem descrição"}
          </Typography>
        </Box>

        <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 2 }}>
          <Chip
            size="small"
            label={guide.propertyType}
            color="primary"
            variant="outlined"
          />
          <Typography variant="body2" color="text.secondary">
            {guide.location}
          </Typography>
        </Stack>
      </CardContent>
      <ConfirmationDialog
        open={openConfirmation}
        onClose={handleConfirmationClose}
        title={t("settings.guide.syncConfirmationTitle")}
        message={t("settings.guide.syncConfirmationMessage")}
        confirmText={t("settings.guide.syncConfirm")}
        cancelText={t("settings.guide.syncCancel")}
      />
      <ConfirmationDialog
        open={openDeleteConfirmation}
        onClose={handleDeleteConfirmationClose}
        title={t("settings.guide.deleteConfirmationTitle")}
        message={t("settings.guide.deleteConfirmationMessage")}
        confirmText={t("settings.guide.deleteConfirm")}
        cancelText={t("settings.guide.deleteCancel")}
      />
      <Divider />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          pt: 1,
        }}
      >
        <Typography variant="caption" color="text.secondary">
          {t("settings.guide.lastUpdate")}:{" "}
          {new Date(guide.lastUpdate).toLocaleDateString()}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 1,
          }}
        >
          {currentPropertyId === guide.id && (
            <>
              <CustomTooltip title={t("settings.guide.editGuide")}>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/management/`);
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </CustomTooltip>
              <CustomTooltip title={t("settings.guide.deleteGuide")}>
                <IconButton size="small" onClick={handleDeleteClick}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </CustomTooltip>
              <CustomTooltip title={t("settings.guide.manageUsers")}>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    onManageUsers(guide);
                  }}
                >
                  <PeopleIcon fontSize="small" />
                </IconButton>
              </CustomTooltip>

              <CustomTooltip title={t("settings.guide.synced")}>
                <IconButton size="small" onClick={handleSyncClick}>
                  <SyncIcon fontSize="small" />
                </IconButton>
              </CustomTooltip>
            </>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default GuideCard;
