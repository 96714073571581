import React, { useState } from "react";
import { Box, Typography, IconButton, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Home as HomeIcon,
  ContentCopy as ContentCopyIcon,
  Check as CheckIcon,
} from "@mui/icons-material";
import CustomTooltip from "../common/CustomTooltip";

const AddressDisplay = ({ addressText }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [copied, setCopied] = useState(false);

  const handleCopyAddress = async () => {
    try {
      await navigator.clipboard.writeText(addressText);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1.5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: theme.palette.primary.main,
            borderRadius: 1,
            p: 1,
            color: "white",
          }}
        >
          <HomeIcon fontSize="small" />
        </Box>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            color: theme.palette.text.primary,
            flex: 1,
          }}
        >
          {t("location.address")}
        </Typography>
        <CustomTooltip
          title={
            copied
              ? t("location.copied", "Endereço copiado!")
              : t("location.copyAddress", "Copiar endereço")
          }
        >
          <IconButton
            onClick={handleCopyAddress}
            size="small"
            sx={{
              color: copied
                ? theme.palette.success.main
                : theme.palette.text.secondary,
              transition: "all 0.2s",
              "&:hover": {
                bgcolor: copied
                  ? `${theme.palette.success.main}15`
                  : theme.palette.action.hover,
              },
            }}
          >
            {copied ? (
              <CheckIcon fontSize="small" />
            ) : (
              <ContentCopyIcon fontSize="small" />
            )}
          </IconButton>
        </CustomTooltip>
      </Box>

      <Box
        sx={{
          display: "grid",
          gap: 1,
          pl: "44px", // Alinhado com o texto após o ícone
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.primary,
              fontWeight: 500,
            }}
          >
            Rua Example, 123
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.secondary,
            }}
          >
            Neighborhood
          </Typography>
        </Box>

        <Typography
          variant="body2"
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          City, State - ZIP
        </Typography>

        <Typography
          variant="body2"
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          Country
        </Typography>
      </Box>
    </Box>
  );
};

export default AddressDisplay;
