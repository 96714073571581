import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  useTheme,
  CircularProgress,
  Alert,
  Paper,
  useMediaQuery,
} from "@mui/material";
import {
  ContentCopy as ContentCopyIcon,
  Check as CheckIcon,
  Share as ShareIcon,
  Wifi as WifiIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useProperty } from "../contexts/PropertyContext";
import { usePropertyAccess } from "../hooks/usePropertyAccess";
import { QRCodeSVG } from "qrcode.react";
import CustomSnackbar from "./common/CustomSnackbar";
import CustomTooltip from "./common/CustomTooltip";
import DialogHeader from "./common/DialogHeader";

const WifiDialog = ({ open, onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [codeCopied, setCodeCopied] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const { currentPropertyId } = useProperty();
  const { accessData, loading, error } = usePropertyAccess(currentPropertyId);

  const handleCopyCode = () => {
    if (accessData?.wifi?.networkPassword) {
      navigator.clipboard.writeText(accessData.wifi.networkPassword);
      setCodeCopied(true);
      setSnackbarMessage(
        t("common.copiedToClipboard", "Copiado para a área de transferência!")
      );
      setSnackbarOpen(true);
      setTimeout(() => setCodeCopied(false), 3000);
    }
  };

  const handleShare = async () => {
    if (accessData?.wifi) {
      try {
        await navigator.share({
          title: t("wifi.shareTitle", "Dados do Wi-Fi"),
          text: `${t("wifi.network", "Rede")}: ${
            accessData.wifi.networkName
          }\n${t("wifi.password", "Senha")}: ${
            accessData.wifi.networkPassword
          }`,
        });
        setSnackbarMessage(
          t("wifi.shared", "Dados compartilhados com sucesso!")
        );
        setSnackbarOpen(true);
      } catch (error) {
        if (error.name !== "AbortError") {
          setSnackbarMessage(
            t("wifi.shareError", "Erro ao compartilhar os dados")
          );
          setSnackbarOpen(true);
        }
      }
    }
  };

  const handleClose = () => {
    setCodeCopied(false);
    onClose();
  };

  const generateQRCode = () => {
    if (!accessData?.wifi?.networkName || !accessData?.wifi?.networkPassword)
      return null;
    const { networkName: ssid, networkPassword: password } = accessData.wifi;
    const encryption = "WPA";
    return `WIFI:S:${ssid};T:${encryption};P:${password};;`;
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        maxWidth="sm"
        fullWidth
      >
        <DialogHeader
          title={t("wifi.title", "Wi-Fi")}
          icon={WifiIcon}
          onClose={handleClose}
        />

        <DialogContent dividers>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="200px"
            >
              <CircularProgress sx={{ color: "#FF385C" }} />
            </Box>
          ) : error ? (
            <Alert severity="error">{error}</Alert>
          ) : accessData?.wifi ? (
            <Paper
              elevation={0}
              sx={{
                p: 3,
                borderRadius: 2,
                bgcolor: "background.paper",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* QR Code Section */}
              <Box
                sx={{
                  p: 3,
                  mb: 2,
                  bgcolor: "#fff",
                  borderRadius: 2,
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  border: "3px solid #FF385C",
                }}
              >
                <QRCodeSVG
                  value={generateQRCode()}
                  size={140}
                  level="H"
                  bgColor="#FFFFFF"
                  fgColor="#FF385C"
                />
              </Box>

              {/* Password Section */}
              <Box sx={{ width: "100%", mb: 3 }}>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  gutterBottom
                >
                  {t("wifi.password", "Senha")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    bgcolor: "#FFF1F1",
                    borderRadius: 2,
                    p: 2,
                    mt: 1,
                  }}
                >
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{
                      letterSpacing: 4,
                      color: "#FF385C",
                      fontWeight: 500,
                      fontSize: "1.75rem",
                    }}
                  >
                    {accessData.wifi.networkPassword}
                  </Typography>
                  <Box>
                    <CustomTooltip title={t("wifi.share", "Compartilhar")}>
                      <IconButton
                        onClick={handleShare}
                        size="small"
                        sx={{
                          color: "#FF385C",
                          mr: 1,
                        }}
                      >
                        <ShareIcon />
                      </IconButton>
                    </CustomTooltip>
                    <CustomTooltip
                      title={
                        codeCopied
                          ? t("common.copied", "Copiado")
                          : t("common.copy", "Copiar")
                      }
                    >
                      <IconButton
                        onClick={handleCopyCode}
                        size="small"
                        sx={{
                          color: "#FF385C",
                        }}
                      >
                        {codeCopied ? <CheckIcon /> : <ContentCopyIcon />}
                      </IconButton>
                    </CustomTooltip>
                  </Box>
                </Box>
              </Box>

              {/* Network Name Section */}
              <Box sx={{ width: "100%" }}>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  gutterBottom
                >
                  {t("wifi.network", "Nome da Rede")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    bgcolor: "#F5F5F5",
                    borderRadius: 2,
                    p: 2,
                    mt: 1,
                  }}
                >
                  <Typography
                    variant="body1"
                    component="div"
                    sx={{
                      color: "#424242",
                      fontWeight: 400,
                    }}
                  >
                    {accessData.wifi.networkName}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          ) : null}
        </DialogContent>

        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleClose} variant="outlined">
            {t("common.close", "Fechar")}
          </Button>
        </DialogActions>
      </Dialog>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity="success"
      />
    </>
  );
};

export default WifiDialog;
