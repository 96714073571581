import { database } from '../config/firebase';
import { ref, get, set, update, onValue } from 'firebase/database';

export const SUPPORTED_LANGUAGES = {
  ptbr: "Português (Brasil)",
  enus: "English (US)",
  esp: "Español"
};

export async function getUserLanguageSettings(uid) {
  const settingsRef = ref(database, `users/${uid}/global_settings/language`);
  const snapshot = await get(settingsRef);
  
  if (!snapshot.exists()) {
    // Configuração padrão
    const defaultSettings = {
      defaultLanguage: 'ptbr',
      enabledLanguages: ['ptbr'],
      lastUpdated: new Date().toISOString()
    };
    await set(settingsRef, defaultSettings);
    return defaultSettings;
  }
  
  return snapshot.val();
}

export async function updateUserLanguageSettings(uid, settings) {
  const settingsRef = ref(database, `users/${uid}/global_settings/language`);
  await update(settingsRef, {
    ...settings,
    lastUpdated: new Date().toISOString()
  });
}

export async function getPropertyDataByLanguage(uid, language, dataType) {
  const dataRef = ref(database, `users/${uid}/languages/${language}/${dataType}/current`);
  const snapshot = await get(dataRef);
  return snapshot.exists() ? snapshot.val() : null;
}

export async function savePropertyDataByLanguage(uid, language, dataType, data) {
  // Salva a versão atual
  const currentRef = ref(database, `users/${uid}/languages/${language}/${dataType}/current`);
  await set(currentRef, data);
  
  // Salva na história de versões
  const versionRef = ref(database, `users/${uid}/languages/${language}/${dataType}/versions/${new Date().getTime()}`);
  await set(versionRef, data);
}

// Função para escutar mudanças em tempo real
export function subscribeToPropertyData(uid, language, dataType, callback) {
  const dataRef = ref(database, `users/${uid}/languages/${language}/${dataType}/current`);
  return onValue(dataRef, (snapshot) => {
    callback(snapshot.exists() ? snapshot.val() : null);
  });
}
