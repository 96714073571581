import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const UserManagement = ({
  open,
  onClose,
  guide,
  newUserEmail,
  onNewUserEmailChange,
  onAddUser,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t("settings.users.title", { name: guide?.title })}
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" gutterBottom>
          {t("settings.users.currentUsers")}
        </Typography>
        <List>
          {guide?.users?.map((user, index) => (
            <React.Fragment key={user.email}>
              <ListItem>
                <ListItemText
                  primary={user.email}
                  secondary={t(`settings.users.roles.${user.role}`)}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" disabled={user.role === "admin"}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              {index < guide.users.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>

        <Box sx={{ mt: 4 }}>
          <Typography variant="subtitle1" gutterBottom>
            {t("settings.users.addUser")}
          </Typography>
          <TextField
            fullWidth
            label={t("settings.users.emailLabel")}
            value={newUserEmail}
            onChange={(e) => onNewUserEmailChange(e.target.value)}
            type="email"
            variant="outlined"
            size="small"
            sx={{ mt: 1 }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("common.cancel")}</Button>
        <Button
          onClick={onAddUser}
          variant="contained"
          disabled={!newUserEmail}
        >
          {t("settings.users.addButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserManagement;
