import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Info,
  LocationOn,
  LocalActivity,
  Wifi,
  LocalPolice as Emergency,
  Key,
} from "@mui/icons-material";
import WifiDialog from "../components/WifiDialog";
import LocationDialog from "../components/LocationDialog";
import EmergencyDialog from "../components/EmergencyDialog";
import ElectronicKeyDialog from "../components/ElectronicKeyDialog";
import PageHeader from "../components/PageHeader";

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "all 0.3s ease",
  cursor: "pointer",
  borderRadius: "16px",
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  "&:hover": {
    transform: "translateY(-4px)",
    borderColor: theme.palette.primary.main,
    boxShadow: `0 8px 24px ${theme.palette.primary.main}15`,
  },
}));

const IconWrapper = styled(Box)(({ theme, color }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 56,
  height: 56,
  borderRadius: "12px",
  backgroundColor: color + "15",
  marginBottom: theme.spacing(2),
  transition: "all 0.3s ease",
  "& .MuiSvgIcon-root": {
    fontSize: 28,
    color: color,
  },
}));

const Home = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const [wifiDialogOpen, setWifiDialogOpen] = useState(false);
  const [locationDialogOpen, setLocationDialogOpen] = useState(false);
  const [emergencyDialogOpen, setEmergencyDialogOpen] = useState(false);
  const [electronicKeyDialogOpen, setElectronicKeyDialogOpen] = useState(false);

  const handleOpenWifiDialog = () => {
    setWifiDialogOpen(true);
  };

  const handleCloseWifiDialog = () => {
    setWifiDialogOpen(false);
  };

  const handleOpenLocationDialog = () => {
    setLocationDialogOpen(true);
  };

  const handleCloseLocationDialog = () => {
    setLocationDialogOpen(false);
  };

  const handleOpenEmergencyDialog = () => {
    setEmergencyDialogOpen(true);
  };

  const handleCloseEmergencyDialog = () => {
    setEmergencyDialogOpen(false);
  };

  const handleOpenElectronicKeyDialog = () => {
    setElectronicKeyDialogOpen(true);
  };

  const handleCloseElectronicKeyDialog = () => {
    setElectronicKeyDialogOpen(false);
  };

  const cards = [
    {
      title: t("home.propertyInfo"),
      description: t("home.propertyInfoDesc"),
      icon: <Info />,
      path: "/property-info",
      color: theme.palette.primary.main,
      onClick: () => navigate("/property-info"),
    },
    {
      title: t("home.location"),
      description: t("home.locationDesc"),
      icon: <LocationOn />,
      color: theme.palette.success.main,
      onClick: handleOpenLocationDialog,
    },
    {
      title: t("propertyInfo.electronicKeyTitle", "Senha Eletrônica"),
      description: t(
        "propertyInfo.electronicKeySubtitle",
        "Código de acesso para as portas"
      ),
      icon: <Key />,
      color: theme.palette.warning.main,
      onClick: handleOpenElectronicKeyDialog,
    },
    {
      title: t("home.wifi"),
      description: t("home.wifiDesc"),
      icon: <Wifi />,
      color: theme.palette.info.main,
      onClick: handleOpenWifiDialog,
    },
    {
      title: t("home.localTips"),
      description: t("home.localTipsDesc"),
      icon: <LocalActivity />,
      path: "/local-tips",
      color: theme.palette.secondary.main,
      onClick: () => navigate("/local-tips"),
    },
    {
      title: t("home.emergency"),
      description: t("home.emergencyDesc"),
      icon: <Emergency />,
      color: theme.palette.error.main,
      onClick: handleOpenEmergencyDialog,
    },
  ];

  return (
    <Box>
      <PageHeader title={t("home.welcome")} subtitle={t("home.subtitle")} />

      <Container maxWidth="lg" sx={{ mt: -2 }}>
        <Grid container spacing={3}>
          {cards.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <StyledCard onClick={card.onClick}>
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    p: 3,
                  }}
                >
                  <IconWrapper color={card.color}>{card.icon}</IconWrapper>
                  <Typography gutterBottom variant="h6" component="h2">
                    {card.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {card.description}
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Container>

      <WifiDialog open={wifiDialogOpen} onClose={handleCloseWifiDialog} />

      <LocationDialog
        open={locationDialogOpen}
        onClose={handleCloseLocationDialog}
        location={{ lat: -23.55052, lng: -46.633308 }} // Example coordinates for São Paulo
      />

      <EmergencyDialog
        open={emergencyDialogOpen}
        onClose={handleCloseEmergencyDialog}
      />

      <ElectronicKeyDialog
        open={electronicKeyDialogOpen}
        onClose={handleCloseElectronicKeyDialog}
      />
    </Box>
  );
};

export default Home;
