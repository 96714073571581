import React from "react";
import {
  Box,
  IconButton,
  TextField,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  Edit as EditIcon,
  Close as CloseIcon,
  Save as SaveIcon,
  LocationOn as LocationOnIcon,
  Home as HomeIcon,
  Info as InfoOutlinedIcon,
} from "@mui/icons-material";
import { useTheme, alpha } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import ConfigSection from "./ConfigSection";
import CustomTooltip from "../common/CustomTooltip";

const LocationSection = ({
  locationForm,
  isEditingLocation,
  isSavingLocation,
  onLocationChange,
  onStartEditing,
  onCancelEditing,
  onSave,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <ConfigSection
      title={t("propertyManagement.location.title")}
      icon={<LocationOnIcon />}
      action={
        <Box>
          {!isEditingLocation ? (
            <CustomTooltip title={t("common.edit")}>
              <IconButton
                onClick={onStartEditing}
                size="large"
                sx={{
                  width: 32,
                  height: 32,
                  bgcolor: alpha(theme.palette.primary.main, 0.1),
                  color: "primary.main",
                  "&:hover": {
                    bgcolor: alpha(theme.palette.primary.main, 0.2),
                  },
                }}
              >
                <EditIcon fontsize="large" />
              </IconButton>
            </CustomTooltip>
          ) : (
            <Box sx={{ display: "flex", gap: 1 }}>
              <CustomTooltip title={t("common.cancel")}>
                <IconButton
                  onClick={onCancelEditing}
                  size="large"
                  disabled={isSavingLocation}
                  sx={{
                    width: 32,
                    height: 32,
                    bgcolor: alpha(theme.palette.text.secondary, 0.1),
                    color: "text.secondary",
                    "&:hover": {
                      bgcolor: alpha(theme.palette.text.secondary, 0.2),
                    },
                  }}
                >
                  <CloseIcon fontsize="large" />
                </IconButton>
              </CustomTooltip>
              <CustomTooltip title={t("common.save")}>
                <IconButton
                  onClick={onSave}
                  size="large"
                  disabled={isSavingLocation}
                  sx={{
                    width: 32,
                    height: 32,
                    bgcolor: alpha(theme.palette.error.main, 0.1),
                    color: "error.main",
                    "&:hover": {
                      bgcolor: alpha(theme.palette.error.main, 0.2),
                    },
                  }}
                >
                  {isSavingLocation ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <SaveIcon fontsize="large" />
                  )}
                </IconButton>
              </CustomTooltip>
            </Box>
          )}
        </Box>
      }
    >
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={3}>
          {/* Endereço */}
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="primary"
              sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}
            >
              <HomeIcon fontsize="large" />{" "}
              {t("propertyManagement.location.address")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <TextField
                  fullWidth
                  size="large"
                  label={t("propertyManagement.location.street")}
                  value={locationForm.address.street}
                  onChange={(e) =>
                    onLocationChange("address.street", e.target.value)
                  }
                  disabled={!isEditingLocation}
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  size="large"
                  label={t("propertyManagement.location.number")}
                  value={locationForm.address.number}
                  onChange={(e) =>
                    onLocationChange("address.number", e.target.value)
                  }
                  disabled={!isEditingLocation}
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="large"
                  label={t("propertyManagement.location.complement")}
                  value={locationForm.address.complement}
                  onChange={(e) =>
                    onLocationChange("address.complement", e.target.value)
                  }
                  disabled={!isEditingLocation}
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  size="large"
                  label={t("propertyManagement.location.neighborhood")}
                  value={locationForm.address.neighborhood}
                  onChange={(e) =>
                    onLocationChange("address.neighborhood", e.target.value)
                  }
                  disabled={!isEditingLocation}
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  size="large"
                  label={t("propertyManagement.location.city")}
                  value={locationForm.address.city}
                  onChange={(e) =>
                    onLocationChange("address.city", e.target.value)
                  }
                  disabled={!isEditingLocation}
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  size="large"
                  label={t("propertyManagement.location.state")}
                  value={locationForm.address.state}
                  onChange={(e) =>
                    onLocationChange("address.state", e.target.value)
                  }
                  disabled={!isEditingLocation}
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  size="large"
                  label={t("propertyManagement.location.country")}
                  value={locationForm.address.country}
                  onChange={(e) =>
                    onLocationChange("address.country", e.target.value)
                  }
                  disabled={!isEditingLocation}
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="large"
                  label={t("propertyManagement.location.postalCode")}
                  value={locationForm.address.postalCode}
                  onChange={(e) =>
                    onLocationChange("address.postalCode", e.target.value)
                  }
                  disabled={!isEditingLocation}
                  sx={{ mb: 2 }}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Referência */}
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="primary"
              sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}
            >
              <InfoOutlinedIcon fontsize="large" />{" "}
              {t("propertyManagement.location.reference")}
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={3}
              size="large"
              label={t("propertyManagement.location.referenceLabel")}
              value={locationForm.reference}
              onChange={(e) => onLocationChange("reference", e.target.value)}
              disabled={!isEditingLocation}
              sx={{ mb: 2 }}
            />
          </Grid>
        </Grid>
      </Box>
    </ConfigSection>
  );
};

export default LocationSection;
