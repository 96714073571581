import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAbfSauS-VT9Xl2tSyGHgrVBAd8MxGYpO0",
  authDomain: "imob-host.firebaseapp.com",
  databaseURL: "https://imob-host-default-rtdb.firebaseio.com",
  projectId: "imob-host",
  storageBucket: "imob-host.appspot.com",
  messagingSenderId: "319731315823",
  appId: "1:319731315823:web:d63b21c74f8075f3f6c99a",
  measurementId: "G-8NK8XRHWBJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { app, database, auth, storage, analytics };
