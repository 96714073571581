import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { 
  getAuth, 
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  onAuthStateChanged
} from 'firebase/auth';
import { ref, get, set } from 'firebase/database';
import { database } from '../config/firebase';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();

  // Utility function for robust email normalization
  function normalizeEmail(email) {
    if (!email) return '';
    
    // Remove all whitespace
    let normalized = email.replace(/\s+/g, '');
    
    // Convert to lowercase
    normalized = normalized.toLowerCase();
    
    // Remove dots from Gmail addresses (Gmail ignores dots)
    if (normalized.includes('@gmail.com')) {
      normalized = normalized.replace(/\./g, '');
    }
    
    return normalized;
  }

  const checkUserRole = useCallback(async () => {
    if (!auth.currentUser) return null;
    
    try {
      const roleRef = ref(database, `users/${auth.currentUser.uid}/role`);
      const snapshot = await get(roleRef);
      if (!snapshot.exists()) {
        // If the role does not exist, set it to host by default
        await set(roleRef, 'host');
        return 'host';
      }
      return snapshot.val();
    } catch (error) {
      console.error('Error checking user role:', error);
      return null;
    }
  }, [auth.currentUser]);

  async function signIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function signOut() {
    return firebaseSignOut(auth);
  }

  async function hasGuestAccess(email, propertyId) {
    if (!email || !propertyId) {
      console.warn('hasGuestAccess: Missing email or propertyId');
      return false;
    }
    
    try {
      // Normalize email
      const normalizedEmail = normalizeEmail(email);
      
      // Check guest access directly
      const guestAccessRef = ref(database, 'guestAccess');
      const snapshot = await get(guestAccessRef);
      
      if (!snapshot.exists()) {
        console.warn(`No guest access entries found for email: ${normalizedEmail}`);
        return false;
      }

      let hasAccess = false;
      let matchedGuest = null;

      snapshot.forEach((guestSnapshot) => {
        const guestData = guestSnapshot.val();
        
        // Check if this guest entry matches the email
        if (guestData.email === normalizedEmail) {
          matchedGuest = guestData;
          const properties = guestData.properties || {};
          
          // Check if the specific property is in the guest's properties
          if (properties[propertyId]) {
            const accessData = properties[propertyId];
            const isActive = accessData.status === 'active';
            const isNotExpired = !accessData.accessExpires || 
                                 new Date(accessData.accessExpires) > new Date();
            
            hasAccess = isActive && isNotExpired;
          }
        }
      });

      if (!hasAccess && matchedGuest) {
        console.info(`Guest found but no active access for property ${propertyId}`, {
          email: normalizedEmail,
          matchedProperties: Object.keys(matchedGuest.properties || {})
        });
      }

      return hasAccess;
    } catch (error) {
      console.error('Detailed error checking guest access:', {
        error: error.message,
        email,
        propertyId
      });
      return false;
    }
  }

  async function getGuestProperties(email) {
    if (!email) {
      console.warn('getGuestProperties: Missing email');
      return [];
    }
    
    try {
      // Normalize email
      const normalizedEmail = normalizeEmail(email);
      
      // Check guest access directly
      const guestAccessRef = ref(database, 'guestAccess');
      const snapshot = await get(guestAccessRef);
      
      if (!snapshot.exists()) {
        console.warn(`No guest access entries found for email: ${normalizedEmail}`);
        return [];
      }

      const activeProperties = [];
      let matchedGuest = null;

      snapshot.forEach((guestSnapshot) => {
        const guestData = guestSnapshot.val();
        
        // Check if this guest entry matches the email
        if (guestData.email === normalizedEmail) {
          matchedGuest = guestData;
          const properties = guestData.properties || {};
          
          Object.entries(properties).forEach(([propertyId, accessData]) => {
            const isActive = accessData.status === 'active';
            const isNotExpired = !accessData.accessExpires || 
                                 new Date(accessData.accessExpires) > new Date();
            
            if (isActive && isNotExpired) {
              activeProperties.push({
                id: propertyId,
                ...accessData
              });
            }
          });
        }
      });

      if (activeProperties.length === 0 && matchedGuest) {
        console.info(`Guest found but no active properties`, {
          email: normalizedEmail,
          allProperties: Object.keys(matchedGuest.properties || {})
        });
      }

      return activeProperties;
    } catch (error) {
      console.error('Detailed error fetching guest properties:', {
        error: error.message,
        email
      });
      return [];
    }
  }

  async function getUserGuides() {
    if (!auth.currentUser) return [];
    
    try {
      const userGuidesRef = ref(database, `users/${auth.currentUser.uid}/properties`);
      const snapshot = await get(userGuidesRef);
      
      if (!snapshot.exists()) return [];
      
      const properties = snapshot.val();
      const propertyIds = Object.keys(properties);

      // Fetch full details for each property
      const propertyDetailsPromises = propertyIds.map(async (propertyId) => {
        const propertyRef = ref(database, `properties/${propertyId}`);
        const propertySnapshot = await get(propertyRef);
        
        if (propertySnapshot.exists()) {
          const propertyData = propertySnapshot.val();
          return {
            id: propertyId,
            ...properties[propertyId],
            ...propertyData
          };
        }
        
        return null;
      });

      const propertyDetails = await Promise.all(propertyDetailsPromises);
      
      // Filter out any null results (properties not found)
      return propertyDetails.filter(Boolean);
    } catch (error) {
      console.error('Error fetching user guides:', error);
      return [];
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const role = await checkUserRole();
        console.log('User role:', role); // Debug log
        setCurrentUser({
          ...user,
          email: normalizeEmail(user.email),
          isHost: role === 'host'
        });
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, [auth, checkUserRole]);

  const value = {
    currentUser,
    signIn,
    signOut,
    hasGuestAccess,
    getGuestProperties,
    getUserGuides,
    checkUserRole
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
