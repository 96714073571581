import { useState, useEffect } from "react";
import { database } from "../config/firebase";
import { ref, set, onValue } from "firebase/database";
import { useProperty } from "../contexts/PropertyContext";

export const usePropertyConfig = (propertyId) => {
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentLanguage } = useProperty();

  useEffect(() => {
    if (!propertyId) {
      setError("Property ID is required");
      setLoading(false);
      return;
    }

    console.log("Fetching config for property:", propertyId);
    const configRef = ref(database, `properties/${propertyId}`);

    const unsubscribe = onValue(
      configRef,
      (snapshot) => {
        try {
          const data = snapshot.val();
          console.log("Received data from Firebase:", {
            hasData: !!data,
            hasLanguages: !!data?.languages,
            currentLanguage,
          });

          if (data) {
            // Estrutura a configuração com dados base e específicos do idioma
            const processedConfig = {
              id: data.id,
              status: data.status,
              name: data.name,
              location: data.location,
              accessKey: {
                networkName: data.accessKey?.networkName || "",
                networkPassword: data.accessKey?.networkPassword || "",
                eKey: data.accessKey?.eKey || "",
              },
              // Dados específicos do idioma
              localizedConfig:
                data.languages?.[currentLanguage]?.property_config || {},
              // Dados da propriedade no idioma atual
              propertyData:
                data.languages?.[currentLanguage]?.property_data || {},
            };

            console.log("Processed config:", {
              id: processedConfig.id,
              hasAccessKey: !!processedConfig.accessKey,
              hasLocalizedConfig: !!Object.keys(processedConfig.localizedConfig)
                .length,
              hasPropertyData: !!Object.keys(processedConfig.propertyData)
                .length,
            });

            setConfig(processedConfig);
            setError(null);
          } else {
            // Configuração padrão caso não exista dados
            const defaultConfig = {
              id: propertyId,
              status: "inactive",
              name: "",
              location: "",
              accessKey: {
                networkName: "",
                networkPassword: "",
                eKey: "",
              },
              localizedConfig: {},
              propertyData: {},
            };
            console.log("Setting default config:", defaultConfig);
            setConfig(defaultConfig);
          }
        } catch (err) {
          console.error("Error loading property config:", {
            error: err.message,
            propertyId,
            currentLanguage,
          });
          setError(err.message);
        } finally {
          setLoading(false);
        }
      },
      (error) => {
        console.error("Firebase subscription error:", {
          error: error.message,
          propertyId,
        });
        setError(error.message);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [propertyId, currentLanguage]);

  // Função para atualizar a configuração
  const updateConfig = async (newConfig) => {
    if (!propertyId) {
      throw new Error("Property ID is required");
    }

    try {
      const configRef = ref(database, `properties/${propertyId}`);

      // Preserva a estrutura existente e atualiza apenas os campos necessários
      const updates = {
        ...newConfig,
        languages: {
          [currentLanguage]: {
            property_config: newConfig.localizedConfig,
            property_data: newConfig.propertyData,
          },
        },
      };

      await set(configRef, updates);
      console.log("Config updated successfully:", {
        propertyId,
        language: currentLanguage,
      });
    } catch (err) {
      console.error("Error updating config:", {
        error: err.message,
        propertyId,
      });
      throw err;
    }
  };

  return {
    config,
    loading,
    error,
    updateConfig,
  };
};
