import { useState, useEffect } from 'react';
import { auth } from '../config/firebase';
import { getUserLanguageSettings, getPropertyDataByLanguage } from '../utils/languageUtils';

export function useLanguage() {
  const [currentLanguage, setCurrentLanguage] = useState('ptbr');
  const [availableLanguages, setAvailableLanguages] = useState(['ptbr']);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadLanguageSettings = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          setLoading(false);
          return;
        }

        const settings = await getUserLanguageSettings(user.uid);
        setCurrentLanguage(settings.defaultLanguage);
        setAvailableLanguages(settings.enabledLanguages);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadLanguageSettings();
  }, []);

  const loadPropertyData = async (dataType) => {
    try {
      const user = auth.currentUser;
      if (!user) return null;

      return await getPropertyDataByLanguage(user.uid, currentLanguage, dataType);
    } catch (err) {
      setError(err.message);
      return null;
    }
  };

  return {
    currentLanguage,
    availableLanguages,
    loading,
    error,
    loadPropertyData,
    setCurrentLanguage
  };
}
