import React, { useState } from "react";
import { Button, Box, useTheme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  DirectionsCar as DirectionsIcon,
  LocationOn,
} from "@mui/icons-material";
import CustomSnackbar from "./common/CustomSnackbar";
import LocationMap from "./LocationDialog/LocationMap";
import AddressDisplay from "./LocationDialog/AddressDisplay";
import BaseDialog from "./common/BaseDialog";

const LocationDialog = ({
  open,
  onClose,
  location = { lat: -23.55052, lng: -46.633308 },
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const mapContainerStyle = {
    width: "100%",
    height: "300px",
    borderRadius: theme.shape.borderRadius,
  };

  const handleGetDirections = () => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${location.lat},${location.lng}`;
    window.open(url, "_blank");
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const actions = (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<DirectionsIcon />}
        onClick={handleGetDirections}
        fullWidth
        size="large"
        sx={{
          borderRadius: 1,
          py: 1,
          mb: 1,
        }}
      >
        {t("location.getDirections")}
      </Button>
    </>
  );

  return (
    <>
      <BaseDialog
        open={open}
        onClose={onClose}
        title={t("location.title", "Localização")}
        icon={LocationOn}
        useCustomHeader={true}
        actions={actions}
      >
        <Box sx={{ mb: 3 }}>
          <LocationMap location={location} containerStyle={mapContainerStyle} />
        </Box>
        <AddressDisplay />
      </BaseDialog>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={t("location.addressCopied")}
      />
    </>
  );
};

export default LocationDialog;
