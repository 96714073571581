import React from "react";
import {
  Box,
  Paper,
  Stack,
  Avatar,
  Typography,
  Button,
  Grid,
  Chip,
  Divider,
  Slider,
  useTheme,
  alpha,
} from "@mui/material";
import {
  Edit as EditIcon,
  InfoOutlined,
  CheckCircleOutline,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const AccountManagement = ({
  currentUserData,
  plans,
  guidesCount,
  onGuidesCountChange,
  guidesMarks,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box sx={{ mt: 4 }}>
      <Paper
        elevation={0}
        sx={{
          p: 3,
          mb: 3,
          border: "1px solid",
          borderColor: "divider",
          borderRadius: 1,
        }}
      >
        <Stack direction="row" spacing={3} alignItems="center">
          <Avatar
            sx={{
              width: 80,
              height: 80,
              bgcolor: "primary.main",
              fontSize: "2rem",
            }}
          >
            {currentUserData?.name?.charAt(0) || "U"}
          </Avatar>
          <Box flex={1}>
            <Typography variant="h6" gutterBottom>
              {currentUserData?.name || t("settings.account.unnamed")}
            </Typography>
            <Typography color="text.secondary" gutterBottom>
              {currentUserData?.email}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {t("settings.account.joinedOn")}: {" "}
              {new Date(currentUserData?.joinDate).toLocaleDateString()}
            </Typography>
          </Box>
          <Button
            variant="outlined"
            startIcon={<EditIcon />}
            sx={{
              borderRadius: "8px",
              textTransform: "none",
            }}
          >
            {t("settings.account.editProfile")}
          </Button>
        </Stack>

        <Divider sx={{ my: 3 }} />

        <Stack direction="row" spacing={3} justifyContent="space-between">
          <Box>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {t("settings.account.currentPlan")}
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              {currentUserData?.plan || t("settings.account.basicPlan")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {t("settings.account.nextBilling")}
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              {currentUserData?.nextBilling
                ? new Date(currentUserData.nextBilling).toLocaleDateString()
                : t("settings.account.noNextBilling")}
            </Typography>
          </Box>
        </Stack>
      </Paper>

      <Typography variant="h6" gutterBottom sx={{ mt: 6, mb: 3 }}>
        {t("settings.account.availablePlans")}
      </Typography>
      <Grid container spacing={3}>
        {plans.map((plan, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Paper
              elevation={0}
              sx={{
                height: "100%",
                position: "relative",
                transition: "all 0.2s ease-in-out",
                cursor: "pointer",
                border: "1px solid",
                borderColor: plan.current || plan.popular ? "primary.main" : "divider",
                borderRadius: 1,
                "&:hover": {
                  borderColor: "primary.main",
                  transform: "translateY(-4px)",
                },
                ...(plan.current && {
                  bgcolor: alpha(theme.palette.primary.main, 0.04),
                }),
              }}
            >
              {plan.popular && (
                <Chip
                  label={t("settings.account.popular")}
                  color="primary"
                  size="small"
                  sx={{
                    position: "absolute",
                    top: 16,
                    right: 16,
                    fontWeight: 500,
                  }}
                />
              )}

              <Box sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                  {plan.name}
                </Typography>

                {plan.discount && (
                  <Box sx={{ mb: 2 }}>
                    <Chip
                      label={`${plan.discount} OFF`}
                      color="error"
                      size="small"
                      sx={{ fontWeight: 500 }}
                    />
                  </Box>
                )}

                <Box sx={{ mb: 3, display: "flex", alignItems: "baseline" }}>
                  <Typography
                    variant="h4"
                    component="span"
                    fontWeight="700"
                    color="primary"
                  >
                    {typeof plan.price === "number"
                      ? `R$${plan.price.toFixed(2)}`
                      : plan.price}
                  </Typography>
                  {plan.period && (
                    <Typography
                      variant="subtitle1"
                      component="span"
                      color="text.secondary"
                      sx={{ ml: 1 }}
                    >
                      /{plan.period}
                    </Typography>
                  )}
                </Box>

                {plan.hasSlider && (
                  <Box sx={{ mt: 2, mb: 3 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      gutterBottom
                    >
                      {t("settings.account.guidesCount")}
                    </Typography>
                    <Slider
                      value={guidesCount}
                      onChange={(_, newValue) => onGuidesCountChange(newValue)}
                      step={null}
                      marks={guidesMarks}
                      min={1}
                      max={25}
                      sx={{
                        "& .MuiSlider-mark": {
                          height: 8,
                        },
                        "& .MuiSlider-markLabel": {
                          fontSize: "0.875rem",
                        },
                      }}
                    />
                  </Box>
                )}

                {plan.enterprise && (
                  <Box sx={{ mt: 2, mb: 3 }}>
                    <Typography
                      variant="body2"
                      color="primary"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <InfoOutlined fontSize="small" />
                      {t("settings.account.enterpriseDescription")}
                    </Typography>
                  </Box>
                )}

                <Divider sx={{ my: 3 }} />

                <Box component="ul" sx={{ p: 0, m: 0, listStyle: "none" }}>
                  {plan.features.map((feature, idx) => (
                    <Box
                      component="li"
                      key={idx}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        mb: 2,
                      }}
                    >
                      <CheckCircleOutline
                        fontSize="small"
                        sx={{ color: "success.main" }}
                      />
                      <Typography variant="body2">{feature}</Typography>
                    </Box>
                  ))}
                </Box>

                <Button
                  fullWidth
                  variant={plan.current ? "outlined" : "contained"}
                  color="primary"
                  sx={{
                    mt: 3,
                    textTransform: "none",
                    borderRadius: "8px",
                  }}
                >
                  {plan.current
                    ? t("settings.account.currentPlanButton")
                    : t("settings.account.upgradePlanButton")}
                </Button>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AccountManagement;
