import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Chip,
  Link,
  useTheme,
} from '@mui/material';
import {
  Place as PlaceIcon,
  Phone as PhoneIcon,
  Language as LanguageIcon,
  Delete as DeleteIcon,
  Star as StarIcon,
  LocalOffer as LocalOfferIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const LocalTipCard = ({ tip, onEdit, onDelete, disabled }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        height: "100%",
        bgcolor: "background.default",
        border: `1px solid ${theme.palette.divider}`,
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          transform: "translateY(-4px)",
          boxShadow: "0 12px 24px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            mb: 2,
          }}
        >
          <Box sx={{ flex: 1, mr: 2 }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                color: theme.palette.text.primary,
                mb: 1,
              }}
            >
              {tip.title}
            </Typography>
            {tip.category && (
              <Chip
                label={tip.category}
                size="small"
                sx={{
                  bgcolor: `${theme.palette.primary.main}15`,
                  color: theme.palette.primary.main,
                  mb: 1,
                }}
              />
            )}
          </Box>
          <Box>
            <IconButton
              size="small"
              onClick={() => onEdit(tip)}
              disabled={disabled}
              sx={{
                color: theme.palette.primary.main,
                "&:hover": {
                  bgcolor: `${theme.palette.primary.main}15`,
                },
                mr: 1
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => onDelete(tip.id)}
              disabled={disabled}
              sx={{
                color: theme.palette.error.main,
                "&:hover": {
                  bgcolor: `${theme.palette.error.main}15`,
                },
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              mb: 1,
            }}
          >
            <PlaceIcon fontSize="small" />
            {tip.address}
          </Typography>

          {tip.phoneNumber && (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                mb: 1,
              }}
            >
              <PhoneIcon fontSize="small" />
              <Link
                href={`tel:${tip.phoneNumber}`}
                sx={{
                  color: "inherit",
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                {tip.phoneNumber}
              </Link>
            </Typography>
          )}

          {tip.website && (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                mb: 1,
              }}
            >
              <LanguageIcon fontSize="small" />
              <Link
                href={tip.website}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  color: "inherit",
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                {t("localTips.visitWebsite")}
              </Link>
            </Typography>
          )}

          {tip.notes && (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mt: 2 }}
            >
              {tip.notes}
            </Typography>
          )}
        </Box>

        {(tip.discount?.discountInfo || tip.discount?.discountCode) && (
          <Box
            sx={{
              mt: 2,
              p: 2,
              bgcolor: `${theme.palette.primary.main}08`,
              borderRadius: 1,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                color: theme.palette.primary.main,
                mb: 1,
              }}
            >
              <LocalOfferIcon fontSize="small" />
              {t("localTips.discount")}
            </Typography>
            {tip.discount.discountInfo && (
              <Typography variant="body2" sx={{ mb: 1 }}>
                {tip.discount.discountInfo}
              </Typography>
            )}
            {tip.discount.discountCode && (
              <Chip
                label={tip.discount.discountCode}
                size="small"
                sx={{
                  bgcolor: theme.palette.background.paper,
                  border: `1px dashed ${theme.palette.primary.main}`,
                }}
              />
            )}
          </Box>
        )}

        {tip.rating && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              mt: 2,
            }}
          >
            <StarIcon
              sx={{
                fontSize: 20,
                color: theme.palette.warning.main,
              }}
            />
            <Typography
              variant="body2"
              sx={{ color: theme.palette.text.secondary }}
            >
              {tip.rating}
              {tip.ratingCount && (
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ color: theme.palette.text.disabled, ml: 0.5 }}
                >
                  ({tip.ratingCount})
                </Typography>
              )}
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default LocalTipCard;
