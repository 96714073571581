import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const EditTipDialog = ({ open, onClose, onSave, tip }) => {
  const { t } = useTranslation();
  const [editedTip, setEditedTip] = useState(null);

  useEffect(() => {
    if (tip) {
      setEditedTip({
        ...tip,
        notes: tip.notes || '',
        discount: {
          discountInfo: tip.discount?.discountInfo || '',
          discountCode: tip.discount?.discountCode || '',
        },
      });
    }
  }, [tip]);

  const handleChange = (field) => (event) => {
    if (field.includes('.')) {
      const [parent, child] = field.split('.');
      setEditedTip((prev) => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: event.target.value,
        },
      }));
    } else {
      setEditedTip((prev) => ({
        ...prev,
        [field]: event.target.value,
      }));
    }
  };

  const handleSubmit = () => {
    onSave(editedTip);
  };

  if (!tip || !editedTip) return null;

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="sm" 
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        pb: 1
      }}>
        <Typography variant="h6" component="div">
          {t('localTips.editTip')}
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ pt: 1 }}>
          <TextField
            fullWidth
            multiline
            rows={4}
            label={t('localTips.notes')}
            value={editedTip.notes}
            onChange={handleChange('notes')}
            sx={{ mb: 3 }}
          />
          <TextField
            fullWidth
            label={t('localTips.discountInfo')}
            value={editedTip.discount.discountInfo}
            onChange={handleChange('discount.discountInfo')}
            sx={{ mb: 3 }}
          />
          <TextField
            fullWidth
            label={t('localTips.discountCode')}
            value={editedTip.discount.discountCode}
            onChange={handleChange('discount.discountCode')}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button onClick={onClose} color="inherit">
          {t('common.cancel')}
        </Button>
        <Button 
          onClick={handleSubmit} 
          variant="contained" 
          color="primary"
          sx={{
            px: 3,
            borderRadius: 2,
          }}
        >
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTipDialog;
