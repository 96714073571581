import React from 'react';
import { Snackbar, Alert, styled } from '@mui/material';

const StyledAlert = styled(Alert)(({ theme, severity }) => ({
  width: '100%',
  '& .MuiAlert-icon': {
    fontSize: '24px',
  },
  '& .MuiAlert-message': {
    fontSize: '0.875rem',
    padding: '8px 0',
  },
  ...(severity === 'success' && {
    backgroundColor: '#E8F5E9',
    color: '#1B5E20',
  }),
  ...(severity === 'error' && {
    backgroundColor: '#FFEBEE',
    color: '#B71C1C',
  }),
  ...(severity === 'warning' && {
    backgroundColor: '#FFF3E0',
    color: '#E65100',
  }),
  ...(severity === 'info' && {
    backgroundColor: '#E3F2FD',
    color: '#0D47A1',
  }),
}));

const CustomSnackbar = ({
  open,
  onClose,
  message,
  severity = 'info',
  autoHideDuration = 6000,
  anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
  ...props
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      {...props}
    >
      <StyledAlert
        onClose={onClose}
        severity={severity}
        variant="filled"
        elevation={6}
      >
        {message}
      </StyledAlert>
    </Snackbar>
  );
};

export default CustomSnackbar;
