import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline, Box, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Navigation from './components/Navigation';
import Home from './pages/Home';
import PropertyInfo from './pages/PropertyInfo';
import LocalTips from './pages/LocalTips';
import Settings from './pages/Settings';
import AmenitiesPage from './pages/AmenitiesPage';
import PropertyManagement from './pages/PropertyManagement';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { PropertyProvider } from './contexts/PropertyContext';
import PrivateRoute from './components/PrivateRoute';
import LandingPage from './pages/LandingPage';
import HostLogin from './pages/Login';
import GuestLogin from './pages/GuestLogin';
import GuestAccess from './pages/GuestAccess';
import ResetPassword from './pages/ResetPassword';
import CreateGuide from './pages/CreateGuide';

const App = () => {
  const [darkMode, setDarkMode] = React.useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });

  React.useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  const { t } = useTranslation();

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: '#FF5A5F',
        light: '#FF7E82',
        dark: '#E00007',
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: '#00A699',
        light: '#00C5B7',
        dark: '#008F84',
        contrastText: '#FFFFFF',
      },
      background: {
        default: darkMode ? '#121212' : '#F7F7F7',
        paper: darkMode ? '#1E1E1E' : '#FFFFFF',
      },
      text: {
        primary: darkMode ? '#FFFFFF' : '#222222',
        secondary: darkMode ? '#B0B0B0' : '#717171',
      },
      divider: darkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
      action: {
        active: darkMode ? '#FFFFFF' : '#222222',
        hover: darkMode ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)',
        selected: darkMode ? 'rgba(255, 255, 255, 0.16)' : 'rgba(0, 0, 0, 0.08)',
      },
    },
    typography: {
      fontFamily: 'Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
      h1: {
        fontSize: '2.5rem',
        '@media (max-width:600px)': {
          fontSize: '2rem',
        },
        fontWeight: 600,
        letterSpacing: '-0.5px',
      },
      h2: {
        fontSize: '2rem',
        '@media (max-width:600px)': {
          fontSize: '1.75rem',
        },
        fontWeight: 600,
        letterSpacing: '-0.5px',
      },
      h3: {
        fontSize: '1.75rem',
        '@media (max-width:600px)': {
          fontSize: '1.5rem',
        },
        fontWeight: 600,
        letterSpacing: '-0.5px',
      },
      h4: {
        fontSize: '1.5rem',
        '@media (max-width:600px)': {
          fontSize: '1.25rem',
        },
        fontWeight: 600,
        letterSpacing: '-0.5px',
      },
      h5: {
        fontSize: '1.25rem',
        '@media (max-width:600px)': {
          fontSize: '1.1rem',
        },
        fontWeight: 600,
        letterSpacing: '-0.5px',
      },
      h6: {
        fontSize: '1rem',
        '@media (max-width:600px)': {
          fontSize: '0.9rem',
        },
        fontWeight: 600,
        letterSpacing: '-0.5px',
      },
      body1: {
        fontSize: '1rem',
        '@media (max-width:600px)': {
          fontSize: '0.9rem',
        },
        letterSpacing: '-0.25px',
        lineHeight: 1.5,
      },
      body2: {
        fontSize: '0.875rem',
        '@media (max-width:600px)': {
          fontSize: '0.8rem',
        },
        letterSpacing: '-0.25px',
        lineHeight: 1.5,
      },
    },
    shape: {
      borderRadius: 12,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            textTransform: 'none',
            fontWeight: 600,
            padding: '10px 24px',
            '@media (max-width:600px)': {
              padding: '8px 16px',
            },
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          },
          containedPrimary: {
            background: 'linear-gradient(to right, #FF5A5F, #FF385C)',
            '&:hover': {
              background: 'linear-gradient(to right, #FF385C, #FF1A1F)',
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 20,
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.04)',
            transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
            '&:hover': {
              transform: 'translateY(-4px)',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
            },
            border: '1px solid',
            borderColor: darkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.08)',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 20,
            backgroundImage: 'none',
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            borderRight: 'none',
            boxShadow: '4px 0 24px rgba(0, 0, 0, 0.08)',
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            margin: '4px 8px',
            width: 'auto',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: 12,
            fontWeight: 500,
            '&.MuiChip-filled': {
              backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.08)',
            },
          },
        },
      },
    },
  });

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const RootRedirect = () => {
    const { currentUser } = useAuth();
    return currentUser ? <Navigate to="/dashboard" replace /> : <LandingPage />;
  };

  const AppContent = () => {
    const { currentUser } = useAuth();
    
    return (
      <Box sx={{ display: 'flex', minHeight: '100vh', bgcolor: 'background.default' }}>
        {currentUser && <Navigation darkMode={darkMode} setDarkMode={toggleDarkMode} />}
        <Box 
          component="main" 
          sx={{ 
            flexGrow: 1, 
            p: 3,
            ...(currentUser && {
              ml: { xs: 0, md: '72px' },
              transition: theme => theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            })
          }}
        >
          <Routes>
            <Route path="/" element={<RootRedirect />} />
            <Route path="/host" element={<HostLogin />} />
            <Route path="/guest" element={<GuestLogin />} />
            <Route path="/forgot-password" element={<ResetPassword />} />
            <Route path="/create-guide" element={<CreateGuide />} />
            <Route path="/guest-access/:propertyId" element={<GuestAccess />} />
            <Route 
              path="/dashboard" 
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/property-info" 
              element={
                <PrivateRoute>
                  <PropertyInfo />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/local-tips" 
              element={
                <PrivateRoute>
                  <LocalTips />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/amenities" 
              element={
                <PrivateRoute>
                  <AmenitiesPage />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/management" 
              element={
                <PrivateRoute>
                  <PropertyManagement />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/settings" 
              element={
                <PrivateRoute>
                  <Settings />
                </PrivateRoute>
              } 
            />
          </Routes>
        </Box>
      </Box>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <PropertyProvider>
          <AppContent />
        </PropertyProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
