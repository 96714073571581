import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Box,
  alpha,
  useTheme,
  useMediaQuery,
  Divider,
  Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DialogHeader from './DialogHeader';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const ConfirmationDialog = ({ open, onClose, onConfirm, title, content }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          borderRadius: fullScreen ? 0 : 2,
          bgcolor: 'background.paper'
        }
      }}
    >
      <DialogHeader 
        title={title} 
        onClose={onClose}
        icon={WarningAmberIcon}
      />
      
      <Divider />
      
      <DialogContent sx={{ pt: 3 }}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          py: 2
        }}>
          <DialogContentText 
            id="confirmation-dialog-description"
            sx={{
              color: 'text.primary',
              fontSize: '1rem',
              maxWidth: '80%',
              mb: 1
            }}
          >
            {content}
          </DialogContentText>
        </Box>
      </DialogContent>

      <DialogActions 
        sx={{ 
          px: 3, 
          pb: 3,
          gap: 1,
          justifyContent: 'end'
        }}
      >
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            minWidth: 120,
            color: 'text.primary',
            borderColor: alpha(theme.palette.divider, 0.8),
            '&:hover': {
              borderColor: 'text.primary',
              bgcolor: alpha(theme.palette.text.primary, 0.04)
            }
          }}
        >
          {t('common.cancel')}
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          color="error"
          sx={{
            minWidth: 120,
            background: theme.palette.error.main,
            '&:hover': {
              background: theme.palette.error.dark,
              boxShadow: `0 6px 16px ${alpha(theme.palette.error.main, 0.3)}`,
              transform: 'translateY(-1px)'
            },
            transition: 'all 0.2s ease-in-out'
          }}
        >
          {t('common.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
