import React from 'react';
import { Container, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageHeader from '../components/PageHeader';
import Amenities from '../components/Amenities';

const AmenitiesPage = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <PageHeader
        title={t('amenities.title')}
        subtitle={t('amenities.subtitle')}
      />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Amenities />
      </Container>
    </Box>
  );
};

export default AmenitiesPage;
