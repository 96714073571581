import React from 'react';
import { Box, Typography, Paper, Container, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

const HeaderWrapper = styled(Paper)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '24px',
  background: `linear-gradient(135deg, ${theme.palette.primary.main}08, ${theme.palette.primary.main}08)`,
  marginBottom: theme.spacing(4),
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '3px',
    background: theme.palette.primary.main,
    opacity: 0.8,
  },
}));

const PageHeader = ({ title, subtitle, action }) => {
  const theme = useTheme();
  const logoSrc = theme.palette.mode === 'dark' ? '/images/logo_full_dark.png' : '/images/logo_full.png';

  return (
    <HeaderWrapper elevation={0}>
      <Container maxWidth="xl">
        <Box
          sx={{
            py: { xs: 3, md: 4 },
            px: { xs: 2, sm: 3, md: 4 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Box
            component="img"
            src={logoSrc}
            alt="Logo"
            sx={{
              height: { xs: '40px', sm: '50px', md: '60px' },
              width: 'auto',
              mb: subtitle ? 2 : 0,
            }}
          />
          
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2, position: 'relative' }}>
            <Box sx={{ flex: 1, textAlign: 'center' }}>
              <Typography
                variant="h4"
                component="h1"
                align="center"
                sx={{
                  fontWeight: 'bold',
                  fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
                }}
              >
                {title}
              </Typography>
              
              {subtitle && (
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  align="center"
                  sx={{
                    maxWidth: '600px',
                    mx: 'auto',
                    mt: 1,
                  }}
                >
                  {subtitle}
                </Typography>
              )}
            </Box>
            
            {action && (
              <Box
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                {action}
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </HeaderWrapper>
  );
};

export default PageHeader;
