import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  TextField,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  useTheme,
  Divider,
  Chip,
} from '@mui/material';
import {
  Close as CloseIcon,
  Search as SearchIcon,
  Place as PlaceIcon,
  Phone as PhoneIcon,
  Star as StarIcon,
  Language as LanguageIcon,
  LocationOn as LocationOnIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import CustomSnackbar from './common/CustomSnackbar';

const SearchPlacesDialog = ({ open, onClose, onSelectPlace }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchQuery, setSearchQuery] = useState('');
  const [location] = useState('Sao Francisco do Sul, State of Santa Catarina, Brazil');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const handleSearch = async () => {
    if (!searchQuery.trim()) {
      setSnackbar({
        open: true,
        message: t('searchPlaces.emptyQuery'),
        severity: 'warning',
      });
      return;
    }

    setLoading(true);
    setError(null);

    const myHeaders = new Headers();
    myHeaders.append("X-API-KEY", "6bfecbb60d9d02d614702b5869e92aeed1eee3ac");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "q": `${searchQuery} ${location}`,
      "gl": "br",
      "hl": "pt-br",
      "type": "places"
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch("https://google.serper.dev/places", requestOptions);
      const data = await response.json();
      
      if (data.places) {
        setResults(data.places);
        if (data.places.length === 0) {
          setSnackbar({
            open: true,
            message: t('searchPlaces.noResults'),
            severity: 'info',
          });
        }
      } else {
        setResults([]);
        setSnackbar({
          open: true,
          message: t('searchPlaces.noResults'),
          severity: 'info',
        });
      }
    } catch (error) {
      console.error(error);
      setError(t('searchPlaces.error'));
      setSnackbar({
        open: true,
        message: t('searchPlaces.error'),
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleAddPlace = (place) => {
    const formattedPlace = {
      name: place.title,
      address: place.address,
      rating: place.rating,
      ratingCount: place.reviewsCount,
      category: place.type,
      phoneNumber: place.phone,
      website: place.website,
    };
    onSelectPlace(formattedPlace);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            p: 1,
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
          <SearchIcon sx={{ color: theme.palette.primary.main }} />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {t('searchPlaces.title')}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Box sx={{ mb: 3 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder={t('searchPlaces.searchPlaceholder')}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={handleKeyPress}
              InputProps={{
                endAdornment: (
                  <IconButton 
                    onClick={handleSearch}
                    disabled={loading}
                    sx={{ color: theme.palette.primary.main }}
                  >
                    {loading ? <CircularProgress size={24} /> : <SearchIcon />}
                  </IconButton>
                ),
              }}
            />
          </Box>

          {error && (
            <Typography color="error" sx={{ mb: 2 }}>
              {error}
            </Typography>
          )}

          <List sx={{ width: '100%' }}>
            {results.map((place, index) => (
              <React.Fragment key={place.cid || index}>
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    borderRadius: 1,
                    mb: 1,
                    '&:hover': {
                      bgcolor: 'action.hover',
                    },
                  }}
                >
                  <ListItemIcon>
                    <PlaceIcon sx={{ color: theme.palette.primary.main }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="subtitle1" component="span">
                          {place.title}
                        </Typography>
                        {place.rating && (
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            <StarIcon sx={{ color: '#FFB400', fontSize: '1rem' }} />
                            <Typography variant="body2" color="text.secondary">
                              {place.rating} ({place.ratingCount || 0})
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    }
                    secondary={
                      <Box sx={{ mt: 1 }}>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                          {place.address}
                        </Typography>
                        {place.category && (
                          <Chip
                            label={place.category}
                            size="small"
                            sx={{ 
                              mr: 1,
                              mb: 1,
                              bgcolor: `${theme.palette.primary.main}15`,
                              color: theme.palette.primary.main,
                              fontWeight: 500,
                            }}
                          />
                        )}
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, mt: 1 }}>
                          {place.phoneNumber && (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <PhoneIcon sx={{ fontSize: '1rem', color: 'text.secondary' }} />
                              <Typography 
                                variant="body2" 
                                color="text.secondary"
                                component="a"
                                href={`tel:${place.phoneNumber}`}
                                sx={{ 
                                  textDecoration: 'none',
                                  color: 'inherit',
                                  '&:hover': {
                                    color: 'primary.main',
                                  }
                                }}
                              >
                                {place.phoneNumber}
                              </Typography>
                            </Box>
                          )}
                          {place.website && (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <LanguageIcon sx={{ fontSize: '1rem', color: 'text.secondary' }} />
                              <Typography 
                                variant="body2" 
                                color="text.secondary"
                                component="a"
                                href={place.website}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ 
                                  textDecoration: 'none',
                                  color: 'inherit',
                                  '&:hover': {
                                    color: 'primary.main',
                                  }
                                }}
                              >
                                {new URL(place.website).hostname}
                              </Typography>
                            </Box>
                          )}
                          {place.latitude && place.longitude && (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <LocationOnIcon sx={{ fontSize: '1rem', color: 'text.secondary' }} />
                              <Typography 
                                variant="body2" 
                                color="text.secondary"
                                component="a"
                                href={`https://www.google.com/maps?q=${place.latitude},${place.longitude}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ 
                                  textDecoration: 'none',
                                  color: 'inherit',
                                  '&:hover': {
                                    color: 'primary.main',
                                  }
                                }}
                              >
                                {t('searchPlaces.viewOnMap')}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    }
                  />
                  <Button
                    variant="contained"
                    onClick={() => handleAddPlace(place)}
                    sx={{
                      bgcolor: theme.palette.primary.main,
                      color: 'white',
                      '&:hover': {
                        bgcolor: theme.palette.primary.dark,
                      },
                    }}
                  >
                    {t('searchPlaces.addPlace')}
                  </Button>
                </ListItem>
                {index < results.length - 1 && <Divider variant="inset" component="li" />}
              </React.Fragment>
            ))}
          </List>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={onClose}
            sx={{
              color: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: `${theme.palette.primary.main}10`,
              },
            }}
          >
            {t('common.close')}
          </Button>
        </DialogActions>
      </Dialog>

      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleCloseSnackbar}
      />
    </>
  );
};

export default SearchPlacesDialog;
