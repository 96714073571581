import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
  Box,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  Chip,
  alpha,
} from '@mui/material';
import {
  Pool,
  Kitchen,
  Wifi,
  AcUnit,
  LocalParking,
  LocalLaundryService,
  Tv,
  Coffee,
  CleaningServices as Cleaning,
  Pets,
  ExpandMore,
  Bathtub,
  Balcony,
  Iron,
  LocalDining,
  FitnessCenter,
  SportsEsports,
  BeachAccess,
  LocalBar,
  Desk,
  Weekend,
  KingBed,
  Elevator,
  Security,
} from '@mui/icons-material';

const amenityIcons = {
  pool: Pool,
  kitchen: Kitchen,
  wifi: Wifi,
  ac: AcUnit,
  parking: LocalParking,
  laundry: LocalLaundryService,
  tv: Tv,
  coffee: Coffee,
  cleaning: Cleaning,
  pets: Pets,
  bathroom: Bathtub,
  balcony: Balcony,
  iron: Iron,
  dining: LocalDining,
  gym: FitnessCenter,
  games: SportsEsports,
  beach: BeachAccess,
  bar: LocalBar,
  workspace: Desk,
  living: Weekend,
  bedroom: KingBed,
  elevator: Elevator,
  security: Security,
};

const amenityColors = {
  pool: '#00A699',
  kitchen: '#FF5A5F',
  wifi: '#484848',
  ac: '#00A699',
  parking: '#767676',
  laundry: '#FFB400',
  tv: '#FF5A5F',
  coffee: '#D93900',
  cleaning: '#00A699',
  pets: '#484848',
  bathroom: '#00A699',
  balcony: '#767676',
  iron: '#484848',
  dining: '#FF5A5F',
  gym: '#00A699',
  games: '#FFB400',
  beach: '#00A699',
  bar: '#D93900',
  workspace: '#484848',
  living: '#FF5A5F',
  bedroom: '#00A699',
  elevator: '#767676',
  security: '#484848',
};

const Amenities = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  
  const amenitySections = [
    {
      id: 'comfort',
      title: t('amenities.comfort'),
      subtitle: t('amenities.comfortDesc'),
      items: ['ac', 'tv', 'living', 'bedroom', 'balcony'],
      color: '#00A699',
    },
    {
      id: 'essentials',
      title: t('amenities.essentials'),
      subtitle: t('amenities.essentialsDesc'),
      items: ['wifi', 'kitchen', 'coffee', 'bathroom', 'iron'],
      color: '#FF5A5F',
    },
    {
      id: 'facilities',
      title: t('amenities.facilities'),
      subtitle: t('amenities.facilitiesDesc'),
      items: ['pool', 'parking', 'laundry', 'gym', 'elevator'],
      color: '#FFB400',
    },
    {
      id: 'entertainment',
      title: t('amenities.entertainment'),
      subtitle: t('amenities.entertainmentDesc'),
      items: ['games', 'bar', 'dining', 'beach', 'workspace'],
      color: '#D93900',
    },
    {
      id: 'services',
      title: t('amenities.services'),
      subtitle: t('amenities.servicesDesc'),
      items: ['cleaning', 'pets', 'security'],
      color: '#484848',
    },
  ];

  return (
    <>
      <Container maxWidth="lg">
        <Box sx={{ mb: 8 }}>
          <Grid container spacing={3}>
            {amenitySections.map((section) => (
              <Grid item xs={12} md={6} key={section.id}>
                <Accordion
                  sx={{
                    borderRadius: '24px !important',
                    '&:before': { display: 'none' },
                    boxShadow: 'none',
                    border: `1px solid ${theme.palette.divider}`,
                    overflow: 'hidden',
                    transition: 'all 0.3s ease',
                    height: '100%',
                    bgcolor: theme.palette.mode === 'dark' ? 'background.paper' : 'background.default',
                    '&:hover': {
                      borderColor: section.color,
                      backgroundColor: alpha(section.color, theme.palette.mode === 'dark' ? 0.15 : 0.05),
                      transform: 'translateY(-2px)',
                      boxShadow: `0 4px 20px 0 ${alpha(section.color, 0.15)}`,
                    },
                    '&.Mui-expanded': {
                      margin: 0,
                      borderColor: section.color,
                      backgroundColor: alpha(section.color, theme.palette.mode === 'dark' ? 0.15 : 0.05),
                      boxShadow: `0 8px 25px 0 ${alpha(section.color, 0.15)}`,
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMore 
                        sx={{ 
                          color: section.color,
                          transition: 'transform 0.3s ease',
                          transform: 'rotate(0deg)',
                          '.Mui-expanded &': {
                            transform: 'rotate(-180deg)',
                          },
                        }} 
                      />
                    }
                    sx={{
                      minHeight: 84,
                      p: 3,
                      '& .MuiAccordionSummary-content': {
                        m: 0,
                        gap: 2.5,
                        alignItems: 'center',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: alpha(section.color, theme.palette.mode === 'dark' ? 0.2 : 0.1),
                        color: section.color,
                        borderRadius: '12px',
                        p: 1.5,
                        minWidth: '48px',
                        height: '48px',
                      }}
                    >
                      {React.createElement(amenityIcons[section.items[0]])}
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="h6" color="text.primary" sx={{ mb: 0.5 }}>
                        {section.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {section.subtitle}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      p: 3,
                      pt: 1,
                      pb: 4,
                      bgcolor: theme.palette.mode === 'dark' 
                        ? alpha(theme.palette.background.paper, 0.5)
                        : alpha(theme.palette.grey[50], 0.5),
                    }}
                  >
                    <Grid container spacing={2}>
                      {section.items.map((amenity) => {
                        const Icon = amenityIcons[amenity];
                        return (
                          <Grid item xs={12} key={amenity}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                p: 3,
                                borderRadius: 3,
                                bgcolor: theme.palette.mode === 'dark' 
                                  ? alpha(theme.palette.background.paper, 0.6)
                                  : theme.palette.background.paper,
                                border: '1px solid',
                                borderColor: theme.palette.divider,
                              }}
                            >
                              <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    bgcolor: alpha(amenityColors[amenity], theme.palette.mode === 'dark' ? 0.2 : 0.1),
                                    color: theme.palette.mode === 'dark' 
                                      ? alpha(amenityColors[amenity], 0.9)
                                      : amenityColors[amenity],
                                    borderRadius: '12px',
                                    p: 1.5,
                                    minWidth: '48px',
                                    height: '48px',
                                  }}
                                >
                                  <Icon />
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                  <Typography variant="h6" gutterBottom color="text.primary">
                                    {t(`amenities.${amenity}.name`)}
                                  </Typography>
                                  <Typography variant="body1" color="text.secondary" gutterBottom>
                                    {t(`amenities.${amenity}.desc`)}
                                  </Typography>
                                  <Box sx={{ mt: 2 }}>
                                    {t(`amenities.${amenity}.details`).split('|').map((detail, index) => (
                                      <Chip
                                        key={index}
                                        label={detail.trim()}
                                        size="small"
                                        sx={{ 
                                          mr: 1,
                                          mb: 1,
                                          bgcolor: alpha(amenityColors[amenity], theme.palette.mode === 'dark' ? 0.2 : 0.08),
                                          color: theme.palette.mode === 'dark' 
                                            ? alpha(amenityColors[amenity], 0.9)
                                            : amenityColors[amenity],
                                          '& .MuiChip-label': {
                                            px: 2,
                                          },
                                        }}
                                      />
                                    ))}
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default Amenities;
