import React, { useState } from 'react';
import {
  Box,
  IconButton,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Switch,
  Paper,
  List} from '@mui/material';
import {
  Edit as EditIcon,
  Close as CloseIcon,
  Save as SaveIcon,
  Gavel as GavelIcon,
  DragHandle as DragHandleIcon,
  Add as AddIcon,
  ListAlt as ListAltIcon,
  Pets as PetsIcon,
  SmokingRooms as SmokingRoomsIcon,
  Celebration as CelebrationIcon,
  ChildCare as ChildCareIcon,
  AccessTime as AccessTimeIcon,
  MusicNote as MusicNoteIcon,
  People as PeopleIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon
} from '@mui/icons-material';
import { useTheme, alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ConfigSection from './ConfigSection';
import CustomTooltip from '../common/CustomTooltip';
import RuleItem from './RuleItem';

const HouseRulesSection = ({
  rulesForm,
  isEditingRules,
  isSavingRules,
  onRulesChange,
  onStartEditing,
  onCancelEditing,
  onSave
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [newRule, setNewRule] = useState('');

  const handleRuleChange = (field) => (event) => {
    onRulesChange(field, event.target.checked);
  };

  const handleAddRule = () => {
    if (newRule.trim()) {
      onRulesChange('additionalRules', [...(rulesForm.additionalRules || []), newRule.trim()]);
      setNewRule('');
    }
  };

  const handleRemoveRule = (index) => {
    const newRules = [...(rulesForm.additionalRules || [])];
    newRules.splice(index, 1);
    onRulesChange('additionalRules', newRules);
  };

  const moveRule = (dragIndex, hoverIndex) => {
    const newRules = [...(rulesForm.additionalRules || [])];
    const dragRule = newRules[dragIndex];
    newRules.splice(dragIndex, 1);
    newRules.splice(hoverIndex, 0, dragRule);
    onRulesChange('additionalRules', newRules);
  };

  const handleEditRule = (index, newRule) => {
    const newRules = [...(rulesForm.additionalRules || [])];
    newRules[index] = newRule;
    onRulesChange('additionalRules', newRules);
  };

  const RuleToggle = ({ field, icon: Icon, label, description, color }) => {
    const isAllowed = rulesForm[field];
    
    return (
      <Paper
        elevation={0}
        sx={{
          p: 2,
          bgcolor: alpha(theme.palette.background.paper, 0.8),
          border: '1px solid',
          borderColor: theme => alpha(theme.palette.divider, 0.1),
          transition: 'all 0.2s ease',
          '&:hover': {
            borderColor: theme => isEditingRules ? alpha(color, 0.3) : alpha(theme.palette.divider, 0.1),
            transform: isEditingRules ? 'translateY(-2px)' : 'none',
            boxShadow: isEditingRules ? `0 4px 12px ${alpha(color, 0.1)}` : 'none',
          }
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
          <Box
            sx={{
              width: 40,
              height: 40,
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: alpha(color, 0.1),
              color: color,
              flexShrink: 0
            }}
          >
            <Icon />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 0.5 }}>
              {label}
            </Typography>
            <Box
              sx={{
                display: 'inline-flex',
                px: 1.5,
                py: 0.5,
                borderRadius: 1,
                bgcolor: isAllowed ? alpha(theme.palette.success.main, 0.1) : alpha(theme.palette.error.main, 0.1),
                color: isAllowed ? theme.palette.success.main : theme.palette.error.main,
                alignItems: 'center',
                gap: 0.5,
                fontSize: '0.75rem',
                fontWeight: 500,
                mb: 1
              }}
            >
              {isAllowed ? (
                <CheckCircleIcon sx={{ fontSize: '1rem' }} />
              ) : (
                <CancelIcon sx={{ fontSize: '1rem' }} />
              )}
              {isAllowed ? (
                t('propertyManagement.houseRules.status.allowed')
              ) : (
                t('propertyManagement.houseRules.status.notAllowed')
              )}
            </Box>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1.5 }}>
              {description}
            </Typography>
            <Switch
              checked={isAllowed}
              onChange={handleRuleChange(field)}
              disabled={!isEditingRules}
              sx={{
                '& .MuiSwitch-switchBase': {
                  '&.Mui-checked': {
                    color: color,
                    '& + .MuiSwitch-track': {
                      bgcolor: alpha(color, 0.5),
                    },
                    '&:hover': {
                      bgcolor: alpha(color, 0.08),
                    },
                  },
                },
                '& .MuiSwitch-track': {
                  bgcolor: alpha(theme.palette.text.secondary, 0.2),
                },
              }}
            />
          </Box>
        </Box>
      </Paper>
    );
  };

  const toggleEditMode = () => {
    if (isEditingRules) {
      onSave();
    } else {
      onStartEditing();
    }
  };

  return (
    <ConfigSection 
      title={t('propertyManagement.houseRules.title')}
      icon={<GavelIcon />}
      action={
        <Box>
          {!isEditingRules ? (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <CustomTooltip title={t('common.edit')}>
                <IconButton
                  onClick={onStartEditing}
                  size="large"
                  sx={{ 
                    width: 32,
                    height: 32,
                    bgcolor: alpha(theme.palette.primary.main, 0.1),
                    color: 'primary.main',
                    '&:hover': {
                      bgcolor: alpha(theme.palette.primary.main, 0.2)
                    }
                  }}
                >
                  <EditIcon />
                </IconButton>
              </CustomTooltip>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <CustomTooltip title={t('common.cancel')}>
                <IconButton
                  onClick={onCancelEditing}
                  size="large"
                  disabled={isSavingRules}
                  sx={{ 
                    width: 32,
                    height: 32,
                    bgcolor: alpha(theme.palette.text.secondary, 0.1),
                    color: 'text.secondary',
                    '&:hover': {
                      bgcolor: alpha(theme.palette.text.secondary, 0.2)
                    }
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </CustomTooltip>
              <CustomTooltip title={t('common.save')}>
                <IconButton
                  onClick={onSave}
                  size="large"
                  disabled={isSavingRules}
                  sx={{ 
                    width: 32,
                    height: 32,
                    bgcolor: alpha(theme.palette.success.main, 0.1),
                    color: 'success.main',
                    '&:hover': {
                      bgcolor: alpha(theme.palette.success.main, 0.2)
                    }
                  }}
                >
                  {isSavingRules ? <CircularProgress size={20} /> : <SaveIcon />}
                </IconButton>
              </CustomTooltip>
            </Box>
          )}
        </Box>
      }
    >
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <RuleToggle
              field="allowPets"
              icon={PetsIcon}
              label={t('propertyManagement.houseRules.allowPets')}
              description={t('propertyManagement.houseRules.petsDescription')}
              color={theme.palette.info.main}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <RuleToggle
              field="allowSmoking"
              icon={SmokingRoomsIcon}
              label={t('propertyManagement.houseRules.allowSmoking')}
              description={t('propertyManagement.houseRules.smokingDescription')}
              color={theme.palette.warning.main}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <RuleToggle
              field="allowEvents"
              icon={CelebrationIcon}
              label={t('propertyManagement.houseRules.allowEvents')}
              description={t('propertyManagement.houseRules.eventsDescription')}
              color={theme.palette.secondary.main}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <RuleToggle
              field="allowChildren"
              icon={ChildCareIcon}
              label={t('propertyManagement.houseRules.allowChildren')}
              description={t('propertyManagement.houseRules.childrenDescription')}
              color={theme.palette.success.main}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <RuleToggle
              field="allowLateCheckout"
              icon={AccessTimeIcon}
              label={t('propertyManagement.houseRules.allowLateCheckout')}
              description={t('propertyManagement.houseRules.lateCheckoutDescription')}
              color={theme.palette.info.dark}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <RuleToggle
              field="allowEarlyCheckin"
              icon={AccessTimeIcon}
              label={t('propertyManagement.houseRules.allowEarlyCheckin')}
              description={t('propertyManagement.houseRules.earlyCheckinDescription')}
              color={theme.palette.info.dark}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <RuleToggle
              field="allowMusic"
              icon={MusicNoteIcon}
              label={t('propertyManagement.houseRules.allowMusic')}
              description={t('propertyManagement.houseRules.musicDescription')}
              color={theme.palette.error.main}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <RuleToggle
              field="allowVisitors"
              icon={PeopleIcon}
              label={t('propertyManagement.houseRules.allowVisitors')}
              description={t('propertyManagement.houseRules.visitorsDescription')}
              color={theme.palette.warning.dark}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" color="primary" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
              <ListAltIcon /> {t('propertyManagement.houseRules.additionalRulesTitle')}
            </Typography>

            <DndProvider backend={HTML5Backend}>
              <Box sx={{ 
                bgcolor: 'background.paper',
                borderRadius: 2,
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                position: 'relative'
              }}>
                {isEditingRules && (
                  <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                    <TextField
                      fullWidth
                      size="small"
                      value={newRule}
                      onChange={(e) => setNewRule(e.target.value)}
                      placeholder={t('propertyManagement.houseRules.additionalRulesPlaceholder')}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' && newRule.trim()) {
                          handleAddRule();
                        }
                      }}
                    />
                    <IconButton
                      onClick={handleAddRule}
                      disabled={!newRule.trim()}
                      sx={{
                        bgcolor: theme.palette.primary.main,
                        color: 'white',
                        '&:hover': {
                          bgcolor: theme.palette.primary.dark,
                        },
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                )}

                <List sx={{ py: 0, flex: 1, minHeight: '100px' }}>
                  {(rulesForm.additionalRules || []).map((rule, index) => (
                    <RuleItem
                      key={index}
                      id={index}
                      index={index}
                      rule={rule}
                      moveRule={moveRule}
                      handleRemoveRule={() => handleRemoveRule(index)}
                      handleEditRule={handleEditRule}
                      isEditingRules={isEditingRules}
                    />
                  ))}
                </List>

                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center',
                  mt: 2,
                  pt: 2,
                  borderTop: 1,
                  borderColor: 'divider'
                }}>
                  {isEditingRules && (
                    <Typography variant="caption" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      <DragHandleIcon fontSize="small" />
                      {t('propertyManagement.houseRules.dragToReorder')}
                    </Typography>
                  )}
                </Box>

                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                    display: 'flex',
                    gap: 1,
                    zIndex: 1,
                  }}
                >
                  <CustomTooltip title={isEditingRules 
                    ? t('propertyManagement.houseRules.exitEditMode')
                    : t('propertyManagement.houseRules.editMode')
                  }>
                    <IconButton
                      onClick={toggleEditMode}
                      size="small"
                      sx={{
                        bgcolor: isEditingRules ? theme.palette.success.main : theme.palette.primary.main,
                        color: 'white',
                        '&:hover': {
                          bgcolor: isEditingRules ? theme.palette.success.dark : theme.palette.primary.dark,
                        },
                        boxShadow: 2,
                      }}
                    >
                      {isEditingRules ? <SaveIcon /> : <EditIcon />}
                    </IconButton>
                  </CustomTooltip>
                </Box>
              </Box>
            </DndProvider>
          </Grid>
        </Grid>
      </Box>
    </ConfigSection>
  );
};

export default HouseRulesSection;
