import React, { useState } from 'react';
import { useProperty } from '../contexts/PropertyContext';
import { useLanguage } from '../hooks/useLanguage';
import { auth } from '../config/firebase';
import { 
  Container, 
  Box, 
  Typography, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  useTheme,
  Grid,
  alpha,
  Button,
  CircularProgress,
  Alert,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ExpandMore,
  AccessTime,
  Gavel,
  Warning,
  CleaningServices,
  Security,
  Pets,
  SmokingRooms,
  Password as PasswordIcon,
  Schedule as ScheduleIcon,
  Event as EventIcon,
} from '@mui/icons-material';
import WifiDialog from '../components/WifiDialog';
import ElectronicKeyDialog from '../components/ElectronicKeyDialog';
import PropertyOverview from '../components/PropertyOverview';
import { usePropertyData } from '../hooks/usePropertyData';

function PropertyInfo() {
  const { currentLanguage } = useLanguage();
  const [wifiDialogOpen, setWifiDialogOpen] = useState(false);
  const [keyDialogOpen, setKeyDialogOpen] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const { propertyId } = useProperty();

  // Usando o hook usePropertyData para buscar os dados da propriedade
  const { propertyData, loading, error } = usePropertyData(propertyId);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (!propertyData) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="warning">{t('propertyInfo.notFound', 'Propriedade não encontrada')}</Alert>
      </Box>
    );
  }

  const handleWifiClick = () => {
    setWifiDialogOpen(true);
  };

  const handleKeyClick = () => {
    setKeyDialogOpen(true);
  };

  const handleKeyClose = () => {
    setKeyDialogOpen(false);
  };

  const handleWifiClose = () => {
    setWifiDialogOpen(false);
  };

  // Obtém as configurações do idioma atual
  const propertyConfig = propertyData?.languages?.[currentLanguage]?.property_config;
  const propertyDataInfo = propertyData?.languages?.[currentLanguage]?.property_data;

  const sections = [
    {
      id: 'checkin',
      title: t('propertyInfo.checkinTitle', 'Check-in'),
      subtitle: t('propertyInfo.checkinSubtitle', 'Horário de entrada'),
      icon: <ScheduleIcon />,
      content: (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: { xs: 'stretch', md: 'center' }, 
              justifyContent: 'space-between',
              gap: { xs: 2, md: 3 },
              bgcolor: theme => theme.palette.mode === 'dark' ? 'rgba(76, 175, 80, 0.15)' : 'rgba(76, 175, 80, 0.1)',
              p: 3,
              borderRadius: 2,
              border: '1px solid',
              borderColor: 'rgba(76, 175, 80, 0.3)',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <AccessTime sx={{ fontSize: 32, color: '#4CAF50' }} />
              <Typography 
                variant="h4" 
                sx={{ 
                  fontWeight: 'bold',
                  color: '#4CAF50',
                  fontSize: { xs: '1.75rem', sm: '2rem' },
                }}
              >
                {propertyConfig?.checkin_time || '14:00'}
              </Typography>
            </Box>
            <Button
              variant="outlined"
              startIcon={<EventIcon />}
              onClick={() => window.open(`https://www.google.com/calendar/render?action=TEMPLATE&text=${t('propertyInfo.checkinCalendarTitle', 'Check-in')}+${propertyDataInfo?.name}&details=${t('propertyInfo.checkinCalendarDetails', 'Check-in time at')}+${propertyDataInfo?.name}&dates=${new Date().toISOString().split('T')[0]}T140000/${new Date().toISOString().split('T')[0]}T140000`, '_blank')}
              sx={{
                color: '#4CAF50',
                borderColor: '#4CAF50',
                minWidth: { xs: '100%', md: '200px' },
                height: { xs: 'auto', md: 48 },
                whiteSpace: { xs: 'normal', md: 'nowrap' },
                p: { xs: 1.5, md: 2 },
                '& .MuiButton-startIcon': {
                  marginRight: { xs: 1, md: 1.5 },
                },
                '&:hover': {
                  borderColor: '#4CAF50',
                  backgroundColor: 'rgba(76, 175, 80, 0.1)',
                },
              }}
            >
              {t('propertyInfo.addToCalendar', 'Adicionar ao Calendário')}
            </Button>
          </Box>
        </Box>
      ),
      color: '#4CAF50',
    },
    {
      id: 'checkout',
      title: t('propertyInfo.checkoutTitle', 'Check-out'),
      subtitle: t('propertyInfo.checkoutSubtitle', 'Horário de saída'),
      icon: <ScheduleIcon />,
      content: (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: { xs: 'stretch', md: 'center' }, 
              justifyContent: 'space-between',
              gap: { xs: 2, md: 3 },
              bgcolor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 90, 95, 0.15)' : 'rgba(255, 90, 95, 0.1)',
              p: 3,
              borderRadius: 2,
              border: '1px solid',
              borderColor: 'rgba(255, 90, 95, 0.3)',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <AccessTime sx={{ fontSize: 32, color: '#FF5A5F' }} />
              <Typography 
                variant="h4" 
                sx={{ 
                  fontWeight: 'bold',
                  color: '#FF5A5F',
                  fontSize: { xs: '1.75rem', sm: '2rem' },
                }}
              >
                {propertyConfig?.checkout_time || '10:00'}
              </Typography>
            </Box>
            <Button
              variant="outlined"
              startIcon={<EventIcon />}
              onClick={() => window.open(`https://www.google.com/calendar/render?action=TEMPLATE&text=${t('propertyInfo.checkoutCalendarTitle', 'Check-out')}+${propertyDataInfo?.name}&details=${t('propertyInfo.checkoutCalendarDetails', 'Check-out time at')}+${propertyDataInfo?.name}&dates=${new Date().toISOString().split('T')[0]}T100000/${new Date().toISOString().split('T')[0]}T100000`, '_blank')}
              sx={{
                color: '#FF5A5F',
                borderColor: '#FF5A5F',
                minWidth: { xs: '100%', md: '200px' },
                height: { xs: 'auto', md: 48 },
                whiteSpace: { xs: 'normal', md: 'nowrap' },
                p: { xs: 1.5, md: 2 },
                '& .MuiButton-startIcon': {
                  marginRight: { xs: 1, md: 1.5 },
                },
                '&:hover': {
                  borderColor: '#FF5A5F',
                  backgroundColor: 'rgba(255, 90, 95, 0.1)',
                },
              }}
            >
              {t('propertyInfo.addToCalendar', 'Adicionar ao Calendário')}
            </Button>
          </Box>
        </Box>
      ),
      color: '#FF5A5F',
    },
    {
      id: 'electronic-key',
      title: t('propertyInfo.electronicKeyTitle', 'Senha Eletrônica'),
      subtitle: t('propertyInfo.electronicKeySubtitle', 'Código de acesso'),
      icon: <PasswordIcon />,
      content: t('propertyInfo.electronicKeyContent', 'Clique no botão abaixo para ver o código de acesso'),
      color: '#FF5A5F',
      action: handleKeyClick,
    },
    {
      id: 'rules',
      title: t('propertyInfo.rulesTitle', 'Regras da Casa'),
      subtitle: t('propertyInfo.rulesSubtitle', 'Informações importantes'),
      icon: <Gavel />,
      content: (propertyDataInfo?.house_rules || []).map((rule, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
          {rule?.includes('pets') ? <Pets /> : 
           rule?.includes('smoking') ? <SmokingRooms /> : 
           <Warning />}
          <Typography>{rule}</Typography>
        </Box>
      )),
      color: '#FF9F1C',
    },
    {
      id: 'cleaning',
      title: t('propertyInfo.cleaningTitle', 'Limpeza'),
      subtitle: t('propertyInfo.cleaningSubtitle', 'Informações sobre limpeza'),
      icon: <CleaningServices />,
      content: propertyConfig?.cleaning?.content || t('propertyInfo.cleaningDefaultContent', 'Informações sobre limpeza não disponíveis'),
      color: '#E31C5F',
    },
    {
      id: 'security',
      title: t('propertyInfo.securityTitle', 'Segurança'),
      subtitle: t('propertyInfo.securitySubtitle', 'Informações sobre segurança'),
      icon: <Security />,
      content: propertyConfig?.security?.content || t('propertyInfo.securityDefaultContent', 'Informações sobre segurança não disponíveis'),
      color: '#D93900',
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Property Overview */}
      <PropertyOverview />

      {/* Access Information */}
      <Box sx={{ mb: 4 }}>
        <Grid container spacing={3}>
          {/* WiFi Access */}
          <Grid item xs={12} md={6}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleWifiClick}
              startIcon={<PasswordIcon />}
              sx={{
                p: 2,
                justifyContent: 'flex-start',
                borderRadius: '12px',
                bgcolor: theme => theme.palette.primary.main,
                color: 'white',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                  bgcolor: theme => theme.palette.primary.dark,
                  boxShadow: '0 6px 16px rgba(0, 0, 0, 0.2)',
                  transform: 'translateY(-1px)',
                },
                transition: 'all 0.2s ease-in-out',
              }}
            >
              {t('propertyInfo.wifiAccess', 'Acesso Wi-Fi')}
            </Button>
          </Grid>

          {/* Electronic Key */}
          <Grid item xs={12} md={6}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleKeyClick}
              startIcon={<PasswordIcon />}
              sx={{
                p: 2,
                justifyContent: 'flex-start',
                borderRadius: '12px',
                bgcolor: theme => theme.palette.primary.main,
                color: 'white',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                  bgcolor: theme => theme.palette.primary.dark,
                  boxShadow: '0 6px 16px rgba(0, 0, 0, 0.2)',
                  transform: 'translateY(-1px)',
                },
                transition: 'all 0.2s ease-in-out',
              }}
            >
              {t('propertyInfo.electronicKey', 'Senha Eletrônica')}
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Property Rules and Information */}
      <Box sx={{ mb: 4 }}>
        {sections.map((section) => (
          <Accordion
            key={section.id}
            sx={{
              mb: 2,
              borderRadius: '24px !important',
              '&:before': { display: 'none' },
              boxShadow: 'none',
              border: `1px solid ${theme.palette.divider}`,
              overflow: 'hidden',
              transition: 'all 0.3s ease',
              height: '100%',
              bgcolor: theme.palette.mode === 'dark' ? 'background.paper' : 'background.default',
              '&:hover': {
                borderColor: section.color,
                backgroundColor: alpha(section.color, theme.palette.mode === 'dark' ? 0.15 : 0.05),
                transform: 'translateY(-2px)',
                boxShadow: `0 4px 20px 0 ${alpha(section.color, 0.15)}`,
              },
              '&.Mui-expanded': {
                margin: 0,
                borderColor: section.color,
                backgroundColor: alpha(section.color, theme.palette.mode === 'dark' ? 0.15 : 0.05),
                boxShadow: `0 8px 25px 0 ${alpha(section.color, 0.15)}`,
              },
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMore 
                  sx={{ 
                    color: section.color,
                    transition: 'transform 0.3s ease',
                    transform: 'rotate(0deg)',
                    '.Mui-expanded &': {
                      transform: 'rotate(-180deg)',
                    },
                  }} 
                />
              }
              sx={{
                minHeight: 84,
                p: 3,
                '& .MuiAccordionSummary-content': {
                  m: 0,
                  gap: 2.5,
                  alignItems: 'center',
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: alpha(section.color, theme.palette.mode === 'dark' ? 0.2 : 0.1),
                    color: section.color,
                    borderRadius: '12px',
                    p: 1.5,
                    minWidth: '48px',
                    height: '48px',
                  }}
                >
                  {section.icon}
                </Box>
                <Box>
                  <Typography variant="subtitle1" fontWeight="medium">
                    {section.title}
                  </Typography>
                  {section.subtitle && (
                    <Typography variant="body2" color="text.secondary">
                      {section.subtitle}
                    </Typography>
                  )}
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 3 }}>
              {section.content}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      {/* Dialogs */}
      <WifiDialog
        open={wifiDialogOpen}
        onClose={handleWifiClose}
        networkName={propertyData?.accessKey?.networkName}
        networkPassword={propertyData?.accessKey?.networkPassword}
      />
      <ElectronicKeyDialog
        open={keyDialogOpen}
        onClose={handleKeyClose}
        keyCode={propertyData?.accessKey?.eKey}
        instructions={propertyConfig?.electronicKey?.instructions}
        notes={propertyConfig?.electronicKey?.notes}
      />
    </Container>
  );
}

export default PropertyInfo;
