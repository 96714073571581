import React from 'react';
import { DialogActions, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DialogFooter = ({ 
  onClose, 
  closeButtonText,
  customActions,
  sx = { p: 2 }
}) => {
  const { t } = useTranslation();

  if (customActions) {
    return <DialogActions sx={sx}>{customActions}</DialogActions>;
  }

  return (
    <DialogActions sx={sx}>
      <Button onClick={onClose} variant="outlined">
        {closeButtonText || t('common.close', 'Fechar')}
      </Button>
    </DialogActions>
  );
};

export default DialogFooter;
