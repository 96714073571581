import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  Typography,
  Alert,
  CircularProgress,
  Container,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, set } from 'firebase/database';
import { useTheme } from '@mui/material/styles';
import AuthCard from '../components/common/AuthCard';
import TypewriterText from '../components/common/TypewriterText';
import ProcessingGuide from '../components/common/ProcessingGuide';

export default function CreateGuide() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [airbnbUrl, setAirbnbUrl] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const welcomeMessages = t('createGuide.welcomeMessages', { returnObjects: true });

  const isValidAirbnbUrl = (url) => {
    return url.match(/^https?:\/\/(www\.)?airbnb\.(com|com\.br)\/rooms\/\d+/);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    
    if (!airbnbUrl || !email || !password) {
      setError(t('createGuide.allFieldsRequired'));
      return;
    }

    if (!isValidAirbnbUrl(airbnbUrl.trim())) {
      setError(t('createGuide.invalidUrl'));
      return;
    }

    try {
      setError('');
      setSuccess('');
      setLoading(true);

      // 1. Create user account
      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;

      // 2. Save initial user data
      const db = getDatabase();
      await set(ref(db, `users/${userId}`), {
        email: email,
        createdAt: new Date().toISOString(),
        role: 'host'
      });

      // 3. Send URL to webhook
      await fetch('https://db-core-n8n.vhhb1z.easypanel.host/webhook/bc6d3c20-388b-46af-af4d-1aca0e94f7c2', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          url: airbnbUrl.trim(),
          userId: userId,
          email: email
        }),
      });

      // 4. Show processing state
      setIsProcessing(true);
      
    } catch (err) {
      console.error('Error creating guide:', err);
      if (err.code === 'auth/email-already-in-use') {
        setError(t('createGuide.emailInUse'));
      } else {
        setError(t('createGuide.error'));
      }
      setLoading(false);
    }
  }

  if (isProcessing) {
    return <ProcessingGuide />;
  }

  const styles = {
    textField: {
      marginBottom: theme.spacing(2.5),
      '& .MuiOutlinedInput-root': {
        borderRadius: 4,
        backgroundColor: theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.05)'
          : 'rgba(0, 0, 0, 0.02)',
        transition: theme.transitions.create([
          'background-color', 
          'box-shadow', 
          'border-color'
        ], {
          duration: 200,
        }),
        '&:hover': {
          backgroundColor: theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, 0.1)'
            : 'rgba(0, 0, 0, 0.04)',
        },
        '&.Mui-focused': {
          backgroundColor: theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, 0.15)'
            : 'rgba(0, 0, 0, 0.06)',
          boxShadow: `0 0 0 1px ${theme.palette.primary.main}25`,
        }
      }
    },
    submitButton: {
      padding: theme.spacing(1.5),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
      borderRadius: 4,
      textTransform: 'none',
      fontSize: '1rem',
      fontWeight: 600,
      background: 'linear-gradient(45deg, #ff4b6e, #ff758c)',
      boxShadow: '0 2px 8px rgba(255, 75, 110, 0.25)',
      transition: 'all 0.2s ease-in-out',
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: '0 4px 12px rgba(255, 75, 110, 0.35)',
      },
      '&:active': {
        transform: 'translateY(1px)',
      }
    },
    backButton: {
      padding: theme.spacing(1.5),
      borderRadius: 4,
      textTransform: 'none',
      fontSize: '0.95rem',
      fontWeight: 500,
      color: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.05)'
        : 'rgba(0, 0, 0, 0.02)',
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(0, 0, 0, 0.04)',
      }
    },
    subtitle: {
      marginBottom: theme.spacing(4),
      color: theme.palette.text.secondary,
      textAlign: 'center',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.6,
    }
  };

  return (
    <AuthCard
      welcomeTitle={t('createGuide.title')}
      welcomeMessage={t('createGuide.subtitle')}
    >
      <Box sx={{ mb: 4, textAlign: 'center' }}>
        <TypewriterText messages={welcomeMessages} />
      </Box>

      <Typography variant="body1" sx={styles.subtitle}>
        {t('createGuide.instructions')}
      </Typography>

      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        {error && (
          <Alert 
            severity="error" 
            sx={{ 
              mb: 3,
              borderRadius: 2,
              '& .MuiAlert-icon': {
                color: 'error.main'
              }
            }}
          >
            {error}
          </Alert>
        )}
        {success && (
          <Alert 
            severity="success"
            sx={{ 
              mb: 3,
              borderRadius: 2,
              '& .MuiAlert-icon': {
                color: 'success.main'
              }
            }}
          >
            {success}
          </Alert>
        )}

        <TextField
          fullWidth
          label={t('createGuide.urlLabel')}
          type="url"
          value={airbnbUrl}
          onChange={(e) => setAirbnbUrl(e.target.value)}
          disabled={loading}
          placeholder={t('createGuide.urlPlaceholder')}
          sx={styles.textField}
        />

        <TextField
          fullWidth
          label={t('createGuide.emailLabel')}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={loading}
          sx={styles.textField}
        />

        <TextField
          fullWidth
          label={t('createGuide.passwordLabel')}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading}
          sx={styles.textField}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={loading}
          sx={styles.submitButton}
        >
          {loading ? (
            <CircularProgress 
              size={20}
              sx={{ 
                color: 'white',
              }}
            />
          ) : (
            t('createGuide.submitButton')
          )}
        </Button>

        <Button
          fullWidth
          variant="outlined"
          onClick={() => navigate('/')}
          sx={styles.backButton}
        >
          {t('createGuide.backButton')}
        </Button>
      </form>
    </AuthCard>
  );
}
