import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ConfigSection = ({ title, icon, action, children }) => {
  const theme = useTheme();
  return (
    <Card 
      elevation={0}
      sx={{ 
        mb: 4,
        bgcolor: 'background.paper',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 2,
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          boxShadow: `0px 4px 20px ${theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.3)' : 'rgba(170, 180, 190, 0.3)'}`,
        }
      }}
    >
      <CardContent sx={{ p: 3 }}>
        <Box sx={{ 
          mb: 3, 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1,
          pb: 2,
          borderBottom: '1px solid',
          borderColor: 'divider'
        }}>
          {React.cloneElement(icon, { 
            sx: { 
              fontSize: 28,
              color: 'primary.main'
            } 
          })}
          <Typography variant="h6" component="h2" fontWeight="500">
            {title}
          </Typography>
          {action && (
            <Box sx={{ ml: 'auto' }}>
              {action}
            </Box>
          )}
        </Box>
        {children}
      </CardContent>
    </Card>
  );
};

export default ConfigSection;
