import React, { createContext, useContext, useState, useEffect } from 'react';
import { ref, get, child, getDatabase } from 'firebase/database';
import { useAuth } from './AuthContext';

// Create the PropertyContext
const PropertyContext = createContext();

// Hook to use the PropertyContext
export const useProperty = () => {
  const context = useContext(PropertyContext);
  if (!context) {
    throw new Error('useProperty must be used within a PropertyProvider');
  }
  return context;
};

// PropertyProvider component
export const PropertyProvider = ({ children }) => {
  const { currentUser } = useAuth();
  // State for the current property ID, initialized from localStorage
  const [currentPropertyId, setCurrentPropertyId] = useState(() => {
    return localStorage.getItem('selectedGuideId') || null;
  });
  // State for the property data
  const [propertyData, setPropertyData] = useState(null);
  // State for loading status
  const [loading, setLoading] = useState(true);
  // State for error messages
  const [error, setError] = useState(null);
  // State for the current language
  const [currentLanguage, setCurrentLanguage] = useState('ptbr');

  // Helper function to set error and reset property data
  const handleFetchError = (message) => {
    setError(message);
    setPropertyData(null);
    setLoading(false);
  };

  // Helper function to process property data
  const processPropertyData = (data, language) => {
    const availableLanguages = Object.keys(data?.languages || {});
    const selectedLanguage = availableLanguages.includes(language) ? language : 'ptbr';

    return {
      ...data,
      id: data?.id,
      status: data?.status,
      name: data?.name,
      location: data?.location,
      accessKey: data?.accessKey,
      currentLanguage: selectedLanguage,
      availableLanguages,
      localizedData: {
        property_config: data?.languages?.[selectedLanguage]?.property_config || {},
        property_data: data?.languages?.[selectedLanguage]?.property_data || {},
      }
    };
  };

  // Function to update the property ID in state and localStorage
  const updateCurrentPropertyId = (id) => {
    setCurrentPropertyId(id);
    if (id) {
      localStorage.setItem('selectedGuideId', id);
    } else {
      localStorage.removeItem('selectedGuideId');
    }
  };

  // Function to fetch property data from Firebase
  const fetchPropertyData = async (propertyId, language = currentLanguage) => {
    if (!propertyId) {
      console.warn('fetchPropertyData: No property ID provided');
      handleFetchError('No property ID provided');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const dbRef = ref(getDatabase());
      const propertyPath = `properties/${propertyId}`;
      
      console.log('Fetching property data from path:', propertyPath, { language });
      
      const snapshot = await get(child(dbRef, propertyPath));
      
      if (snapshot.exists()) {
        const data = snapshot.val();
        const processedData = processPropertyData(data, language);

        console.log('Property data fetched:', {
          propertyId,
          hasLanguages: !!data?.languages,
          availableLanguages: processedData.availableLanguages,
          requestedLanguage: language
        });

        console.log('Processed property data:', {
          id: processedData.id,
          name: processedData.name,
          language: processedData.currentLanguage,
          hasConfig: !!processedData.localizedData.property_config,
          hasPropertyData: !!processedData.localizedData.property_data
        });

        setPropertyData(processedData);
        setCurrentLanguage(processedData.currentLanguage);
      } else {
        console.warn(`Property not found: ${propertyId}`, {
          userType: currentUser?.role,
          path: propertyPath
        });
        handleFetchError('Property not found');
      }
    } catch (err) {
      console.error('Error fetching property data:', {
        error: err.message,
        propertyId,
        userType: currentUser?.role,
        language
      });
      handleFetchError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Function to select a property
  const selectProperty = async (propertyId) => {
    try {
      updateCurrentPropertyId(propertyId);
      await fetchPropertyData(propertyId);
      return true;
    } catch (error) {
      console.error('Error selecting property:', error);
      handleFetchError(error.message);
      return false;
    }
  };

  // Auto-select property when user logs in
  useEffect(() => {
    const autoSelectProperty = async () => {
      if (currentUser) {
        try {
          const db = getDatabase();
          let propertyId = currentPropertyId;

          console.log('Auto-select property - Current User:', {
            uid: currentUser.uid,
            email: currentUser.email,
            role: currentUser.role
          });

          // Get user's default language from global settings
          const userSettingsRef = ref(db, `users/${currentUser.uid}/global_settings/language`);
          const languageSnapshot = await get(userSettingsRef);
          
          if (languageSnapshot.exists()) {
            const { defaultLanguage } = languageSnapshot.val();
            setCurrentLanguage(defaultLanguage);
          }

          // If no property is selected, try to load the first one
          if (!propertyId) {
            const userPropertiesRef = ref(db, `users/${currentUser.uid}/properties`);
            const propertiesSnapshot = await get(userPropertiesRef);
            
            console.log('User properties snapshot:', {
              exists: propertiesSnapshot.exists(),
              val: propertiesSnapshot.val()
            });
            
            if (propertiesSnapshot.exists()) {
              const properties = propertiesSnapshot.val();
              const propertyIds = Object.keys(properties);
              
              if (propertyIds.length > 0) {
                propertyId = propertyIds[0];
                console.log('Auto-selected property:', propertyId);
                updateCurrentPropertyId(propertyId);
              }
            }
          }

          // Load property data if there is an ID
          if (propertyId) {
            await fetchPropertyData(propertyId);
          }
        } catch (err) {
          console.error('Error in auto-select property:', err);
          handleFetchError(err.message);
        }
      }
    };

    autoSelectProperty();
  }, [currentUser]);

  // Function to change the current language
  const changeLanguage = async (language) => {
    if (currentPropertyId && language !== currentLanguage) {
      await fetchPropertyData(currentPropertyId, language);
    }
  };

  // Context value to be provided
  const value = {
    currentPropertyId,
    setCurrentPropertyId: updateCurrentPropertyId,
    propertyData,
    loading,
    error,
    fetchPropertyData,
    currentLanguage,
    changeLanguage,
    selectProperty
  };

  // Provide the context to the children components
  return (
    <PropertyContext.Provider value={value}>
      {children}
    </PropertyContext.Provider>
  );
};
