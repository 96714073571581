import React from 'react';
import { Tooltip, styled } from '@mui/material';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(33, 33, 33, 0.98)'
      : 'rgba(255, 255, 255, 0.98)',
    color: theme.palette.mode === 'dark' 
      ? '#ffffff'
      : 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.8125rem',
    fontWeight: 500,
    padding: '8px 12px',
    borderRadius: '8px',
    maxWidth: 300,
    wordWrap: 'break-word',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 4px 20px 0 rgba(0,0,0,0.5)'
      : '0 4px 12px rgba(0,0,0,0.1)',
    border: theme.palette.mode === 'dark'
      ? '1px solid rgba(255, 255, 255, 0.1)'
      : '1px solid rgba(0, 0, 0, 0.08)',
    letterSpacing: '0.15px',
    lineHeight: '1.4',
    transition: 'all 0.2s ease-in-out',
  },
  '& .MuiTooltip-arrow': {
    color: theme.palette.mode === 'dark'
      ? 'rgba(33, 33, 33, 0.98)'
      : 'rgba(255, 255, 255, 0.98)',
    '&::before': {
      border: theme.palette.mode === 'dark'
        ? '1px solid rgba(255, 255, 255, 0.1)'
        : '1px solid rgba(0, 0, 0, 0.08)',
      backgroundColor: theme.palette.mode === 'dark'
        ? 'rgba(33, 33, 33, 0.98)'
        : 'rgba(255, 255, 255, 0.98)',
    },
  },
  '& .MuiTooltip-tooltipPlacementTop': {
    transform: 'translateY(-4px)',
  },
  '& .MuiTooltip-tooltipPlacementBottom': {
    transform: 'translateY(4px)',
  },
  '& .MuiTooltip-tooltipPlacementLeft': {
    transform: 'translateX(-4px)',
  },
  '& .MuiTooltip-tooltipPlacementRight': {
    transform: 'translateX(4px)',
  },
}));

const CustomTooltip = ({ 
  title, 
  children, 
  placement = 'top', 
  arrow = true,
  enterDelay = 200,
  leaveDelay = 0,
  ...props 
}) => {
  return (
    <StyledTooltip 
      title={title}
      placement={placement}
      arrow={arrow}
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
      {...props}
    >
      {children}
    </StyledTooltip>
  );
};

export default CustomTooltip;
