import React from "react";
import { Typography, Box, Tabs, Tab } from "@mui/material";
import { useTranslation } from "react-i18next";

const SettingsHeader = ({ currentTab, onTabChange }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
      <Typography variant="h5" component="h1" gutterBottom>
        {t("settings.title")}
      </Typography>
      <Tabs 
        value={currentTab} 
        onChange={(e, newValue) => onTabChange(newValue)}
        sx={{
          '& .MuiTab-root': {
            textTransform: 'none',
            minWidth: 120,
            fontWeight: 500,
          },
        }}
      >
        <Tab value="guide" label={t("settings.tabs.guides")} />
        <Tab value="appearance" label={t("settings.tabs.appearance")} />
        <Tab value="account" label={t("settings.tabs.account")} />
      </Tabs>
    </Box>
  );
};

export default SettingsHeader;
