import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
  Collapse,
  Avatar} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Emergency as EmergencyIcon,
  Build as BuildIcon,
  CleaningServices as CleaningServicesIcon,
  Person as PersonIcon,
  Contacts as ContactsIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Notes as NotesIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useTheme, alpha } from '@mui/material/styles';
import CustomTooltip from '../common/CustomTooltip';
import ConfirmationDialog from '../common/ConfirmationDialog';

const ContactCard = ({ contact, onDelete, onEdit, type }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const getContactTypeIcon = (type) => {
    switch (type) {
      case 'emergency':
        return <EmergencyIcon />;
      case 'maintenance':
        return <BuildIcon />;
      case 'cleaning':
        return <CleaningServicesIcon />;
      case 'host':
        return <PersonIcon />;
      default:
        return <ContactsIcon />;
    }
  };

  const getContactTypeLabel = (type) => {
    return t(`propertyManagement.contacts.types.${type}`);
  };


  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  return (
    <Card
      elevation={0}
      sx={{
        mb: 2,
        border: 1,
        borderColor: 'divider',
        borderRadius: 2,
        transition: 'background-color 0.2s ease',
        maxWidth: 600,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
          bgcolor: alpha(theme.palette.primary.main, 0.04)
        }
      }}
    >
      <CardHeader
        sx={{
          pb: 0,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
        title={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Avatar
              sx={{
                bgcolor: alpha(theme.palette.primary.main, 0.1),
                color: 'primary.main',
                width: 48,
                height: 48
              }}
            >
              {getContactTypeIcon(contact.type)}
            </Avatar>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'medium', lineHeight: 1.3, mb: 0.1 }}>
                {contact.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {getContactTypeLabel(contact.type)}
              </Typography>
            </Box>
          </Box>
        }
        action={
          <Box sx={{ display: 'flex' }}>
            <CustomTooltip title={t('common.edit')}>
              <IconButton
                onClick={onEdit}
                size="small"
                sx={{
                  bgcolor: alpha(theme.palette.primary.main, 0.1),
                  color: 'primary.main',
                  '&:hover': {
                    bgcolor: alpha(theme.palette.primary.main, 0.2)
                  }
                }}
                aria-label={t('common.edit')}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </CustomTooltip>
            <CustomTooltip title={t('common.delete')}>
              <IconButton
                onClick={handleDeleteClick}
                size="small"
                sx={{
                  bgcolor: alpha(theme.palette.error.main, 0.1),
                  color: 'error.main',
                  '&:hover': {
                    bgcolor: alpha(theme.palette.error.main, 0.2)
                  }
                }}
                aria-label={t('common.delete')}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </CustomTooltip>
          </Box>
        }
      />
      <CardContent sx={{ pt: 0, display: 'flex', flexDirection: 'column', gap: 1, px: 2, pb: 0, mt: 1 }}>
        {contact.notes && (
          <Box
            onClick={() => setExpanded(!expanded)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              p: 1.5,
              bgcolor: alpha(theme.palette.background.default, 0.6),
              borderRadius: 1,
              cursor: 'pointer',
              '&:hover': {
                bgcolor: alpha(theme.palette.background.default, 0.8)
              }
            }}
            aria-label={t('contacts.notes')}
          >
            <NotesIcon sx={{ color: 'text.secondary' }} />
            <Typography sx={{ flex: 1, color: 'text.secondary' }}>
              {t('contacts.notes')}
            </Typography>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
        )}
        {contact.notes && (
          <Collapse in={expanded} transition="auto">
            <Typography 
              variant="body2" 
              sx={{ 
                mt: 1.5,
                px: 2,
                py: 1.5,
                color: 'text.secondary',
                bgcolor: alpha(theme.palette.background.default, 0.6),
                borderRadius: 1,
                border: '1px solid',
                borderColor: alpha(theme.palette.divider, 0.1)
              }}
            >
              {contact.notes}
            </Typography>
          </Collapse>
        )}
      </CardContent>
      <ConfirmationDialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        onConfirm={onDelete}
        title={t('propertyManagement.contacts.deleteConfirmationTitle')}
        content={t('propertyManagement.contacts.deleteConfirmationContent')}
      />
    </Card>
  );
};

export default ContactCard;
