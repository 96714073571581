import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

const LocationMap = ({ location }) => {
  const theme = useTheme();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "", // Modo de desenvolvimento
  });

  const mapContainerStyle = {
    width: "100%",
    height: "300px",
    borderRadius: theme.shape.borderRadius,
  };

  return (
    <Box>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={location}
          zoom={15}
          options={{
            disableDefaultUI: true,
            zoomControl: true,
            styles: [
              {
                featureType: "poi",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
            ],
          }}
        >
          <Marker position={location} />
        </GoogleMap>
      ) : (
        <Box
          sx={{
            height: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "grey.100",
            borderRadius: theme.shape.borderRadius,
          }}
        >
          <Typography variant="body1" color="text.secondary">
            Carregando mapa...
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default LocationMap;
