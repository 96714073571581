import { useState, useEffect } from "react";
import { useProperty } from "../contexts/PropertyContext";
import { useTranslation } from "react-i18next";
import { getDatabase, ref, get, set } from "firebase/database";

const useLocalTips = () => {
  const { t } = useTranslation();
  const { currentPropertyId, currentLanguage } = useProperty();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [localTips, setLocalTips] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadLocalTips = async () => {
      if (!currentPropertyId) {
        setError("Property ID is required");
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        setError(null);

        const propertyPath = `properties/${currentPropertyId}/languages/${currentLanguage}/local_tips`;
        const tipsRef = ref(getDatabase(), propertyPath);
        const snapshot = await get(tipsRef);

        if (snapshot.exists()) {
          const tips = snapshot.val();
          setLocalTips(Array.isArray(tips) ? tips : Object.values(tips));
        } else {
          setLocalTips([]);
        }
      } catch (err) {
        console.error("Error loading local tips:", err);
        setError(t("common.errorMessage"));
      } finally {
        setIsLoading(false);
      }
    };

    loadLocalTips();
  }, [currentPropertyId, currentLanguage, t]);

  const saveLocalTips = async (updatedTips) => {
    if (!currentPropertyId) {
      setError(t("common.propertyIdRequired"));
      return false;
    }

    try {
      setIsSaving(true);
      setError(null);

      const propertyPath = `properties/${currentPropertyId}/languages/${currentLanguage}/local_tips`;
      const tipsRef = ref(getDatabase(), propertyPath);
      await set(tipsRef, updatedTips);

      return true;
    } catch (err) {
      console.error("Error saving local tips:", err);
      setError(t("common.errorMessage"));
      return false;
    } finally {
      setIsSaving(false);
    }
  };

  const addTip = async (tip) => {
    if (!tip.title || !tip.address) {
      setError(t("localTips.invalidData"));
      return false;
    }

    const newTip = {
      ...tip,
      id: `tip_${Date.now()}`,
      createdAt: new Date().toISOString(),
    };

    const updatedTips = [...localTips, newTip];
    const success = await saveLocalTips(updatedTips);
    if (success) {
      setLocalTips(updatedTips);
    }
    return success;
  };

  const removeTip = async (tipId) => {
    if (!tipId) {
      setError(t("localTips.invalidId"));
      return false;
    }

    const updatedTips = localTips.filter((tip) => tip.id !== tipId);
    const success = await saveLocalTips(updatedTips);
    if (success) {
      setLocalTips(updatedTips);
    }
    return success;
  };

  const updateTip = async (editedTip) => {
    if (!editedTip.id) {
      setError(t("localTips.invalidData"));
      return false;
    }

    const updatedTips = localTips.map(tip => 
      tip.id === editedTip.id ? { ...tip, ...editedTip } : tip
    );

    const success = await saveLocalTips(updatedTips);
    if (success) {
      setLocalTips(updatedTips);
    }
    return success;
  };

  return {
    localTips,
    isLoading,
    isSaving,
    error,
    addTip,
    removeTip,
    updateTip
  };
};

export default useLocalTips;
