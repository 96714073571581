import React, { useRef, useState } from 'react';
import { Paper, Box, IconButton, TextField } from '@mui/material';
import { 
  DragHandle as DragHandleIcon, 
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { useDrop, useDrag } from 'react-dnd';
import { useTheme, alpha } from '@mui/material/styles';
import CustomTooltip from '../common/CustomTooltip';
import { useTranslation } from 'react-i18next';

const ItemTypes = {
  RULE: 'rule'
};

const RuleItem = ({ id, index, rule, moveRule, handleRemoveRule, handleEditRule, isEditingRules }) => {
  const ref = useRef(null);
  const theme = useTheme();
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [editedRule, setEditedRule] = useState(rule);

  const startEditing = () => {
    setIsEditing(true);
    setEditedRule(rule);
  };

  const cancelEditing = () => {
    setIsEditing(false);
    setEditedRule(rule);
  };

  const saveEdit = () => {
    if (editedRule.trim() && editedRule !== rule) {
      handleEditRule(index, editedRule);
    }
    setIsEditing(false);
  };

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.RULE,
    item: { type: ItemTypes.RULE, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => isEditingRules && !isEditing,
  });

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.RULE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current || !isEditingRules || isEditing) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveRule(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));

  return (
    <Paper
      elevation={0}
      ref={ref}
      data-handler-id={handlerId}
      sx={{
        mb: 1.5,
        opacity,
        transition: 'all 0.2s ease',
        border: '1px solid',
        borderColor: theme => alpha(theme.palette.divider, 0.1),
        bgcolor: theme => alpha(theme.palette.background.paper, 0.8),
        '&:hover': {
          boxShadow: isEditingRules ? `0 2px 8px ${alpha('#000', 0.08)}` : 'none',
          transform: isEditingRules ? 'translateY(-2px)' : 'none',
          borderColor: theme => isEditingRules ? alpha(theme.palette.primary.main, 0.2) : alpha(theme.palette.divider, 0.1)
        }
      }}
    >
      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center',
          p: 2,
          gap: 1.5
        }}
      >
        <Box 
          sx={{ 
            cursor: isEditingRules && !isEditing ? 'move' : 'default',
            display: 'flex',
            alignItems: 'center',
            color: theme => isEditingRules ? alpha(theme.palette.text.primary, 0.4) : alpha(theme.palette.text.primary, 0.2),
            '&:hover': {
              color: theme => isEditingRules ? alpha(theme.palette.text.primary, 0.6) : alpha(theme.palette.text.primary, 0.2)
            }
          }}
        >
          <DragHandleIcon />
        </Box>
        
        <Box sx={{ flex: 1 }}>
          {isEditing ? (
            <TextField
              fullWidth
              size="small"
              value={editedRule}
              onChange={(e) => setEditedRule(e.target.value)}
              autoFocus
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  saveEdit();
                }
              }}
            />
          ) : (
            rule
          )}
        </Box>

        {isEditingRules && (
          <Box sx={{ display: 'flex', gap: 1 }}>
            {isEditing ? (
              <>
                <CustomTooltip title={t('propertyManagement.houseRules.cancelEdit')}>
                  <IconButton
                    onClick={cancelEditing}
                    size="small"
                    sx={{
                      color: theme.palette.text.secondary,
                      '&:hover': {
                        bgcolor: alpha(theme.palette.text.secondary, 0.1),
                      },
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </CustomTooltip>
                <CustomTooltip title={t('propertyManagement.houseRules.saveRule')}>
                  <IconButton
                    onClick={saveEdit}
                    size="small"
                    disabled={!editedRule.trim() || editedRule === rule}
                    sx={{
                      color: theme.palette.success.main,
                      '&:hover': {
                        bgcolor: alpha(theme.palette.success.main, 0.1),
                      },
                    }}
                  >
                    <SaveIcon fontSize="small" />
                  </IconButton>
                </CustomTooltip>
              </>
            ) : (
              <>
                <CustomTooltip title={t('propertyManagement.houseRules.editRule')}>
                  <IconButton
                    onClick={startEditing}
                    size="small"
                    sx={{
                      color: theme.palette.primary.main,
                      '&:hover': {
                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                      },
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </CustomTooltip>
                <CustomTooltip title={t('propertyManagement.houseRules.removeRule')}>
                  <IconButton
                    onClick={handleRemoveRule}
                    size="small"
                    sx={{
                      color: theme.palette.error.main,
                      '&:hover': {
                        bgcolor: alpha(theme.palette.error.main, 0.1),
                      },
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </CustomTooltip>
              </>
            )}
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default RuleItem;
