import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  pt: {
    translation: {
      appName: "Guia Digital",
      home: {
        welcome: "Bem-vindo(a) ao seu Guia Digital",
        subtitle:
          "Tudo o que você precisa saber sobre sua estadia, em um só lugar.",
        propertyInfo: "Informações do Imóvel",
        propertyInfoDesc:
          "Acesse informações detalhadas sobre o imóvel, incluindo check-in/out e regras da casa.",
        location: "Localização",
        locationDesc: "Descubra como chegar e explore a região ao redor.",
        localTips: "Dicas Locais",
        localTipsDesc:
          "Conheça restaurantes, atrações e serviços essenciais na região.",
        restaurants: "Restaurantes",
        restaurantsDesc: "Descubra os melhores lugares para comer próximos.",
        amenities: "Facilidades",
        amenitiesDesc: "Conheça todas as comodidades disponíveis.",
        parking: "Estacionamento",
        parkingDesc: "Informações sobre estacionamento e garagem.",
        wifi: "Wi-Fi",
        wifiDesc: "Acesse a internet de alta velocidade.",
        emergency: "Contatos e Emergência",
        emergencyDesc: "Informações importantes para emergências.",
      },
      propertyInfo: {
        title: "Informações do Imóvel",
        subtitle: "Tudo o que você precisa saber sobre sua estadia",
        overview: {
          checkAvailability: "Verificar Disponibilidade",
          guests: "hóspedes",
          bedrooms: "quartos",
          bathrooms: "banheiros",
          amenities: "Comodidades",
          reviews: "avaliações",
          night: "noite",
          kitchen: "Cozinha",
          freeParking: "Estacionamento gratuito",
          tv: "TV",
          airConditioning: "Ar condicionado",
          wifi: "Wi-Fi",
          pool: "Piscina",
          washer: "Máquina de lavar",
          hairDryer: "Secador de cabelo",
        },
        checkinTitle: "Check-in",
        checkinSubtitle: "Horário de entrada",
        checkinDescription:
          "O check-in pode ser feito a partir das 14h. Por favor, certifique-se de ter o código de acesso em mãos antes de chegar.",
        checkoutTitle: "Check-out",
        checkoutSubtitle: "Horário de saída",
        checkoutDescription:
          "O check-out deve ser realizado até as 12h. Por favor, certifique-se de não deixar pertences pessoais no local.",
        addToCalendar: "Adicionar ao Calendário",
        addToCalendarShort: "Add Calendário",
        rulesTitle: "Regras da Casa",
        rulesSubtitle: "Diretrizes importantes para sua estadia",
        petsRule: "Não são permitidos animais de estimação",
        smokingRule: "Proibido fumar em qualquer área do imóvel",
        noiseRule: "Respeite o horário de silêncio (22h às 8h)",
        wifiTitle: "Wi-Fi e Códigos de Acesso",
        wifiSubtitle: "Conecte-se à internet",
        wifiContent: "Rede: AirbnbHome_5G\nSenha: welcome2024",
        parkingTitle: "Estacionamento",
        parkingSubtitle: "Informações sobre garagem",
        parkingContent: "Garagem coberta disponível para 1 veículo",
        cleaningTitle: "Limpeza",
        cleaningSubtitle: "Serviços e procedimentos",
        cleaningContent:
          "Limpeza profissional entre as estadias. Produtos de limpeza disponíveis sob a pia.",
        securityTitle: "Segurança",
        securitySubtitle: "Informações de emergência",
        securityContent:
          "Extintor de incêndio na cozinha. Kit de primeiros socorros no armário do banheiro.",
      },
      localTips: {
        title: "Dicas Locais",
        subtitle: "Descubra os melhores lugares da cidade",
        categories: {
          restaurants: "Restaurantes",
          attractions: "Atrações Turísticas",
          shopping: "Compras",
        },
        itemRemoved: "Local removido com sucesso",
        restaurants: "Restaurantes",
        attractions: "Atrações",
        services: "Serviços",
      },
      navigation: {
        home: "Início",
        propertyInfo: "Informações",
        localTips: "Dicas Locais",
        amenities: "Facilidades",
        settings: "Configurações",
        edit: "Editar",
        logout: "Sair",
        login: "Entrar",
      },
      login: {
        welcome: "Bem-vindo de volta!",
        welcomeMessage: "Acesse sua conta para gerenciar seus guias",
        email: "E-mail",
        password: "Senha",
        rememberMe: "Lembrar-me",
        forgotPassword: "Esqueceu a senha?",
        submit: "Entrar",
        loginWithGoogle: "Continuar com Google",
        noAccount: "Não tem uma conta?",
        createAccount: "Criar conta",
        errors: {
          invalidCredentials: "E-mail ou senha inválidos",
          genericError: "Erro ao fazer login. Tente novamente.",
        },
        subtitle: "Gerencie seus guias e propriedades",
      },
      guestLogin: {
        welcome: "Área do Hóspede",
        welcomeMessage: "Acesse todas as informações da sua hospedagem",
        email: "Digite seu e-mail de acesso",
        emailRequired: "Por favor, insira seu e-mail",
        submit: "Acessar Guia",
        back: "Voltar",
        error: "Erro ao acessar o guia",
        noProperties: "Nenhuma propriedade encontrada",
        noActiveAccess: "Nenhum acesso ativo encontrado",
        subtitle: "Acesse o guia da sua hospedagem com seu e-mail",
        welcomeMessages: [
          "Sua experiência única começa aqui",
          "Explore todos os detalhes da sua estadia",
          "Seu guia personalizado está pronto",
          "Descubra tudo sobre sua hospedagem",
        ],
      },
      settings: {
        title: "Configurações",
        subtitle: "Gerencie suas preferências",
        tabs: {
          guides: "Meu(s) Guia(s)",
          appearance: "Aparência",
          language: "Idioma",
          account: "Minha Conta",
        },
        guide: {
          title: "Meus Guias",
          subtitle: "Gerencie seus guias digitais",
          addButton: "Adicionar Guia",
          deleted: "Guia removido com sucesso",
          synced: "Sincronizar Guia com Airbnb",
          lastUpdate: "Última atualização",
          hosts: "hosts",
          activeStatus: "Ativo",
          viewGuide: "Ver Guia",
          manageUsers: "Gerenciar Usuários",
          guideSelected: "Guide selecionado!",
          addNewUser: "Adicionar novo usuário",
          emailPlaceholder: "Digite o email do usuário",
          addUser: "Adicionar",
          userAdded: "Usuário adicionado com sucesso",
          userRemoved: "Usuário removido com sucesso",
          close: "Fechar",
          status: {
            active: "Selecionado",
            draft: "Rascunho",
          },
          roles: {
            admin: "Administrador",
            editor: "Editor",
            viewer: "Visualizador",
          },
        },
        appearance: {
          title: "Aparência",
          subtitle: "Altere a aparência do seu painel público",
          brandColor: {
            title: "Cor da marca",
            subtitle: "Selecione ou personalize a cor da sua marca",
          },
          charts: {
            title: "Gráficos do painel",
            subtitle: "Como os gráficos são exibidos",
            viewExamples: "Ver exemplos",
            default: {
              title: "Padrão",
              desc: "Identidade visual padrão da empresa",
            },
            simplified: {
              title: "Simplificado",
              desc: "Minimalista e moderno",
            },
            custom: {
              title: "CSS Personalizado",
              desc: "Gerencie o estilo com CSS",
            },
          },
          language: {
            title: "Idioma",
            subtitle: "Idioma padrão para o painel público",
          },
          cookies: {
            title: "Banner de cookies",
            subtitle: "Exibir banners de cookies para visitantes",
            viewExamples: "Ver exemplos",
            default: {
              title: "Padrão",
              desc: "Controles de cookies para visitantes",
            },
            simplified: {
              title: "Simplificado",
              desc: "Exibir um banner simplificado",
            },
            none: {
              title: "Nenhum",
              desc: "Não exibir nenhum banner",
            },
          },
        },
        actions: {
          save: "Salvar alterações",
          cancel: "Cancelar",
        },
        language: "Idioma",
        theme: "Tema",
        dark: "Escuro",
        light: "Claro",
        account: {
          personalInfo: "Informações Pessoais",
          editProfile: "Editar Perfil",
          currentPlan: "Plano Atual",
          availablePlans: "Planos Disponíveis",
          upgrade: "Fazer Upgrade",
          popular: "Mais Popular",
          joinedOn: "Membro desde",
          nextBilling: "Próxima cobrança",
          currentPlanButton: "Plano Atual",
          selectPlan: "Selecionar Plano",
          plans: {
            basic: {
              name: "Básico",
              feature1: "1 guia digital",
              feature2: "Recursos básicos",
              feature3: "Suporte por email",
            },
            pro: {
              name: "Profissional",
              feature1: "5 guias digitais",
              feature2: "Todos os recursos básicos",
              feature3: "Personalização avançada",
              feature4: "Suporte prioritário",
            },
            enterprise: {
              name: "Empresarial",
              feature1: "Guias ilimitados",
              feature2: "Todos os recursos Pro",
              feature3: "API dedicada",
              feature4: "Gerenciamento de equipe",
              feature5: "Suporte 24/7",
            },
            period: {
              free: "grátis",
              monthly: "mês",
            },
          },
        },
        accessDenied: {
          title: "Acesso Negado",
          message:
            "Você não tem permissão para acessar esta página de configurações.",
          noActiveProperties:
            "Nenhuma propriedade ativa encontrada para este usuário.",
        },
      },
      common: {
        close: "Fechar",
        cancel: "Cancelar",
        submit: "Enviar",
        edit: "Editar",
        delete: "Excluir",
        save: "Salvar",
        savedSuccessfully: "Salvo com sucesso!",
        add: "Adicionar",
      },
      language: {
        select: "Selecione o idioma",
        request: "Solicitar novo idioma",
        requestTitle: "Solicitar nova tradução",
        requestDescription:
          "Nos ajude a tornar nosso aplicativo mais acessível. Informe qual idioma você gostaria de ver disponível e por quê.",
        requestLanguageLabel: "Idioma desejado",
        requestReasonLabel: "Por que este idioma é importante?",
        requestSuccess:
          "Solicitação enviada com sucesso! Agradecemos seu feedback.",
        submit: "Enviar solicitação",
      },
      wifi: {
        title: "Informações do Wi-Fi",
        networkName: "Nome da Rede",
        password: "Senha",
        copied: "Copiado!",
        copyPassword: "Copiar senha",
        scanQRCode: "Escaneie o QR code para conectar automaticamente",
      },
      emergency: {
        title: "Contatos de Emergência",
        official: "Serviços de Emergência Oficiais",
        property: "Contatos do Imóvel",
        police: "Polícia",
        firefighters: "Bombeiros",
        ambulance: "Ambulância",
        security: "Segurança Privada",
        owner: "Proprietário do Imóvel",
        call: "Ligar",
        whatsapp: "Enviar mensagem pelo WhatsApp",
      },
      amenities: {
        title: "Facilidades",
        subtitle: "Conheça todas as comodidades disponíveis para sua estadia",
        comfort: "Conforto",
        comfortDesc: "Comodidades para seu bem-estar",
        essentials: "Essenciais",
        essentialsDesc: "Itens básicos para sua estadia",
        facilities: "Instalações",
        facilitiesDesc: "Áreas e serviços do imóvel",
        entertainment: "Entretenimento",
        entertainmentDesc: "Opções de lazer e diversão",
        services: "Serviços",
        servicesDesc: "Serviços disponíveis para você",
        pool: {
          name: "Piscina",
          desc: "Piscina aquecida com raia de 25m",
          details: "Horário: 7h às 22h | Temperatura: 28°C",
        },
        kitchen: {
          name: "Cozinha Equipada",
          desc: "Cozinha completa com todos utensílios",
          details: "Fogão | Geladeira | Micro-ondas | Cafeteira",
        },
        wifi: {
          name: "Wi-Fi de Alta Velocidade",
          desc: "Internet fibra em todo apartamento",
          details: "300Mbps | Rede 5G disponível",
        },
        ac: {
          name: "Ar Condicionado",
          desc: "Climatização em todos ambientes",
          details: "Controle individual | Sleep noturno | 22°C",
        },
        parking: {
          name: "Estacionamento",
          desc: "Vaga coberta e segura",
          details: "Acesso 24h | Monitorado | Vaga privativa",
        },
        laundry: {
          name: "Lavanderia",
          desc: "Máquina de lavar e secar",
          details: "Produtos inclusos | Tábua de passar | 24h",
        },
        tv: {
          name: "Smart TV",
          desc: 'TV 55" com streaming',
          details: "Netflix | Prime Video | Disney+",
        },
        coffee: {
          name: "Cafeteira",
          desc: "Nespresso com cápsulas",
          details: "Cápsulas cortesia | Chás diversos",
        },
        cleaning: {
          name: "Serviço de Limpeza",
          desc: "Limpeza profissional",
          details: "Entre estadias | Produtos inclusos | Troca de roupas",
        },
        pets: {
          name: "Pet Friendly",
          desc: "Aceitamos animais de estimação",
          details: "Máx. 2 pets | Até 10kg | Sem taxa",
        },
        bathroom: {
          name: "Banheiro Completo",
          desc: "Banheiro com amenidades",
          details: "Toalhas | Secador | Produtos de higiene",
        },
        balcony: {
          name: "Varanda",
          desc: "Varanda com vista",
          details: "Mobiliada | Vista cidade | Rede",
        },
        iron: {
          name: "Ferro de Passar",
          desc: "Kit para passar roupas",
          details: "Ferro a vapor | Tábua | Cabides",
        },
        dining: {
          name: "Sala de Jantar",
          desc: "Mesa de jantar completa",
          details: "Mesa 6 lugares | Louças | Talheres",
        },
        gym: {
          name: "Academia",
          desc: "Academia completa",
          details: "Cardio | Musculação | 24h",
        },
        games: {
          name: "Sala de Jogos",
          desc: "Área de entretenimento",
          details: "Sinuca | Ping Pong | Videogame",
        },
        beach: {
          name: "Kit Praia",
          desc: "Equipamentos para praia",
          details: "Guarda-sol | Cadeiras | Cooler",
        },
        bar: {
          name: "Bar",
          desc: "Bar equipado",
          details: "Coqueteleira | Taças | Gelo",
        },
        workspace: {
          name: "Espaço de Trabalho",
          desc: "Área para home office",
          details: "Mesa | Cadeira ergonômica | Luz natural",
        },
        living: {
          name: "Sala de Estar",
          desc: "Sala ampla e confortável",
          details: "Sofá-cama | Smart TV | Som ambiente",
        },
        bedroom: {
          name: "Quarto",
          desc: "Quarto com cama king size",
          details: "Colchão premium | Blackout | Ar condicionado",
        },
        elevator: {
          name: "Elevador",
          desc: "Acesso facilitado",
          details: "Social e Serviço | 24h",
        },
        security: {
          name: "Segurança",
          desc: "Sistema de segurança",
          details: "Câmeras | Portaria 24h | Cofre",
        },
      },
      location: {
        title: "Localização",
        address: "Endereço",
        howToArrive: "Como Chegar",
        directions:
          "Utilize o botão abaixo para abrir o Google Maps e obter direções até o local.",
        getDirections: "Obter Direções no Google Maps",
      },
      propertyManagement: {
        title: "Gerenciar Propriedade",
        propertyTitle: "Título da Propriedade",
        description: "Descrição",
        price: "Preço por noite",
        location: {
          title: "Localização",
          address: "Endereço",
          street: "Rua",
          number: "Número",
          complement: "Complemento",
          neighborhood: "Bairro",
          city: "Cidade",
          state: "Estado",
          country: "País",
          postalCode: "CEP",
          coordinates: "Coordenadas",
          instructions: "Instruções de Acesso",
          reference: "Referência",
        },
        wifi: {
          title: "Wi-Fi",
          networkName: "Nome da Rede",
          password: "Senha",
          qrCode: "Gerar QR Code",
        },
        electronicKey: {
          title: "Chave Eletrônica",
          code: "Código",
          instructions: "Instruções de Uso",
          addInstruction: "Adicionar Instrução",
          notes: "Observações",
          addNote: "Adicionar Observação",
          dragToReorder: "Arraste para reordenar",
        },
        contacts: {
          title: "Contatos",
          addContact: "Adicionar Contato",
          editContact: "Editar Contato",
          name: "Nome",
          phone: "Telefone",
          type: "Tipo",
          notes: "Observações",
          types: {
            emergency: "Emergência",
            owner: "Proprietário",
            ambulance: "Ambulância",
            maintenance: "Manutenção",
            cleaning: "Limpeza",
            host: "Anfitrião",
            other: "Outro",
          },
        },
        houseRules: {
          title: "Regras da Casa",
          description: "Defina as regras da casa para sua propriedade",
          status: {
            allowed: "Permitido",
            notAllowed: "Não Permitido",
          },
          additionalRulesTitle: "Regras Adicionais",
          additionalRulesPlaceholder: "Adicione uma nova regra",
          editMode: "Modo de Edição",
          exitEditMode: "Sair do Modo de Edição",
          dragToReorder: "Arraste para reordenar as regras",
          editRule: "Editar regra",
          saveRule: "Salvar regra",
          cancelEdit: "Cancelar edição",
          removeRule: "Remover regra",
          allowPets: "Animais de Estimação",
          allowSmoking: "Fumar",
          allowEvents: "Eventos e Festas",
          allowChildren: "Crianças",
          allowLateCheckout: "Check-out Tardio",
          allowEarlyCheckin: "Check-in Antecipado",
          allowMusic: "Música Alta",
          allowVisitors: "Visitantes",
          petsDescription: "Aceita animais de estimação na propriedade",
          smokingDescription: "Permite fumar dentro ou nas áreas externas",
          eventsDescription: "Permite realização de eventos e festas",
          childrenDescription: "Adequado para crianças (2-12 anos)",
          lateCheckoutDescription: "Permite check-out após o horário padrão",
          earlyCheckinDescription: "Permite check-in antes do horário padrão",
          musicDescription: "Permite música alta ou sons que possam perturbar",
          visitorsDescription: "Permite visitantes não registrados no imóvel",
        },
      },
      searchPlaces: {
        title: "Pesquisar Locais",
        searchPlaceholder: "Digite o nome do local...",
        add: "Adicionar",
        emptyQuery: "Digite algo para pesquisar",
        noResults: "Nenhum resultado encontrado",
        error: "Erro ao buscar locais. Tente novamente.",
        placeAdded: "Local adicionado com sucesso!",
        viewOnMap: "Ver no Mapa",
        selectCategory: "Selecionar categoria",
      },
      landing: {
        title: "Bem-vindo ao Guia Digital",
        subtitle: "Seu guia completo para uma experiência única",
        welcomeBack: "Escolha como deseja acessar o sistema",
        hostButton: "Área do Anfitrião",
        hostSubtext: "Gerencie seus guias e propriedades",
        guestButton: "Área do Hóspede",
        guestSubtext: "Acesse o guia da sua hospedagem",
        newHostText: "Ainda não tem um guia?",
        createGuideLink: "Crie agora mesmo!",
        welcomeMessages: [
          "Sua experiência única começa aqui",
          "Gerencie suas propriedades com facilidade",
          "Tudo que você precisa em um só lugar",
          "Crie guias incríveis para seus hóspedes",
        ],
      },
      resetPassword: {
        title: "Recuperar Senha",
        subtitle: "Não se preocupe, vamos te ajudar",
        instructions:
          "Digite seu e-mail abaixo e enviaremos instruções detalhadas para redefinir sua senha com segurança.",
        welcomeMessages: [
          "Processo simples e seguro",
          "Recupere o acesso à sua conta",
          "Instruções direto no seu e-mail",
          "Suporte disponível se precisar",
        ],
        emailLabel: "E-mail",
        emailRequired: "Por favor, insira seu e-mail",
        resetButton: "Enviar E-mail de Recuperação",
        resetEmailSent:
          "E-mail de recuperação enviado com sucesso! Verifique sua caixa de entrada.",
        error: "Erro ao enviar e-mail de recuperação. Tente novamente.",
        backToLogin: "Voltar para o Login",
      },
      createGuide: {
        title: "Criar Guia Digital",
        subtitle: "Transforme seu anúncio do Airbnb em um guia interativo",
        instructions:
          "Insira o link do seu anúncio do Airbnb, seu e-mail e uma senha para criar sua conta e gerar seu primeiro guia.",
        welcomeMessages: [
          "Crie seu guia em poucos minutos",
          "Personalize todas as informações",
          "Compartilhe facilmente com seus hóspedes",
          "Atualize quando quiser",
        ],
        urlLabel: "URL do Airbnb",
        urlPlaceholder: "https://www.airbnb.com.br/rooms/...",
        emailLabel: "Seu E-mail",
        passwordLabel: "Escolha uma Senha",
        submitButton: "Criar Guia",
        backButton: "Voltar",
        allFieldsRequired: "Por favor, preencha todos os campos",
        invalidUrl:
          "URL do Airbnb inválida. Insira uma URL válida do seu anúncio.",
        emailInUse:
          "Este e-mail já está em uso. Tente fazer login ou recuperar sua senha.",
        networkError:
          "Erro de conexão. Verifique sua internet e tente novamente.",
        error: "Erro ao criar o guia. Tente novamente.",
        success:
          "Guia sendo gerado! Você receberá um e-mail quando estiver pronto. Redirecionando para o dashboard...",
      },
      processingGuide: {
        title: "Seu guia está sendo gerado!",
        description:
          "Estamos trabalhando na criação do seu guia personalizado. Você receberá um email assim que ele estiver pronto. Enquanto isso, você pode explorar seu painel de controle.",
        goToDashboard: "Ir para o Painel",
      },
      contacts: {
        title: "Contatos de Emergência",
        addContact: "Adicionar Contato",
        edit: "Editar Contato",
        add: "Adicionar",
        deleteConfirmationTitle: "Excluir Contato",
        deleteConfirmationContent:
          "Tem certeza que deseja excluir este contato?",
        noContacts: "Nenhum contato cadastrado",
        name: "Nome",
        number: "Número",
        role: "Função",
        notes: "Observações",
        notesPlaceholder:
          "Adicione informações importantes sobre este contato...",
        hasWhatsapp: "Possui WhatsApp",
        call: "Ligar",
        whatsapp: "Enviar mensagem no WhatsApp",
        types: {
          police: "Polícia",
          fire: "Bombeiros",
          hospital: "Hospital",
          ambulance: "Ambulância",
          building: "Prédio",
          maintenance: "Manutenção",
          owner: "Proprietário do Imóvel",
          other: "Outro",
        },
      },
    },
  },
  en: {
    translation: {
      appName: "Digital Guide",
      home: {
        welcome: "Welcome to your Digital Guide",
        subtitle: "Everything you need to know about your stay, in one place.",
        propertyInfo: "Property Information",
        propertyInfoDesc:
          "Access detailed information about the property, including check-in/out and house rules.",
        location: "Location",
        locationDesc:
          "Discover how to get here and explore the surrounding area.",
        localTips: "Local Tips",
        localTipsDesc:
          "Get to know restaurants, attractions, and essential services in the area.",
        restaurants: "Restaurants",
        restaurantsDesc: "Discover the best places to eat nearby.",
        amenities: "Amenities",
        amenitiesDesc: "Get to know all the amenities available.",
        parking: "Parking",
        parkingDesc: "Information about parking and garage.",
        wifi: "Wi-Fi",
        wifiDesc: "Access high-speed internet.",
        emergency: "Emergency",
        emergencyDesc: "Important information for emergencies.",
      },
      propertyInfo: {
        title: "Property Information",
        subtitle: "Everything you need to know about your stay",
        overview: {
          checkAvailability: "Check Availability",
          guests: "guests",
          bedrooms: "bedrooms",
          bathrooms: "bathrooms",
          amenities: "Amenities",
          reviews: "reviews",
          night: "night",
          kitchen: "Kitchen",
          freeParking: "Free parking",
          tv: "TV",
          airConditioning: "Air conditioning",
          wifi: "Wi-Fi",
          pool: "Pool",
          washer: "Washer",
          hairDryer: "Hair dryer",
        },
        checkinTitle: "Check-in",
        checkinSubtitle: "Check-in time",
        checkinDescription:
          "Check-in is available from 2:00 PM. Please make sure to have your access code ready before arrival.",
        checkoutTitle: "Check-out",
        checkoutSubtitle: "Check-out time",
        checkoutDescription:
          "Check-out must be completed by 12:00 PM. Please ensure you do not leave any personal belongings behind.",
        addToCalendar: "Add to Calendar",
        addToCalendarShort: "Add Calendar",
        rulesTitle: "House Rules",
        rulesSubtitle: "Important guidelines for your stay",
        petsRule: "No pets allowed",
        smokingRule: "No smoking in any area of the property",
        noiseRule: "Respect the quiet hours (10 pm to 8 am)",
        wifiTitle: "Wi-Fi and Access Codes",
        wifiSubtitle: "Connect to the internet",
        wifiContent: "Network: AirbnbHome_5G\nPassword: welcome2024",
        parkingTitle: "Parking",
        parkingSubtitle: "Information about garage",
        parkingContent: "Covered parking available for 1 vehicle",
        cleaningTitle: "Cleaning",
        cleaningSubtitle: "Services and procedures",
        cleaningContent:
          "Professional cleaning between stays. Cleaning products available under the sink.",
        securityTitle: "Security",
        securitySubtitle: "Emergency information",
        securityContent:
          "Fire extinguisher in the kitchen. First aid kit in the bathroom cabinet.",
      },
      localTips: {
        title: "Local Tips",
        subtitle: "Discover the best places in town",
        categories: {
          restaurants: "Restaurants",
          attractions: "Tourist Attractions",
          shopping: "Shopping",
        },
        itemRemoved: "Place removed successfully",
        restaurants: "Restaurants",
        attractions: "Attractions",
        services: "Services",
      },
      navigation: {
        home: "Home",
        propertyInfo: "Information",
        localTips: "Local Tips",
        amenities: "Amenities",
        settings: "Settings",
        edit: "Edit",
        logout: "Sign Out",
        login: "Sign In",
      },
      login: {
        welcome: "Welcome back!",
        welcomeMessage: "Access your account to manage your guides",
        email: "Email",
        password: "Password",
        rememberMe: "Remember me",
        forgotPassword: "Forgot password?",
        submit: "Sign In",
        loginWithGoogle: "Continue with Google",
        noAccount: "Don't have an account?",
        createAccount: "Create account",
        errors: {
          invalidCredentials: "Invalid email or password",
          genericError: "Login error. Please try again.",
        },
        subtitle: "Manage your guides and properties",
      },
      guestLogin: {
        welcome: "Guest Area",
        welcomeMessage: "Access all information about your stay",
        email: "Enter your access email",
        emailRequired: "Please enter your email",
        submit: "Access Guide",
        back: "Back",
        error: "Error accessing guide",
        noProperties: "No properties found",
        noActiveAccess: "No active access found",
        subtitle: "Access your stay guide with your email",
        welcomeMessages: [
          "Your unique experience starts here",
          "Explore all details about your stay",
          "Your personalized guide is ready",
          "Discover everything about your accommodation",
        ],
      },
      settings: {
        title: "Settings",
        subtitle: "Manage your preferences",
        tabs: {
          guides: "My Guide(s)",
          appearance: "Appearance",
          language: "Language",
          account: "My Account",
        },
        guide: {
          title: "My Guides",
          subtitle: "Manage your digital guides",
          addButton: "Add Guide",
          deleted: "Guide successfully removed",
          synced: "Sync Guide with Airbnb",
          guideSelected: "Guide Selected!",
          lastUpdate: "Last update",
          hosts: "hosts",
          viewGuide: "View Guide",
          manageUsers: "Manage Users",
          addNewUser: "Add new user",
          emailPlaceholder: "Enter user email",
          addUser: "Add",
          userAdded: "User added successfully",
          userRemoved: "User removed successfully",
          close: "Close",
          status: {
            active: "Selected",
            draft: "Draft",
          },
          roles: {
            admin: "Administrator",
            editor: "Editor",
            viewer: "Viewer",
          },
        },
        appearance: {
          title: "Appearance",
          subtitle: "Change the appearance of your public panel",
          brandColor: {
            title: "Brand Color",
            subtitle: "Select or customize your brand color",
          },
          charts: {
            title: "Panel Charts",
            subtitle: "How charts are displayed",
            viewExamples: "View examples",
            default: {
              title: "Default",
              desc: "Company's default visual identity",
            },
            simplified: {
              title: "Simplified",
              desc: "Minimalist and modern",
            },
            custom: {
              title: "Custom CSS",
              desc: "Manage style with CSS",
            },
          },
          language: {
            title: "Language",
            subtitle: "Default language for the public panel",
          },
          cookies: {
            title: "Cookie Banner",
            subtitle: "Display cookie banners for visitors",
            viewExamples: "View examples",
            default: {
              title: "Default",
              desc: "Cookie controls for visitors",
            },
            simplified: {
              title: "Simplified",
              desc: "Display a simplified banner",
            },
            none: {
              title: "None",
              desc: "Do not display any banner",
            },
          },
        },
        actions: {
          save: "Save changes",
          cancel: "Cancel",
        },
        language: "Language",
        theme: "Theme",
        dark: "Dark",
        light: "Light",
        account: {
          personalInfo: "Personal Information",
          editProfile: "Edit Profile",
          currentPlan: "Current Plan",
          availablePlans: "Available Plans",
          upgrade: "Upgrade",
          popular: "Most Popular",
          joinedOn: "Member since",
          nextBilling: "Next billing",
          currentPlanButton: "Current Plan",
          selectPlan: "Select Plan",
          plans: {
            basic: {
              name: "Basic",
              feature1: "1 digital guide",
              feature2: "Basic features",
              feature3: "Email support",
            },
            pro: {
              name: "Professional",
              feature1: "5 digital guides",
              feature2: "All basic features",
              feature3: "Advanced customization",
              feature4: "Priority support",
            },
            enterprise: {
              name: "Enterprise",
              feature1: "Unlimited guides",
              feature2: "All Pro features",
              feature3: "Dedicated API",
              feature4: "Team management",
              feature5: "24/7 support",
            },
            period: {
              free: "free",
              monthly: "month",
            },
          },
        },
        accessDenied: {
          title: "Access Denied",
          message: "You do not have permission to access this settings page.",
          noActiveProperties: "No active properties found for this user.",
        },
      },
      common: {
        close: "Close",
        cancel: "Cancel",
        submit: "Submit",
        edit: "Edit",
        delete: "Delete",
        save: "Save",
        savedSuccessfully: "Saved successfully",
        add: "Add",
      },
      language: {
        select: "Select language",
        request: "Request new language",
        requestTitle: "Request new translation",
        requestDescription:
          "Help us make our app more accessible. Tell us which language you would like to see available and why.",
        requestLanguageLabel: "Desired language",
        requestReasonLabel: "Why is this language important?",
        requestSuccess:
          "Request sent successfully! Thank you for your feedback.",
        submit: "Submit request",
      },
      wifi: {
        title: "Wi-Fi Information",
        networkName: "Network Name",
        password: "Password",
        copied: "Copied!",
        copyPassword: "Copy password",
        scanQRCode: "Scan the QR code to connect automatically",
      },
      emergency: {
        title: "Emergency Contacts",
        official: "Official Emergency Services",
        property: "Property Contacts",
        police: "Police",
        firefighters: "Fire Department",
        ambulance: "Ambulance",
        security: "Private Security",
        owner: "Property Owner",
        call: "Call",
        whatsapp: "Send WhatsApp message",
      },
      amenities: {
        title: "Amenities",
        subtitle: "Get to know all the amenities available for your stay",
        comfort: "Comfort",
        comfortDesc: "Amenities for your well-being",
        essentials: "Essentials",
        essentialsDesc: "Basic items for your stay",
        facilities: "Facilities",
        facilitiesDesc: "Areas and services of the property",
        entertainment: "Entertainment",
        entertainmentDesc: "Leisure and entertainment options",
        services: "Services",
        servicesDesc: "Services available to you",
        pool: {
          name: "Pool",
          desc: "Heated pool with 25m lane",
          details: "Hours: 7 am to 10 pm | Temperature: 82°F",
        },
        kitchen: {
          name: "Equipped Kitchen",
          desc: "Fully equipped kitchen with all utensils",
          details: "Stove | Refrigerator | Microwave | Coffee maker",
        },
        wifi: {
          name: "High-Speed Wi-Fi",
          desc: "Fiber internet throughout the apartment",
          details: "300Mbps | 5G network available",
        },
        ac: {
          name: "Air Conditioning",
          desc: "Air conditioning in all rooms",
          details: "Individual control | Night mode | 72°F",
        },
        parking: {
          name: "Parking",
          desc: "Covered and secure parking",
          details: "24-hour access | Monitored | Private parking",
        },
        laundry: {
          name: "Laundry",
          desc: "Washer and dryer",
          details: "Included products | Ironing board | 24-hour access",
        },
        tv: {
          name: "Smart TV",
          desc: '55" TV with streaming',
          details: "Netflix | Prime Video | Disney+",
        },
        coffee: {
          name: "Coffee Maker",
          desc: "Nespresso with capsules",
          details: "Courtesy capsules | Various teas",
        },
        cleaning: {
          name: "Cleaning Service",
          desc: "Professional cleaning",
          details: "Between stays | Included products | Linen change",
        },
        pets: {
          name: "Pet Friendly",
          desc: "We accept pets",
          details: "Max. 2 pets | Up to 22 lbs | No fee",
        },
        bathroom: {
          name: "Full Bathroom",
          desc: "Bathroom with amenities",
          details: "Towels | Hair dryer | Toiletries",
        },
        balcony: {
          name: "Balcony",
          desc: "Balcony with a view",
          details: "Furnished | City view | Net",
        },
        iron: {
          name: "Ironing Kit",
          desc: "Kit for ironing clothes",
          details: "Steam iron | Ironing board | Hangers",
        },
        dining: {
          name: "Dining Room",
          desc: "Complete dining table",
          details: "6-seat table | Dishes | Silverware",
        },
        gym: {
          name: "Gym",
          desc: "Complete gym",
          details: "Cardio | Weightlifting | 24-hour access",
        },
        games: {
          name: "Game Room",
          desc: "Entertainment area",
          details: "Pool table | Ping Pong | Videogame",
        },
        beach: {
          name: "Beach Kit",
          desc: "Beach equipment",
          details: "Beach umbrella | Chairs | Cooler",
        },
        bar: {
          name: "Bar",
          desc: "Equipped bar",
          details: "Cocktail shaker | Glasses | Ice",
        },
        workspace: {
          name: "Workspace",
          desc: "Area for home office",
          details: "Desk | Ergonomic chair | Natural light",
        },
        living: {
          name: "Living Room",
          desc: "Spacious and comfortable living room",
          details: "Sofa bed | Smart TV | Surround sound",
        },
        bedroom: {
          name: "Bedroom",
          desc: "Bedroom with king-size bed",
          details: "Premium mattress | Blackout | Air conditioning",
        },
        elevator: {
          name: "Elevator",
          desc: "Easy access",
          details: "Social and Service | 24-hour access",
        },
        security: {
          name: "Security",
          desc: "Security system",
          details: "Cameras | 24-hour doorman | Safe",
        },
      },
      location: {
        title: "Location",
        address: "Address",
        howToArrive: "How to Get Here",
        directions:
          "Use the button below to open Google Maps and get directions to the location.",
        getDirections: "Get Directions on Google Maps",
      },
      propertyManagement: {
        title: "Manage Property",
        propertyTitle: "Property Title",
        description: "Description",
        price: "Price per night",
        location: {
          title: "Location",
          address: "Address",
          street: "Street",
          number: "Number",
          complement: "Complement",
          neighborhood: "Neighborhood",
          city: "City",
          state: "State",
          country: "Country",
          postalCode: "Postal Code",
          coordinates: "Coordinates",
          instructions: "Access Instructions",
          reference: "Reference",
        },
        wifi: {
          title: "Wi-Fi",
          networkName: "Network Name",
          password: "Password",
          qrCode: "Generate QR Code",
        },
        electronicKey: {
          title: "Electronic Key",
          code: "Code",
          instructions: "Usage Instructions",
          addInstruction: "Add Instruction",
          notes: "Notes",
          addNote: "Add Note",
          dragToReorder: "Drag to reorder",
        },
        contacts: {
          title: "Contacts",
          addContact: "Add Contact",
          editContact: "Edit Contact",
          name: "Name",
          phone: "Phone",
          type: "Type",
          notes: "Notes",
          deleteConfirmationTitle: "Delete Contact",
          deleteConfirmationContent:
            "Are you sure you want to delete this contact?",
          types: {
            emergency: "Emergency",
            owner: "Owner",
            ambulance: "Ambulance",
            maintenance: "Maintenance",
            cleaning: "Cleaning",
            host: "Host",
            other: "Other",
          },
        },
        houseRules: {
          title: "House Rules",
          description: "Set the house rules for your property",
          status: {
            allowed: "Allowed",
            notAllowed: "Not Allowed",
          },
          additionalRulesTitle: "Additional Rules",
          additionalRulesPlaceholder: "Type a new rule",
          editMode: "Edit Mode",
          exitEditMode: "Exit Edit Mode",
          dragToReorder: "Drag to reorder rules",
          editRule: "Edit rule",
          saveRule: "Save rule",
          cancelEdit: "Cancel editing",
          removeRule: "Remove rule",
          allowPets: "Pets",
          allowSmoking: "Smoking",
          allowEvents: "Events & Parties",
          allowChildren: "Children",
          allowLateCheckout: "Late Checkout",
          allowEarlyCheckin: "Early Check-in",
          allowMusic: "Loud Music",
          allowVisitors: "Visitors",
          petsDescription: "Accepts pets on the property",
          smokingDescription: "Allows smoking inside or in outdoor areas",
          eventsDescription: "Allows events and parties",
          childrenDescription: "Suitable for children (2-12 years)",
          lateCheckoutDescription: "Allows checkout after standard time",
          earlyCheckinDescription: "Allows check-in before standard time",
          musicDescription: "Allows loud music or disturbing sounds",
          visitorsDescription: "Allows unregistered visitors on the property",
        },
      },
      searchPlaces: {
        title: "Search Places",
        searchPlaceholder: "Type place name...",
        add: "Add",
        emptyQuery: "Enter something to search",
        noResults: "No results found",
        error: "Error searching places. Please try again.",
        placeAdded: "Place added successfully!",
        viewOnMap: "View on Map",
        selectCategory: "Select category",
      },
      landing: {
        title: "Welcome to Digital Guide",
        subtitle: "Your complete guide for a unique experience",
        welcomeBack: "Choose how you want to access the system",
        hostButton: "Host Area",
        hostSubtext: "Manage your guides and properties",
        guestButton: "Guest Area",
        guestSubtext: "Access your stay guide",
        newHostText: "Don't have a guide yet?",
        createGuideLink: "Create one now!",
        welcomeMessages: [
          "Your unique experience starts here",
          "Manage your properties with ease",
          "Everything you need in one place",
          "Create amazing guides for your guests",
        ],
      },
      resetPassword: {
        title: "Reset Password",
        subtitle: "Don't worry, we'll help you",
        instructions:
          "Enter your email below and we'll send detailed instructions to safely reset your password.",
        welcomeMessages: [
          "Simple and secure process",
          "Recover access to your account",
          "Instructions straight to your email",
          "Support available if needed",
        ],
        emailLabel: "Email",
        emailRequired: "Please enter your email",
        resetButton: "Send Recovery Email",
        resetEmailSent: "Recovery email sent successfully! Check your inbox.",
        error: "Error sending recovery email. Please try again.",
        backToLogin: "Back to Login",
      },
      createGuide: {
        title: "Create Digital Guide",
        subtitle: "Transform your Airbnb listing into an interactive guide",
        instructions:
          "Enter your Airbnb listing link, your email and a password to create your account and generate your first guide.",
        welcomeMessages: [
          "Create your guide in minutes",
          "Customize all information",
          "Share easily with your guests",
          "Update whenever you want",
        ],
        urlLabel: "Airbnb URL",
        urlPlaceholder: "https://airbnb.com/rooms/...",
        emailLabel: "Your Email",
        passwordLabel: "Choose a Password",
        submitButton: "Create Guide",
        backButton: "Go Back",
        allFieldsRequired: "Please fill in all fields",
        invalidUrl: "Invalid Airbnb URL. Please enter a valid listing URL.",
        emailInUse:
          "This email is already in use. Try logging in or resetting your password.",
        networkError: "Connection error. Check your internet and try again.",
        error: "Error creating guide. Please try again.",
        success:
          "Guide being generated! You'll receive an email when it's ready. Redirecting to dashboard...",
      },
      contacts: {
        title: "Emergency Contacts",
        addContact: "Add Contact",
        edit: "Edit Contact",
        add: "Add",
        noContacts: "No contacts added",
        name: "Name",
        number: "Number",
        role: "Role",
        notes: "Notes",
        notesPlaceholder: "Add important information about this contact...",
        hasWhatsapp: "Has WhatsApp",
        call: "Call",
        whatsapp: "Send WhatsApp message",
        types: {
          police: "Police",
          fire: "Fire Department",
          hospital: "Hospital",
          building: "Building",
          maintenance: "Maintenance",
          owner: "Property Owner",
          ambulance: "Ambulance",
          other: "Other",
        },
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "pt",
  fallbackLng: "pt",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
