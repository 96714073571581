import React, { useRef } from 'react';
import { Paper, Box, TextField, IconButton } from '@mui/material';
import { DragHandle as DragHandleIcon, Delete as DeleteIcon } from '@mui/icons-material';
import CustomTooltip from '../common/CustomTooltip';
import { useDrop, useDrag } from 'react-dnd';
import { useTheme, alpha } from '@mui/material/styles';

const ItemTypes = {
  INSTRUCTION: 'instruction'
};

const InstructionItem = ({ id, index, instruction, moveInstruction, handleInstructionChange, handleRemoveInstruction, isEditingKey }) => {
  const ref = useRef(null);
  const theme = useTheme();

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.INSTRUCTION,
    item: { type: ItemTypes.INSTRUCTION, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => isEditingKey,
  });

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.INSTRUCTION,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current || !isEditingKey) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveInstruction(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));

  return (
    <Paper
      elevation={0}
      ref={ref}
      data-handler-id={handlerId}
      sx={{
        mb: 1.5,
        opacity,
        transition: 'all 0.2s ease',
        border: '1px solid',
        borderColor: theme => alpha(theme.palette.divider, 0.1),
        bgcolor: theme => alpha(theme.palette.background.paper, 0.8),
        '&:hover': {
          boxShadow: isEditingKey ? `0 2px 8px ${alpha('#000', 0.08)}` : 'none',
          transform: isEditingKey ? 'translateY(-2px)' : 'none',
          borderColor: theme => isEditingKey ? alpha(theme.palette.primary.main, 0.2) : alpha(theme.palette.divider, 0.1)
        }
      }}
    >
      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center',
          p: 2,
          gap: 1.5
        }}
      >
        <Box 
          sx={{ 
            cursor: isEditingKey ? 'move' : 'default',
            display: 'flex',
            alignItems: 'center',
            color: theme => isEditingKey ? alpha(theme.palette.text.primary, 0.4) : alpha(theme.palette.text.primary, 0.2),
            '&:hover': {
              color: theme => isEditingKey ? alpha(theme.palette.text.primary, 0.6) : alpha(theme.palette.text.primary, 0.2)
            }
          }}
        >
          <DragHandleIcon 
            sx={{ 
              fontSize: 20,
              cursor: isEditingKey ? 'grab' : 'default',
              '&:active': { 
                cursor: isEditingKey ? 'grabbing' : 'default' 
              }
            }} 
          />
        </Box>
        <TextField
          fullWidth
          value={instruction}
          onChange={(e) => handleInstructionChange(index, e.target.value)}
          variant="outlined"
          size="small"
          multiline
          maxRows={4}
          disabled={!isEditingKey}
          sx={{
            flex: 1,
            '& .MuiOutlinedInput-root': {
              bgcolor: 'background.paper',
              '& fieldset': {
                borderColor: theme => !isEditingKey ? 'transparent' : alpha(theme.palette.divider, 0.2)
              },
              '&:hover fieldset': {
                borderColor: theme => !isEditingKey ? 'transparent' : alpha(theme.palette.primary.main, 0.3)
              },
              '&.Mui-focused fieldset': {
                borderColor: 'primary.main'
              }
            },
            '& .MuiOutlinedInput-input': {
              py: 1,
              px: 1.5
            }
          }}
        />
        {isEditingKey && (
          <CustomTooltip title="Remover instrução">
            <IconButton 
              onClick={() => handleRemoveInstruction(index)}
              size="small"
              sx={{ 
                width: 32,
                height: 32,
                bgcolor: alpha(theme.palette.error.main, 0.1),
                color: 'error.main',
                '&:hover': {
                  bgcolor: alpha(theme.palette.error.main, 0.2)
                }
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </CustomTooltip>
        )}
      </Box>
    </Paper>
  );
};

export default InstructionItem;
