import React from 'react';
import {
  Box,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Typography,
  Divider,
  useMediaQuery,
  alpha
} from '@mui/material';
import {
  Add as AddIcon,
  ContactEmergency as ContactEmergencyIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import ConfigSection from './ConfigSection';
import ContactCard from './ContactCard';
import CustomTooltip from '../CustomTooltip';

const EmergencyContactsSection = ({
  contacts,
  newContact,
  editingContactIndex,
  addContactDialogOpen,
  contactTypes,
  onContactChange,
  onAddContact,
  onEditContact,
  onDeleteContact,
  onCloseDialog,
  onOpenDialog
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ConfigSection 
      title={t('contacts.title')}
      icon={<ContactEmergencyIcon />}
      action={
        <CustomTooltip title={t('contacts.addContact')}>
          <IconButton
            onClick={onOpenDialog}
            size="small"
            sx={{ 
              width: 32,
              height: 32,
              bgcolor: alpha(theme.palette.primary.main, 0.1),
              color: 'primary.main',
              '&:hover': {
                bgcolor: alpha(theme.palette.primary.main, 0.2)
              }
            }}
          >
            <AddIcon fontSize="small" />
          </IconButton>
        </CustomTooltip>
      }
    >
      <Box sx={{ width: '100%' }}>
        {contacts.length === 0 ? (
          <Box sx={{ 
            textAlign: 'center', 
            py: 4,
            bgcolor: alpha(theme.palette.primary.main, 0.04),
            borderRadius: 2
          }}>
            <Typography color="text.secondary">
              {t('contacts.noContacts')}
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={2}>
            {contacts.map((contact, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <ContactCard
                  contact={contact}
                  onEdit={() => onEditContact(contact, index)}
                  onDelete={() => onDeleteContact(index)}
                  contactTypes={contactTypes}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      <Dialog 
        open={addContactDialogOpen} 
        onClose={onCloseDialog}
        maxWidth="sm"
        fullWidth
        fullScreen={fullScreen}
        PaperProps={{
          sx: {
            borderRadius: fullScreen ? 0 : 2,
            bgcolor: 'background.paper'
          }
        }}
      >
        <DialogTitle 
          sx={{ 
            pb: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 1
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <ContactEmergencyIcon 
              sx={{ 
                color: 'primary.main',
                opacity: 0.8
              }} 
            />
            <Typography variant="h6" component="span">
              {editingContactIndex !== null
                ? t('contacts.edit')
                : t('contacts.add')}
            </Typography>
          </Box>
          <IconButton
            onClick={onCloseDialog}
            size="small"
            sx={{
              color: 'text.secondary',
              '&:hover': {
                color: 'text.primary',
                bgcolor: alpha(theme.palette.text.primary, 0.1)
              }
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        
        <Divider />
        
        <DialogContent sx={{ pt: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('contacts.name')}
                  value={newContact.name}
                  onChange={(e) => onContactChange('name', e.target.value)}
                  variant="outlined"
                  autoFocus
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('contacts.number')}
                  value={newContact.number}
                  onChange={(e) => onContactChange('number', e.target.value)}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>{t('contacts.role')}</InputLabel>
                  <Select
                    value={newContact.type}
                    onChange={(e) => onContactChange('type', e.target.value)}
                    label={t('contacts.role')}
                  >
                    {Object.entries(contactTypes).map(([key, { label }]) => (
                      <MenuItem key={key} value={key}>
                        {t(`contacts.types.${key}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={newContact.hasWhats}
                      onChange={(e) => onContactChange('hasWhats', e.target.checked)}
                      color="primary"
                    />
                  }
                  label={t('contacts.hasWhatsapp')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label={t('contacts.notes')}
                  value={newContact.notes}
                  onChange={(e) => onContactChange('notes', e.target.value)}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder={t('contacts.notesPlaceholder')}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>

        <Divider />
        
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button 
            onClick={onCloseDialog}
            sx={{ 
              color: 'text.secondary',
              '&:hover': {
                bgcolor: alpha(theme.palette.text.secondary, 0.1)
              }
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button 
            onClick={onAddContact}
            variant="contained"
            disabled={!newContact.name || !newContact.number}
            sx={{
              px: 3,
              '&:not(:disabled)': {
                boxShadow: theme.shadows[2],
                '&:hover': {
                  boxShadow: theme.shadows[4]
                }
              }
            }}
          >
            {editingContactIndex !== null ? t('common.save') : t('common.add')}
          </Button>
        </DialogActions>
      </Dialog>
    </ConfigSection>
  );
};

export default EmergencyContactsSection;
