import React, { useRef } from 'react';
import {
  Box,
  IconButton,
  TextField,
  Grid,
  CircularProgress,
  InputAdornment
} from '@mui/material';
import {
  Edit as EditIcon,
  Close as CloseIcon,
  Save as SaveIcon,
  QrCode2 as QrCode2Icon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Wifi as WifiIcon
} from '@mui/icons-material';
import { useTheme, alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import ConfigSection from './ConfigSection';
import CustomTooltip from '../common/CustomTooltip';

const WifiSection = ({
  wifiForm,
  isEditingWifi,
  isSavingWifi,
  showPassword,
  onWifiChange,
  onStartEditing,
  onCancelEditing,
  onSave,
  onTogglePassword
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const wifiPasswordRef = useRef(null);
  const wifiNetworkRef = useRef(null);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <ConfigSection 
      title={t('propertyManagement.wifi.title')}
      icon={<WifiIcon />}
      action={
        <Box>
          {!isEditingWifi ? (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <CustomTooltip title={t('propertyManagement.wifi.qrCode')}>
                <IconButton
                  onClick={() => {/* Implementar geração de QR code */}}
                  size="small"
                  sx={{ 
                    width: 32,
                    height: 32,
                    bgcolor: alpha(theme.palette.text.primary, 0.1),
                    color: 'text.primary',
                    '&:hover': {
                      bgcolor: alpha(theme.palette.text.primary, 0.2)
                    }
                  }}
                >
                  <QrCode2Icon fontSize="small" />
                </IconButton>
              </CustomTooltip>
              <CustomTooltip title={t('common.edit')}>
                <IconButton
                  onClick={onStartEditing}
                  size="small"
                  sx={{ 
                    width: 32,
                    height: 32,
                    bgcolor: alpha(theme.palette.primary.main, 0.1),
                    color: 'primary.main',
                    '&:hover': {
                      bgcolor: alpha(theme.palette.primary.main, 0.2)
                    }
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </CustomTooltip>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <CustomTooltip title={t('common.cancel')}>
                <IconButton
                  onClick={onCancelEditing}
                  size="small"
                  disabled={isSavingWifi}
                  sx={{ 
                    width: 32,
                    height: 32,
                    bgcolor: alpha(theme.palette.text.secondary, 0.1),
                    color: 'text.secondary',
                    '&:hover': {
                      bgcolor: alpha(theme.palette.text.secondary, 0.2)
                    }
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </CustomTooltip>
              <CustomTooltip title={t('common.save')}>
                <IconButton
                  onClick={onSave}
                  size="small"
                  disabled={isSavingWifi}
                  sx={{ 
                    width: 32,
                    height: 32,
                    bgcolor: alpha(theme.palette.error.main, 0.1),
                    color: 'error.main',
                    '&:hover': {
                      bgcolor: alpha(theme.palette.error.main, 0.2)
                    }
                  }}
                >
                  {isSavingWifi ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <SaveIcon fontSize="small" />
                  )}
                </IconButton>
              </CustomTooltip>
            </Box>
          )}
        </Box>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            size="large"
            type="text"
            label={t('propertyManagement.wifi.networkName')}
            inputRef={wifiNetworkRef}
            value={wifiForm.networkName}
            onChange={(e) => onWifiChange(e, 'networkName')}
            disabled={!isEditingWifi}
            sx={{ mb: 2 }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            size="large"
            type={showPassword ? 'text' : 'password'}
            label={t('propertyManagement.wifi.password')}
            inputRef={wifiPasswordRef}
            value={wifiForm.networkPassword}
            onChange={(e) => onWifiChange(e, 'networkPassword')}
            disabled={!isEditingWifi}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={onTogglePassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </ConfigSection>
  );
};

export default WifiSection;
