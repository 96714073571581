import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import PageHeader from "../components/PageHeader";
import SearchPlacesDialog from "../components/SearchPlacesDialog";
import CustomSnackbar from "../components/common/CustomSnackbar";
import EditTipDialog from "../components/EditTipDialog";
import LocalTipCard from "../components/local-tips/LocalTipCard";
import useLocalTips from "../hooks/useLocalTips";

const LocalTips = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { localTips, isLoading, isSaving, error, addTip, removeTip, updateTip } = useLocalTips();
  const [searchDialogOpen, setSearchDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedTip, setSelectedTip] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    if (error) {
      setSnackbar({
        open: true,
        message: error,
        severity: "error",
      });
    }
  }, [error]);

  const handleAddPlace = async (place) => {
    const success = await addTip({
      title: place.name,
      address: place.address,
      rating: place.rating,
      ratingCount: place.ratingCount,
      category: place.category,
      phoneNumber: place.phoneNumber,
      website: place.website,
      notes: "",
      discount: {
        discountInfo: "",
        discountCode: ""
      }
    });
    
    setSearchDialogOpen(false);
    setSnackbar({
      open: true,
      message: success ? t("searchPlaces.placeAdded") : t("common.errorMessage"),
      severity: success ? "success" : "error",
    });
  };

  const handleRemoveItem = async (itemId) => {
    const success = await removeTip(itemId);
    
    setSnackbar({
      open: true,
      message: success ? t("localTips.itemRemoved") : t("common.errorMessage"),
      severity: success ? "success" : "error",
    });
  };

  const handleEditItem = async (item) => {
    setSelectedTip(item);
    setEditDialogOpen(true);
  };

  const handleSaveEdit = async (editedTip) => {
    const success = await updateTip(editedTip);
    
    setEditDialogOpen(false);
    setSelectedTip(null);
    setSnackbar({
      open: true,
      message: success ? t("localTips.itemUpdated") : t("common.errorMessage"),
      severity: success ? "success" : "error",
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ pb: 4 }}>
      <PageHeader
        title={t("localTips.title")}
        subtitle={t("localTips.subtitle")}
      />

      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 4,
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {localTips && localTips.length > 0 
              ? t("localTips.totalCount", { count: localTips.length })
              : t("localTips.noTips")}
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setSearchDialogOpen(true)}
            disabled={isSaving}
            sx={{
              bgcolor: theme.palette.primary.main,
              color: "white",
              px: 3,
              py: 1,
              borderRadius: "50px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                bgcolor: theme.palette.primary.dark,
                boxShadow: "0 6px 16px rgba(0, 0, 0, 0.2)",
                transform: "translateY(-1px)",
              },
              transition: "all 0.2s ease-in-out",
            }}
          >
            {t("searchPlaces.title")}
          </Button>
        </Box>

        <Grid container spacing={3}>
          {localTips && localTips.map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item.id}>
              <LocalTipCard
                tip={item}
                onEdit={handleEditItem}
                onDelete={handleRemoveItem}
                disabled={isSaving}
              />
            </Grid>
          ))}
        </Grid>
      </Container>

      <SearchPlacesDialog
        open={searchDialogOpen}
        onClose={() => setSearchDialogOpen(false)}
        onSelectPlace={handleAddPlace}
      />

      <EditTipDialog
        open={editDialogOpen}
        onClose={() => {
          setEditDialogOpen(false);
          setSelectedTip(null);
        }}
        onSave={handleSaveEdit}
        tip={selectedTip}
      />

      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleCloseSnackbar}
      />
    </Box>
  );
};

export default LocalTips;
