import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Alert,
  Paper,
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useTranslation } from 'react-i18next';

export default function GuestAccess() {
  const { t } = useTranslation();
  const { propertyId } = useParams();
  const { hasGuestAccess } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Try to retrieve email from session storage first
    const storedEmail = sessionStorage.getItem('guestEmail');
    const storedPropertyId = sessionStorage.getItem('propertyId');

    if (storedEmail && storedPropertyId) {
      setEmail(storedEmail);
      
      // If current page's propertyId doesn't match stored propertyId, redirect
      if (storedPropertyId !== propertyId) {
        navigate(`/guest-access/${storedPropertyId}`);
        return;
      }
    }
  }, [propertyId, navigate]);

  async function handleSubmit(e) {
    e.preventDefault();

    if (!email) {
      setError(t('guestAccess.emailRequired'));
      return;
    }

    try {
      setError('');
      setLoading(true);
      
      // Normalize email
      const normalizedEmail = email.toLowerCase().trim();
      
      // Check guest access for this specific property
      const hasAccess = await hasGuestAccess(normalizedEmail, propertyId);
      
      if (hasAccess) {
        // Store guest email in session storage
        sessionStorage.setItem('guestEmail', normalizedEmail);
        sessionStorage.setItem('propertyId', propertyId);
        
        // Navigate to property info page
        navigate(`/property-info/${propertyId}`);
      } else {
        setError(t('guestAccess.noAccess'));
      }
    } catch (error) {
      console.error('Guest access check error:', error);
      setError(t('guestAccess.error'));
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 8 }}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            {t('guestAccess.title')}
          </Typography>
          
          <Typography variant="body1" sx={{ mb: 3 }} align="center">
            {t('guestAccess.description')}
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label={t('guestAccess.email')}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              required
              autoComplete="email"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading}
              sx={{ mt: 3 }}
            >
              {t('guestAccess.submit')}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}
