import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  Typography,
  Alert,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { useTheme } from '@mui/material/styles';
import AuthCard from '../components/common/AuthCard';
import TypewriterText from '../components/common/TypewriterText';

export default function ResetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const welcomeMessages = t('resetPassword.welcomeMessages', { returnObjects: true });

  const styles = {
    textField: {
      marginBottom: theme.spacing(2.5),
      '& .MuiOutlinedInput-root': {
        borderRadius: 4,
        backgroundColor: theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.05)'
          : 'rgba(0, 0, 0, 0.02)',
        transition: theme.transitions.create([
          'background-color', 
          'box-shadow', 
          'border-color'
        ], {
          duration: 200,
        }),
        '&:hover': {
          backgroundColor: theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, 0.1)'
            : 'rgba(0, 0, 0, 0.04)',
        },
        '&.Mui-focused': {
          backgroundColor: theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, 0.15)'
            : 'rgba(0, 0, 0, 0.06)',
          boxShadow: `0 0 0 1px ${theme.palette.primary.main}25`,
        }
      }
    },
    submitButton: {
      padding: theme.spacing(1.5),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
      borderRadius: 4,
      textTransform: 'none',
      fontSize: '1rem',
      fontWeight: 600,
      background: 'linear-gradient(45deg, #ff4b6e, #ff758c)',
      boxShadow: '0 2px 8px rgba(255, 75, 110, 0.25)',
      transition: 'all 0.2s ease-in-out',
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: '0 4px 12px rgba(255, 75, 110, 0.35)',
      },
      '&:active': {
        transform: 'translateY(1px)',
      }
    },
    backButton: {
      padding: theme.spacing(1.5),
      borderRadius: 4,
      textTransform: 'none',
      fontSize: '0.95rem',
      fontWeight: 500,
      color: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.05)'
        : 'rgba(0, 0, 0, 0.02)',
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(0, 0, 0, 0.04)',
      }
    },
    subtitle: {
      marginBottom: theme.spacing(4),
      color: theme.palette.text.secondary,
      textAlign: 'center',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.6,
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    
    if (!email) {
      setError(t('resetPassword.emailRequired'));
      return;
    }

    try {
      setError('');
      setSuccess('');
      setLoading(true);

      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      
      setSuccess(t('resetPassword.resetEmailSent'));
      setEmail('');
    } catch (error) {
      console.error('Password reset error:', error);
      setError(t('resetPassword.error'));
    } finally {
      setLoading(false);
    }
  }

  return (
    <AuthCard
      welcomeTitle={t('resetPassword.title')}
      welcomeMessage={t('resetPassword.subtitle')}
    >
      <Box sx={{ mb: 4, textAlign: 'center' }}>
        <TypewriterText messages={welcomeMessages} />
      </Box>

      <Typography variant="body1" sx={styles.subtitle}>
        {t('resetPassword.instructions')}
      </Typography>

      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        {error && (
          <Alert 
            severity="error" 
            sx={{ 
              mb: 3,
              borderRadius: 2,
              '& .MuiAlert-icon': {
                color: 'error.main'
              }
            }}
          >
            {error}
          </Alert>
        )}
        {success && (
          <Alert 
            severity="success"
            sx={{ 
              mb: 3,
              borderRadius: 2,
              '& .MuiAlert-icon': {
                color: 'success.main'
              }
            }}
          >
            {success}
          </Alert>
        )}

        <TextField
          fullWidth
          label={t('resetPassword.emailLabel')}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={loading}
          sx={styles.textField}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={loading}
          sx={styles.submitButton}
        >
          {loading ? (
            <CircularProgress 
              size={20}
              sx={{ 
                color: 'white',
              }}
            />
          ) : (
            t('resetPassword.resetButton')
          )}
        </Button>

        <Button
          fullWidth
          variant="outlined"
          onClick={() => navigate('/host')}
          sx={styles.backButton}
        >
          {t('resetPassword.backToLogin')}
        </Button>
      </form>
    </AuthCard>
  );
}
