import { useState, useEffect } from 'react';
import { ref, get, child, getDatabase } from 'firebase/database';
import { useProperty } from '../contexts/PropertyContext';

export const usePropertyData = () => {
  const [propertyData, setPropertyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentPropertyId, currentLanguage } = useProperty();

  useEffect(() => {
    const fetchPropertyData = async () => {
      setLoading(true);
      setError(null);

      if (!currentPropertyId) {
        setError('Property ID is required');
        setLoading(false);
        return;
      }

      try {
        const dbRef = ref(getDatabase());
        const propertyPath = `properties/${currentPropertyId}`;
        
        const propertySnapshot = await get(child(dbRef, propertyPath));
        
        if (propertySnapshot.exists()) {
          const rawData = propertySnapshot.val();
          
          // Verificar se há dados para o idioma atual
          if (rawData.languages?.[currentLanguage]) {
            const propertyInfo = rawData.languages[currentLanguage].property_data;
            const propertyConfig = rawData.languages[currentLanguage].property_config;
            
            setPropertyData({
              id: rawData.id,
              status: rawData.status,
              location: rawData.location,
              accessKey: {
                networkName: propertyConfig?.wifi?.networkName,
                networkPassword: propertyConfig?.wifi?.password,
                eKey: propertyConfig?.electronicKey?.code
              },
              languages: rawData.languages
            });
          } else {
            setError(`Data not available for language: ${currentLanguage}`);
          }
        } else {
          setError('Property not found');
        }
      } catch (err) {
        console.error('Error fetching property data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPropertyData();
  }, [currentPropertyId, currentLanguage]);

  return { propertyData, loading, error };
};
