import React from 'react';
import {
  Box,
  CircularProgress,
  Alert,
  Container,
  Typography,
  Grid
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import usePropertyManagement from './PropertyManagement/usePropertyManagement';
import WifiSection from '../components/property/WifiSection';
import ElectronicKeySection from '../components/property/ElectronicKeySection';
import LocationSection from '../components/property/LocationSection';
import EmergencyContactsSection from '../components/property/EmergencyContactsSection';
import HouseRulesSection from '../components/property/HouseRulesSection';
import CustomSnackbar from '../components/common/CustomSnackbar';

const PropertyManagement = () => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    showPassword,
    setShowPassword,
    openSnackbar,
    setOpenSnackbar,
    snackbarMessage,
    snackbarSeverity,
    isEditingWifi,
    isSavingWifi,
    wifiForm,
    electronicKeyForm,
    setElectronicKeyForm,
    isEditingKey,
    setIsEditingKey,
    isSavingKey,
    newNote,
    setNewNote,
    contacts,
    newContact,
    addContactDialogOpen,
    setAddContactDialogOpen,
    editingContactIndex,
    locationForm,
    isEditingLocation,
    setIsEditingLocation,
    isSavingLocation,
    contactTypes,
    rulesForm,
    isEditingRules,
    isSavingRules,
    handleStartEditingWifi,
    handleCancelEditingWifi,
    handleWifiChange,
    handleSaveWifi,
    handleInstructionChange,
    handleAddInstruction,
    handleRemoveInstruction,
    handleSaveKey,
    handleCancelKey,
    handleAddNote,
    handleRemoveNote,
    handleAddContact,
    handleEditContact,
    handleDeleteContact,
    handleCloseContactDialog,
    handleContactChange,
    handleLocationChange,
    handleSaveLocation,
    handleCancelLocation,
    handleStartEditingRules,
    handleCancelEditingRules,
    handleRulesChange,
    handleSaveRules
  } = usePropertyManagement();

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      </Container>
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4" component="h1" sx={{ fontWeight: 500 }}>
            {t('propertyManagement.title')}
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {/* Coluna da Esquerda */}
          <Grid item xs={12} md={6}>
            <WifiSection
              wifiForm={wifiForm}
              isEditingWifi={isEditingWifi}
              isSavingWifi={isSavingWifi}
              showPassword={showPassword}
              onWifiChange={handleWifiChange}
              onStartEditing={handleStartEditingWifi}
              onCancelEditing={handleCancelEditingWifi}
              onSave={handleSaveWifi}
              onTogglePassword={() => setShowPassword(!showPassword)}
            />

            <ElectronicKeySection
              electronicKeyForm={electronicKeyForm}
              isEditingKey={isEditingKey}
              isSavingKey={isSavingKey}
              newNote={newNote}
              onKeyChange={(field, value) => setElectronicKeyForm(prev => ({ ...prev, [field]: value }))}
              onStartEditing={() => setIsEditingKey(true)}
              onCancelEditing={handleCancelKey}
              onSave={handleSaveKey}
              onAddInstruction={handleAddInstruction}
              onRemoveInstruction={handleRemoveInstruction}
              onInstructionChange={handleInstructionChange}
              onAddNote={handleAddNote}
              onRemoveNote={handleRemoveNote}
              onNoteChange={setNewNote}
            />
          </Grid>

          {/* Coluna da Direita */}
          <Grid item xs={12} md={6}>
            <LocationSection
              locationForm={locationForm}
              isEditingLocation={isEditingLocation}
              isSavingLocation={isSavingLocation}
              onLocationChange={handleLocationChange}
              onStartEditing={() => setIsEditingLocation(true)}
              onCancelEditing={handleCancelLocation}
              onSave={handleSaveLocation}
            />

            <HouseRulesSection
              rulesForm={rulesForm}
              isEditingRules={isEditingRules}
              isSavingRules={isSavingRules}
              onRulesChange={handleRulesChange}
              onStartEditing={handleStartEditingRules}
              onCancelEditing={handleCancelEditingRules}
              onSave={handleSaveRules}
            />

            <EmergencyContactsSection
              contacts={contacts}
              newContact={newContact}
              editingContactIndex={editingContactIndex}
              addContactDialogOpen={addContactDialogOpen}
              contactTypes={contactTypes}
              onContactChange={handleContactChange}
              onAddContact={handleAddContact}
              onEditContact={handleEditContact}
              onDeleteContact={handleDeleteContact}
              onCloseDialog={handleCloseContactDialog}
              onOpenDialog={() => setAddContactDialogOpen(true)}
            />
          </Grid>
        </Grid>

        <CustomSnackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
      </Container>
    </DndProvider>
  );
};

export default PropertyManagement;
