import { useState, useEffect } from 'react';
import { useProperty } from '../../contexts/PropertyContext';
import { usePropertyConfig } from '../../hooks/usePropertyConfig';
import { useTranslation } from 'react-i18next';
import {
  LocalPolice as LocalPoliceIcon,
  LocalFireDepartment as LocalFireDepartmentIcon,
  LocalHospital as LocalHospitalIcon,
  Person as PersonIcon,
  Handyman as HandymanIcon
} from '@mui/icons-material';

const usePropertyManagement = () => {
  const { t } = useTranslation();
  const { currentPropertyId } = useProperty();
  const [showPassword, setShowPassword] = useState(false);
  const {
    config: propertyConfig,
    loading: propertyLoading,
    error: propertyError,
    updateConfig: updatePropertyConfig
  } = usePropertyConfig(currentPropertyId);
  const [config, setConfig] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Estados para o WiFi
  const [isEditingWifi, setIsEditingWifi] = useState(false);
  const [isSavingWifi, setIsSavingWifi] = useState(false);
  const [wifiForm, setWifiForm] = useState({
    networkName: '',
    networkPassword: ''
  });

  // Estados para a Senha Eletrônica
  const [electronicKeyForm, setElectronicKeyForm] = useState({
    code: '',
    instructions: [],
    notes: []
  });
  const [isEditingKey, setIsEditingKey] = useState(false);
  const [isSavingKey, setIsSavingKey] = useState(false);
  const [newNote, setNewNote] = useState('');

  // Estados para os contatos
  const [contacts, setContacts] = useState([]);
  const [newContact, setNewContact] = useState({
    name: '',
    number: '',
    type: 'owner',
    hasWhats: false,
    notes: ''
  });
  const [addContactDialogOpen, setAddContactDialogOpen] = useState(false);
  const [editingContactIndex, setEditingContactIndex] = useState(null);

  // Estados para a Localização
  const defaultLocationForm = {
    address: {
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      country: '',
      postalCode: ''
    },
    reference: ''
  };

  const [locationForm, setLocationForm] = useState(defaultLocationForm);
  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const [isSavingLocation, setIsSavingLocation] = useState(false);

  // Estados para as Regras da Casa
  const [rulesForm, setRulesForm] = useState({
    allowPets: false,
    allowSmoking: false,
    allowEvents: false,
    allowChildren: true,
    additionalRules: ''
  });
  const [isEditingRules, setIsEditingRules] = useState(false);
  const [isSavingRules, setIsSavingRules] = useState(false);

  // Tipos de contatos
  const contactTypes = {
    police: {
      label: t('contacts.types.police'),
      icon: <LocalPoliceIcon />,
      color: 'primary'
    },
    fire: {
      label: t('contacts.types.fire'),
      icon: <LocalFireDepartmentIcon />,
      color: 'error'
    },
    ambulance: {
      label: t('contacts.types.ambulance'),
      icon: <LocalHospitalIcon />,
      color: 'error'
    },
    owner: {
      label: t('contacts.types.owner'),
      icon: <PersonIcon />,
      color: 'info'
    },
    maintenance: {
      label: t('contacts.types.maintenance'),
      icon: <HandymanIcon />,
      color: 'success'
    }
  };

  useEffect(() => {
    if (propertyConfig) {
      setConfig(propertyConfig);
    }
  }, [propertyConfig]);

  useEffect(() => {
    if (!config) return;

    // Carrega dados do WiFi e código de acesso
    if (config.accessKey) {
      setWifiForm({
        networkName: config.accessKey.networkName || '',
        networkPassword: config.accessKey.networkPassword || ''
      });

      setElectronicKeyForm(prev => ({
        ...prev,
        code: config.accessKey.eKey || ''
      }));
    }

    // Carrega instruções e notas
    const electronicKeyConfig = config.localizedConfig?.electronicKey;
    if (electronicKeyConfig) {
      setElectronicKeyForm(prev => ({
        ...prev,
        instructions: electronicKeyConfig.instructions || [],
        notes: electronicKeyConfig.notes || []
      }));
    }

    // Carrega regras da casa
    if (config.localizedConfig?.houseRules) {
      setRulesForm({
        allowPets: config.localizedConfig.houseRules.allowPets || false,
        allowSmoking: config.localizedConfig.houseRules.allowSmoking || false,
        allowEvents: config.localizedConfig.houseRules.allowEvents || false,
        allowChildren: config.localizedConfig.houseRules.allowChildren ?? true,
        additionalRules: config.localizedConfig.houseRules.additionalRules || ''
      });
    }

    // Carrega dados de localização
    if (config.location) {
      setLocationForm({
        address: {
          street: config.location.address?.street || '',
          number: config.location.address?.number || '',
          complement: config.location.address?.complement || '',
          neighborhood: config.location.address?.neighborhood || '',
          city: config.location.address?.city || '',
          state: config.location.address?.state || '',
          country: config.location.address?.country || '',
          postalCode: config.location.address?.postalCode || ''
        },
        reference: config.location.reference || ''
      });
    }
  }, [config]);

  useEffect(() => {
    if (config) {
      const emergencyContacts = config.localizedConfig?.emergency?.contact || [];
      setContacts(emergencyContacts);
    }
  }, [config]);

  const handleStartEditingWifi = () => {
    setIsEditingWifi(true);
  };

  const handleCancelEditingWifi = () => {
    setIsEditingWifi(false);
    // Restaura os valores originais
    if (config?.accessKey) {
      setWifiForm({
        networkName: config.accessKey.networkName || '',
        networkPassword: config.accessKey.networkPassword || ''
      });
    }
  };

  const handleWifiChange = (event, field) => {
    setWifiForm(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleSaveWifi = async () => {
    try {
      setIsSavingWifi(true);

      // Cria uma cópia da configuração atual
      const currentData = {
        ...config,
        accessKey: {
          ...config.accessKey,
          networkName: wifiForm.networkName,
          networkPassword: wifiForm.networkPassword
        }
      };

      await handleUpdateConfig(currentData, 'Configurações de Wi-Fi salvas com sucesso', 'Erro ao salvar as configurações de Wi-Fi');
      setIsEditingWifi(false); // Sai do modo de edição após salvar

    } catch (error) {
      console.error('Error saving WiFi settings:', error);
    } finally {
      setIsSavingWifi(false);
      setOpenSnackbar(true);
    }
  };

  const handleInstructionChange = (index, newValue) => {
    const updatedInstructions = [...electronicKeyForm.instructions];
    updatedInstructions[index] = newValue;
    setElectronicKeyForm(prev => ({
      ...prev,
      instructions: updatedInstructions
    }));
  };

  const handleAddInstruction = () => {
    setElectronicKeyForm(prev => ({
      ...prev,
      instructions: [...prev.instructions, '']
    }));
  };

  const handleRemoveInstruction = (index) => {
    const updatedInstructions = [...electronicKeyForm.instructions];
    updatedInstructions.splice(index, 1);
    setElectronicKeyForm(prev => ({
      ...prev,
      instructions: updatedInstructions
    }));
  };

  const handleSaveKey = async () => {
    try {
      setIsSavingKey(true);

      const updatedConfig = {
        ...config,
        accessKey: {
          ...config.accessKey,
          eKey: electronicKeyForm.code
        },
        localizedConfig: {
          ...config.localizedConfig,
          electronicKey: {
            instructions: electronicKeyForm.instructions,
            notes: electronicKeyForm.notes
          }
        }
      };

      await handleUpdateConfig(updatedConfig, t('common.savedSuccessfully'), t('common.errorSaving'));

      setIsEditingKey(false);
    } catch (error) {
      console.error('Error saving electronic key:', error);
    } finally {
      setIsSavingKey(false);
      setOpenSnackbar(true);
    }
  };

  const handleCancelKey = () => {
    setIsEditingKey(false);
    setNewNote('');
    // Restaura os valores originais
    if (config?.accessKey) {
      setElectronicKeyForm({
        code: config.accessKey.eKey || '',
        instructions: config.localizedConfig?.electronicKey?.instructions || [],
        notes: config.localizedConfig?.electronicKey?.notes || []
      });
    }
  };

  const handleAddNote = () => {
    if (newNote.trim()) {
      const updatedNotes = [...electronicKeyForm.notes, newNote.trim()];
      setElectronicKeyForm(prev => ({
        ...prev,
        notes: updatedNotes
      }));
      setNewNote('');
    }
  };

  const handleRemoveNote = (index) => {
    const updatedNotes = [...electronicKeyForm.notes];
    updatedNotes.splice(index, 1);
    setElectronicKeyForm(prev => ({
      ...prev,
      notes: updatedNotes
    }));
  };

  const handleAddContact = async () => {
    try {
      const updatedContacts = [...contacts];

      if (editingContactIndex !== null) {
        // Editando contato existente
        updatedContacts[editingContactIndex] = newContact;
      } else {
        // Adicionando novo contato
        updatedContacts.push(newContact);
      }

      const updatedConfig = {
        ...config,
        localizedConfig: {
          ...config.localizedConfig,
          emergency: {
            ...config.localizedConfig?.emergency,
            contact: updatedContacts
          }
        }
      };

      await handleUpdateConfig(updatedConfig, editingContactIndex !== null ? t('contacts.contactUpdated') : t('contacts.contactAdded'), t('common.errorSaving'));

      setContacts(updatedContacts);
      setAddContactDialogOpen(false);
      setNewContact({
        name: '',
        number: '',
        type: 'owner',
        hasWhats: false,
        notes: ''
      });
      setEditingContactIndex(null);
    } catch (error) {
      console.error('Error saving contact:', error);
    } finally {
      setOpenSnackbar(true);
    }
  };

  const handleEditContact = (contact, index) => {
    setNewContact({ ...contact });
    setEditingContactIndex(index);
    setAddContactDialogOpen(true);
  };

  const handleDeleteContact = async (index) => {
    try {
      const updatedContacts = contacts.filter((_, i) => i !== index);

      const updatedConfig = {
        ...config,
        localizedConfig: {
          ...config.localizedConfig,
          emergency: {
            ...config.localizedConfig?.emergency,
            contact: updatedContacts
          }
        }
      };

      await handleUpdateConfig(updatedConfig, t('contacts.contactDeleted'), t('common.errorDeleting'));

      setContacts(updatedContacts);
    } catch (error) {
      console.error('Error deleting contact:', error);
    } finally {
      setOpenSnackbar(true);
    }
  };

  const handleCloseContactDialog = () => {
    setAddContactDialogOpen(false);
    setNewContact({
      name: '',
      number: '',
      type: 'owner',
      hasWhats: false,
      notes: ''
    });
    setEditingContactIndex(null);
  };

  const handleContactChange = (field, value) => {
    setNewContact(prev => ({ ...prev, [field]: value }));
  };

  const handleLocationChange = (field, value) => {
    if (field.includes('.')) {
      const [parent, child] = field.split('.');
      setLocationForm(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setLocationForm(prev => ({
        ...prev,
        [field]: value
      }));
    }
  };

  const handleSaveLocation = async () => {
    try {
      setIsSavingLocation(true);

      const updatedConfig = {
        ...config,
        location: locationForm
      };

      await handleUpdateConfig(updatedConfig, t('common.savedSuccessfully'), t('common.errorSaving'));

      setIsEditingLocation(false);
    } catch (error) {
      console.error('Error saving location:', error);
    } finally {
      setIsSavingLocation(false);
      setOpenSnackbar(true);
    }
  };

  const handleCancelLocation = () => {
    setIsEditingLocation(false);
    // Restaura os valores originais
    if (config?.location) {
      setLocationForm({
        address: {
          street: config.location.address?.street || '',
          number: config.location.address?.number || '',
          complement: config.location.address?.complement || '',
          neighborhood: config.location.address?.neighborhood || '',
          city: config.location.address?.city || '',
          state: config.location.address?.state || '',
          country: config.location.address?.country || '',
          postalCode: config.location.address?.postalCode || ''
        },
        reference: config.location.reference || ''
      });
    } else {
      setLocationForm(defaultLocationForm);
    }
  };


  const handleUpdateConfig = async (updatedConfig, successMessage, errorMessage) => {
    try {
      await updatePropertyConfig(updatedConfig);
      setSnackbarMessage(successMessage);
      setSnackbarSeverity('success');
    } catch (error) {
      console.error(errorMessage, error);
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity('error');
    }
  };

  const handleStartEditingRules = () => {
    setIsEditingRules(true);
  };

  const handleCancelEditingRules = () => {
    setIsEditingRules(false);
    // Restaura os valores originais
    if (config?.localizedConfig?.houseRules) {
      setRulesForm({
        allowPets: config.localizedConfig.houseRules.allowPets || false,
        allowSmoking: config.localizedConfig.houseRules.allowSmoking || false,
        allowEvents: config.localizedConfig.houseRules.allowEvents || false,
        allowChildren: config.localizedConfig.houseRules.allowChildren ?? true,
        additionalRules: config.localizedConfig.houseRules.additionalRules || ''
      });
    }
  };

  const handleRulesChange = (field, value) => {
    setRulesForm(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSaveRules = async () => {
    try {
      setIsSavingRules(true);
      const newConfig = {
        ...config,
        localizedConfig: {
          ...config.localizedConfig,
          houseRules: rulesForm
        }
      };
      await updatePropertyConfig(newConfig);
      setConfig(newConfig);
      setIsEditingRules(false);
      setSnackbarMessage(t('propertyManagement.houseRules.saveSuccess'));
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error saving house rules:', error);
      setSnackbarMessage(t('propertyManagement.houseRules.saveError'));
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setIsSavingRules(false);
    }
  };

  return {
    config,
    loading: propertyLoading,
    error: propertyError,
    showPassword,
    setShowPassword,
    openSnackbar,
    setOpenSnackbar,
    snackbarMessage,
    snackbarSeverity,
    handleUpdateConfig,
    isEditingWifi,
    setIsEditingWifi,
    isSavingWifi,
    setIsSavingWifi,
    wifiForm,
    setWifiForm,
    electronicKeyForm,
    setElectronicKeyForm,
    isEditingKey,
    setIsEditingKey,
    isSavingKey,
    setIsSavingKey,
    newNote,
    setNewNote,
    contacts,
    setContacts,
    newContact,
    setNewContact,
    addContactDialogOpen,
    setAddContactDialogOpen,
    editingContactIndex,
    setEditingContactIndex,
    locationForm,
    setLocationForm,
    isEditingLocation,
    setIsEditingLocation,
    isSavingLocation,
    setIsSavingLocation,
    defaultLocationForm,
    contactTypes,
    rulesForm,
    isEditingRules,
    isSavingRules,
    handleStartEditingWifi,
    handleCancelEditingWifi,
    handleWifiChange,
    handleSaveWifi,
    handleInstructionChange,
    handleAddInstruction,
    handleRemoveInstruction,
    handleSaveKey,
    handleCancelKey,
    handleAddNote,
    handleRemoveNote,
    handleAddContact,
    handleEditContact,
    handleDeleteContact,
    handleCloseContactDialog,
    handleContactChange,
    handleLocationChange,
    handleSaveLocation,
    handleCancelLocation,
    handleStartEditingRules,
    handleCancelEditingRules,
    handleRulesChange,
    handleSaveRules
  };
};

export default usePropertyManagement;
