import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  useTheme,
  CircularProgress,
  Alert,
} from '@mui/material';
import {
  Person,
  Hotel,
  Bathroom,
  Star as StarIcon,
  LocationOn,
} from '@mui/icons-material';
import { usePropertyData } from '../hooks/usePropertyData';
import { useProperty } from '../contexts/PropertyContext';

const PropertyOverview = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { currentLanguage } = useProperty();
  const { propertyData, loading, error } = usePropertyData();

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        {error}
      </Alert>
    );
  }

  if (!propertyData) return null;

  const propertyInfo = propertyData.languages?.[currentLanguage]?.property_data;
  if (!propertyInfo) return null;

  // Get details from the formatted data
  const details = {
    guests: propertyInfo.guests || 2,
    bedrooms: propertyInfo.details?.find(d => d.includes('bedroom'))?.split(' ')[0] || 1,
    bathrooms: propertyInfo.details?.find(d => d.includes('bath'))?.split(' ')[0] || 1,
  };

  return (
    <Box sx={{ width: '100%', mb: 6 }}>
      {/* Property Image */}
      <Box 
        sx={{ 
          width: '100%', 
          height: { xs: '250px', sm: '350px', md: '450px' }, 
          mb: 4, 
          borderRadius: 3,
          overflow: 'hidden',
          position: 'relative',
          boxShadow: theme.shadows[3],
          '&:hover': {
            '& img': {
              transform: 'scale(1.05)',
            },
          },
        }}
      >
        <img
          src={propertyInfo.image}
          alt={propertyInfo.name}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transition: 'transform 0.3s ease-in-out',
          }}
        />
      </Box>

      {/* Property Details */}
      <Grid container spacing={4}>
        {/* Left Column - Main Info */}
        <Grid item xs={12}>
          <Typography 
            variant="h4" 
            component="h1" 
            gutterBottom
            sx={{ 
              fontWeight: 700,
              fontSize: { xs: '1.75rem', sm: '2rem', md: '2.25rem' },
              mb: 2,
            }}
          >
            {propertyInfo.name}
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <LocationOn sx={{ color: theme.palette.text.secondary, mr: 1 }} />
            <Typography variant="subtitle1" color="text.secondary">
              {propertyData.location?.address ? 
                `${propertyData.location.address.street}${propertyData.location.address.number ? `, ${propertyData.location.address.number}` : ''}, ${propertyData.location.address.city}, ${propertyData.location.address.state}` 
                : t('propertyInfo.overview.locationNotAvailable', 'Location not available')}
            </Typography>
          </Box>
          
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <StarIcon sx={{ color: '#FFB400', mr: 1 }} />
            <Typography variant="h6" sx={{ fontWeight: 600, mr: 1 }}>
              {propertyInfo.host_rating || '4.75'}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              ({propertyInfo.host_number_of_reviews || '0'} {t('propertyInfo.overview.reviews')})
            </Typography>
          </Box>

          {/* Property Features */}
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={4}>
              <Card elevation={0} sx={{ bgcolor: 'background.default' }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Person sx={{ mr: 1, color: 'text.secondary' }} />
                    <Typography variant="body1">
                      {details.guests} {t('propertyInfo.overview.guests')}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Card elevation={0} sx={{ bgcolor: 'background.default' }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Hotel sx={{ mr: 1, color: 'text.secondary' }} />
                    <Typography variant="body1">
                      {details.bedrooms} {t('propertyInfo.overview.bedrooms')}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Card elevation={0} sx={{ bgcolor: 'background.default' }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Bathroom sx={{ mr: 1, color: 'text.secondary' }} />
                    <Typography variant="body1">
                      {details.bathrooms} {t('propertyInfo.overview.bathrooms')}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Description */}
          <Typography 
            variant="body1" 
            color="text.secondary"
            sx={{ mt: 4, lineHeight: 1.7 }}
          >
            {propertyInfo.description}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PropertyOverview;
