import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@mui/system';

const kenBurnsEffect = keyframes`
  0% {
    transform: scale(1) translate(0, 0);
  }
  25% {
    transform: scale(1.05) translate(-5px, 5px);
  }
  50% {
    transform: scale(1.1) translate(5px, -5px);
  }
  75% {
    transform: scale(1.05) translate(5px, 5px);
  }
  100% {
    transform: scale(1) translate(0, 0);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(1.05);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const images = [
  '/images/backgrounds/travel2.jpg',
  '/images/backgrounds/travel3.jpg',
  '/images/backgrounds/travel1.jpg',
  '/images/backgrounds/travel4.jpg',
];

const BackgroundSlideshow = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [nextImageIndex, setNextImageIndex] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const transitionDuration = 1500; // 1.5 seconds for transition
    const slideDuration = 6000; // 6 seconds per slide

    const interval = setInterval(() => {
      setIsTransitioning(true);
      
      // Prepare next image index
      const nextIndex = (currentImageIndex + 1) % images.length;
      setNextImageIndex(nextIndex);
      
      // Wait for fade out, then update current image
      setTimeout(() => {
        setCurrentImageIndex(nextIndex);
        setIsTransitioning(false);
      }, transitionDuration);
      
    }, slideDuration);

    return () => clearInterval(interval);
  }, [currentImageIndex]);

  const commonStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'opacity 1.5s ease-in-out',
  };

  return (
    <>
      {/* Current Image */}
      <Box
        sx={{
          ...commonStyles,
          backgroundImage: `url(${images[currentImageIndex]})`,
          opacity: isTransitioning ? 0 : 1,
          animation: `${kenBurnsEffect} 15s infinite ease-in-out, ${fadeIn} 1.5s ease-out`,
          zIndex: 0,
        }}
      />

      {/* Next Image */}
      <Box
        sx={{
          ...commonStyles,
          backgroundImage: `url(${images[nextImageIndex]})`,
          opacity: isTransitioning ? 1 : 0,
          animation: `${kenBurnsEffect} 15s infinite ease-in-out`,
          zIndex: 0,
        }}
      />

      {/* Gradient Overlay with previous opacity */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(135deg, rgba(255, 75, 110, 0.75), rgba(255, 117, 140, 0.75))',
          zIndex: 1,
        }}
      />
    </>
  );
};

export default BackgroundSlideshow;
