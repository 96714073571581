import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Link as MuiLink,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Home as HomeIcon, Person as PersonIcon } from '@mui/icons-material';
import AuthCard from '../components/common/AuthCard';
import TypewriterText from '../components/common/TypewriterText';

export default function LandingPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const styles = {
    buttonContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(3),
      marginTop: theme.spacing(6),
    },
    button: {
      padding: theme.spacing(2.5),
      borderRadius: 4,
      textTransform: 'none',
      fontSize: '1rem',
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(3),
      justifyContent: 'flex-start',
      backgroundColor: theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.05)'
        : 'rgba(0, 0, 0, 0.02)',
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(0, 0, 0, 0.04)',
      },
      '@media (max-width: 600px)': {
        width: '100%',
        justifyContent: 'flex-start',
        padding: theme.spacing(2),
      }
    },
    hostButton: {
      color: '#fff',
      backgroundColor: '#FF385C',
      '&:hover': {
        backgroundColor: '#E31C5F',
      },
      '& .MuiTypography-root': {
        color: 'rgba(255, 255, 255, 0.95)',
      },
      '& .MuiTypography-subtitle1': {
        color: 'rgba(255, 255, 255, 0.85)',
      },
    },
    icon: {
      fontSize: '2.2rem',
    },
    buttonText: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: theme.spacing(0.5),
      '@media (max-width: 600px)': {
        alignItems: 'flex-start',
        textAlign: 'left',
      }
    },
    buttonTitle: {
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: 1.2,
      '@media (max-width: 600px)': {
        textAlign: 'left',
      }
    },
    buttonSubtext: {
      fontSize: '0.875rem',
      color: theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.7)'
        : 'rgba(0, 0, 0, 0.6)',
      fontWeight: 400,
      '@media (max-width: 600px)': {
        textAlign: 'left',
        width: '100%',
      }
    },
    welcomeText: {
      marginBottom: theme.spacing(6),
      textAlign: 'center',
      '& > *': {
        fontSize: '1.25rem',
        lineHeight: 1.6,
        color: theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.9)'
          : 'rgba(0, 0, 0, 0.8)',
      }
    },
    subtitle: {
      textAlign: 'center',
      marginBottom: theme.spacing(4),
      color: theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.7)'
        : 'rgba(0, 0, 0, 0.6)',
      fontSize: '1rem',
      fontWeight: 500,
    }
  };

  const welcomeMessages = t('landing.welcomeMessages', { returnObjects: true });

  return (
    <AuthCard
      welcomeTitle={t('landing.title')}
      welcomeMessage={t('landing.subtitle')}
    >
      <Box sx={styles.welcomeText}>
        <TypewriterText messages={welcomeMessages} />
      </Box>

      <Typography variant="body1" sx={styles.subtitle}>
        {t('landing.welcomeBack')}
      </Typography>

      <Box sx={styles.buttonContainer}>
        <Button
          variant="contained"
          onClick={() => navigate('/host')}
          sx={{ ...styles.button, ...styles.hostButton }}
        >
          <HomeIcon sx={styles.icon} />
          <Box sx={styles.buttonText}>
            <Typography sx={styles.buttonTitle}>
              {t('landing.hostButton')}
            </Typography>
            <Typography sx={styles.buttonSubtext}>
              {t('landing.hostSubtext')}
            </Typography>
          </Box>
        </Button>

        <Button
          variant="outlined"
          onClick={() => navigate('/guest')}
          sx={styles.button}
        >
          <PersonIcon sx={styles.icon} />
          <Box sx={styles.buttonText}>
            <Typography sx={styles.buttonTitle}>
              {t('landing.guestButton')}
            </Typography>
            <Typography sx={styles.buttonSubtext}>
              {t('landing.guestSubtext')}
            </Typography>
          </Box>
        </Button>
      </Box>

      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography 
          variant="body2" 
          color="text.secondary"
          sx={{
            '& .MuiLink-root': {
              color: 'primary.main',
              textDecoration: 'none',
              fontWeight: 500,
              transition: 'color 0.2s',
              '&:hover': {
                color: 'primary.dark',
                textDecoration: 'underline',
              },
            },
          }}
        >
          {t('landing.newHostText')}{' '}
          <MuiLink 
            component="button" 
            onClick={() => navigate('/create-guide')}
          >
            {t('landing.createGuideLink')}
          </MuiLink>
        </Typography>
      </Box>
    </AuthCard>
  );
}
