import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../contexts/AuthContext';
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  useTheme,
  useMediaQuery,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Divider,
  alpha,
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  Home as HomeIcon,
  Map as MapIcon,
  Settings as SettingsIcon,
  LightMode,
  DarkMode,
  Close as CloseIcon,
  ChevronLeft,
  ChevronRight,
  Pool as PoolIcon,
  Edit as EditIcon,
  ExitToApp as ExitToAppIcon,
  Login as LoginIcon,
} from '@mui/icons-material';
import LanguageSelector from './LanguageSelector';
import CustomTooltip from './common/CustomTooltip';

const Navigation = ({ darkMode, setDarkMode }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(true);
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { signOut, currentUser } = useAuth();

  const menuItems = [
    { text: t('navigation.home'), icon: <DashboardIcon />, path: '/dashboard' },
    { text: t('navigation.propertyInfo'), icon: <HomeIcon />, path: '/property-info' },
    { text: t('navigation.localTips'), icon: <MapIcon />, path: '/local-tips' },
    { text: t('navigation.amenities'), icon: <PoolIcon />, path: '/amenities' },
    { type: 'divider' },
    { text: t('navigation.edit'), icon: <EditIcon />, path: '/management', admin: true },
    { type: 'divider' },
    { text: t('navigation.settings'), icon: <SettingsIcon />, path: '/settings' },
    { 
      text: currentUser ? t('navigation.logout') : t('navigation.login'),
      icon: currentUser ? <ExitToAppIcon /> : <LoginIcon />,
      action: currentUser ? 'logout' : 'login',
      path: '/host'
    },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavigation = (path, action) => {
    if (action === 'logout') {
      signOut().then(() => navigate('/host'));
    } else if (action === 'login') {
      navigate('/host');
    } else {
      navigate(path);
    }
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const drawer = (
    <Box 
      sx={{ 
        width: isExpanded ? 250 : 72,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          p: 2,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        {!isMobile && (
          <IconButton 
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{
              color: 'text.secondary',
              '&:hover': {
                backgroundColor: `${theme.palette.primary.main}08`,
              },
            }}
          >
            {isExpanded ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        )}
        {isMobile && (
          <IconButton 
            onClick={handleDrawerToggle}
            sx={{
              color: 'text.secondary',
              '&:hover': {
                backgroundColor: `${theme.palette.primary.main}08`,
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      <List>
        {menuItems.map((item, index) => (
          item.type === 'divider' ? (
            <Divider key={`divider-${index}`} sx={{ my: 1 }} />
          ) : (
            <ListItem
              button
              key={item.text}
              onClick={() => handleNavigation(item.path, item.action)}
              selected={location.pathname === item.path}
              sx={{
                minHeight: 48,
                justifyContent: isExpanded ? 'initial' : 'center',
                px: 2.5,
                borderRadius: 2,
                mb: 1,
                position: 'relative',
                color: location.pathname === item.path ? 'primary.main' : 'text.secondary',
                ...(item.admin && {
                  '&:hover': {
                    backgroundColor: theme => `${theme.palette.primary.main}15`,
                  },
                  '&.Mui-selected': {
                    backgroundColor: theme => `${theme.palette.primary.main}20`,
                    '&:hover': {
                      backgroundColor: theme => `${theme.palette.primary.main}30`,
                    },
                  },
                }),
                '&:hover': {
                  bgcolor: theme => `${theme.palette.primary.main}08`,
                  color: 'primary.main',
                },
                '&::before': location.pathname === item.path ? {
                  content: '""',
                  position: 'absolute',
                  left: -8,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  width: 4,
                  height: '60%',
                  bgcolor: 'primary.main',
                  borderRadius: '0 4px 4px 0',
                } : {},
              }}
            >
              <CustomTooltip 
                title={!isExpanded ? item.text : ''}
                placement="right"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isExpanded ? 3 : 'auto',
                    justifyContent: 'center',
                    color: location.pathname === item.path ? 'primary.main' : 'inherit',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
              </CustomTooltip>
              {isExpanded && <ListItemText primary={item.text} />}
            </ListItem>
          )
        ))}
      </List>
      <Box sx={{ mt: 'auto', mb: 2, mx: 1 }}>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: isExpanded ? 'space-between' : 'center',
          px: isExpanded ? 1.5 : 0
        }}>
          <LanguageSelector isCollapsed={!isExpanded} />
          <CustomTooltip title={t(darkMode ? 'settings.light' : 'settings.dark')}>
            <IconButton
              onClick={() => setDarkMode(!darkMode)}
              sx={{
                width: 32,
                height: 32,
                color: 'text.secondary',
                '&:hover': {
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.08),
                },
              }}
            >
              {darkMode ? <LightMode /> : <DarkMode />}
            </IconButton>
          </CustomTooltip>
        </Box>
      </Box>
      <Box sx={{ 
        p: 2, 
        borderTop: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        justifyContent: 'center'
      }}>
      </Box>
    </Box>
  );

  return (
    <>
      <Box
        component="nav"
        sx={{
          width: { md: isExpanded ? 250 : 72 },
          flexShrink: { md: 0 },
        }}
      >
        {isMobile ? (
          <SwipeableDrawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onOpen={handleDrawerToggle}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              '& .MuiDrawer-paper': { width: 250 },
            }}
          >
            {drawer}
          </SwipeableDrawer>
        ) : (
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', md: 'block' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: isExpanded ? 250 : 72,
                transition: theme.transitions.create('width', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
                overflowX: 'hidden',
                borderRight: `1px solid ${theme.palette.divider}`,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        )}
      </Box>

      {isMobile && (
        <Paper
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: theme.zIndex.appBar,
          }}
          elevation={3}
        >
          <BottomNavigation
            value={location.pathname}
            onChange={(event, newValue) => {
              handleNavigation(newValue);
            }}
            showLabels
          >
            {menuItems.map((item) => (
              item.type !== 'divider' && (
                <BottomNavigationAction
                  key={item.path}
                  label={item.text}
                  icon={item.icon}
                  value={item.path}
                />
              )
            ))}
          </BottomNavigation>
        </Paper>
      )}
    </>
  );
};

export default Navigation;
