import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  useTheme,
  Divider,
  CircularProgress,
  Alert,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  useMediaQuery,
  ListItemText,
} from '@mui/material';
import { 
  ContentCopy as ContentCopyIcon, 
  Check as CheckIcon,
  KeyboardReturn as EnterIcon,
  Info as InfoIcon,
  LockOpen as LockOpenIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useProperty } from '../contexts/PropertyContext';
import { usePropertyAccess } from '../hooks/usePropertyAccess';
import CustomSnackbar from './common/CustomSnackbar';
import CustomTooltip from './common/CustomTooltip';
import DialogHeader from './common/DialogHeader';

const ElectronicKeyDialog = ({ open, onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [codeCopied, setCodeCopied] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  
  const { currentPropertyId } = useProperty();
  const { accessData, loading, error } = usePropertyAccess(currentPropertyId);

  const handleCopyCode = () => {
    if (accessData?.electronicKey?.code) {
      navigator.clipboard.writeText(accessData.electronicKey.code);
      setCodeCopied(true);
      setSnackbarOpen(true);
      setTimeout(() => setCodeCopied(false), 3000);
    }
  };

  const handleClose = () => {
    setCodeCopied(false);
    onClose();
  };

  const renderInstructions = (instructions) => {
    return instructions?.map((instruction, index) => (
      <ListItem key={index} sx={{ pl: 0 }}>
        <ListItemIcon sx={{ minWidth: 36 }}>
          <EnterIcon color="primary" fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={instruction} />
      </ListItem>
    ));
  };

  const renderNotes = (notes) => {
    return notes?.map((note, index) => (
      <ListItem key={index} sx={{ pl: 0 }}>
        <ListItemIcon sx={{ minWidth: 36 }}>
          <InfoIcon color="info" fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={note} />
      </ListItem>
    ));
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        maxWidth="sm"
        fullWidth
      >
        <DialogHeader
          title={t('electronicKey.title', 'Chave Eletrônica')}
          icon={LockOpenIcon}
          onClose={handleClose}
        />

        <DialogContent sx={{ p: 3 }}>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
              <CircularProgress />
            </Box>
          ) : error ? (
            <Alert severity="error">{error}</Alert>
          ) : accessData?.electronicKey ? (
            <>
              <Paper elevation={0} sx={{ 
                p: 3,
                mb: 3,
                borderRadius: 2
              }}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                  {t('electronicKey.code', 'Código de Acesso')}
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'space-between',
                  bgcolor: '#FFF1F1',
                  borderRadius: 2,
                  p: 2,
                  mt: 1
                }}>
                  <Typography 
                    variant="h4" 
                    component="div" 
                    sx={{ 
                      letterSpacing: 4,
                      color: '#FF385C',
                      fontWeight: 500,
                      fontSize: '1.75rem'
                    }}
                  >
                    {accessData.electronicKey.code}
                  </Typography>
                  <IconButton 
                    onClick={handleCopyCode} 
                    size="small"
                    sx={{
                      color: '#FF385C'
                    }}
                  >
                    {codeCopied ? <CheckIcon /> : <ContentCopyIcon />}
                  </IconButton>
                </Box>
              </Paper>

              <Paper elevation={0} sx={{ 
                p: 3,
                bgcolor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
                borderRadius: 2
              }}>
                {/* Instructions Section */}
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                  {t('electronicKey.instructions', 'Instruções')}
                </Typography>

                <List dense>
                  {renderInstructions(accessData.electronicKey.instructions)}
                </List>

                <Divider sx={{ my: 2 }} />

                {/* Notes Section */}
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                  {t('electronicKey.notes', 'Observações')}
                </Typography>

                <List dense>
                  {renderNotes(accessData.electronicKey.notes)}
                </List>
              </Paper>
            </>
          ) : null}
        </DialogContent>

        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleClose} variant="outlined">
            {t('common.close', 'Fechar')}
          </Button>
        </DialogActions>
      </Dialog>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t('common.copiedToClipboard', 'Copiado para a área de transferência!')}
        severity="success"
      />
    </>
  );
};

export default ElectronicKeyDialog;
