import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import DialogHeader from "./DialogHeader";
import DialogFooter from "./DialogFooter";

const BaseDialog = ({
  open,
  onClose,
  fullScreen,
  title,
  icon,
  children,
  actions,
  maxWidth = "sm",
  fullWidth = true,
  useCustomHeader = false,
  useCustomFooter = true,
  closeButtonText,
}) => {
  const theme = useTheme();
  const isFullScreen =
    useMediaQuery(theme.breakpoints.down("sm")) || fullScreen;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isFullScreen}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      PaperProps={{
        sx: {
          borderRadius: isFullScreen ? 0 : 2,
          background: theme.palette.background.paper,
        },
      }}
    >
      {useCustomHeader ? (
        <DialogHeader title={title} icon={icon} onClose={onClose} />
      ) : (
        title && <DialogTitle>{title}</DialogTitle>
      )}
      <DialogContent dividers>{children}</DialogContent>
      {useCustomFooter && (
        <DialogFooter
          onClose={onClose}
          closeButtonText={closeButtonText}
          customActions={actions}
        />
      )}
    </Dialog>
  );
};

export default BaseDialog;
