import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  IconButton,
  Typography,
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  DialogContentText,
  Button,
  TextField,
  alpha,
} from "@mui/material";
import { Check, Add, Translate, KeyboardArrowRight } from "@mui/icons-material";
import { BR, US, FR, ES } from "country-flag-icons/react/3x2";
import CustomTooltip from "./common/CustomTooltip";
import CustomSnackbar from "./common/CustomSnackbar";
import BaseDialog from "./common/BaseDialog";

const languages = [
  {
    code: "pt",
    name: "Portuguese",
    nativeName: "Português",
    region: "Brasil",
    flag: BR,
  },
  {
    code: "en",
    name: "English",
    nativeName: "English",
    region: "United States",
    flag: US,
  },
  {
    code: "fr",
    name: "French",
    nativeName: "Français",
    region: "France",
    flag: FR,
  },
  {
    code: "es",
    name: "Spanish",
    nativeName: "Español",
    region: "España",
    flag: ES,
  },
];

const LanguageSelector = ({ isCollapsed }) => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [requestDialogOpen, setRequestDialogOpen] = useState(false);
  const [requestLanguage, setRequestLanguage] = useState("");
  const [requestReason, setRequestReason] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageSelect = (languageCode) => {
    i18n.changeLanguage(languageCode);
    handleClose();
  };

  const handleRequestDialogOpen = () => {
    handleClose();
    setRequestDialogOpen(true);
  };

  const handleRequestDialogClose = () => {
    setRequestDialogOpen(false);
    setRequestLanguage("");
    setRequestReason("");
  };

  const handleRequestSubmit = () => {
    // Here you would typically send this to your backend
    console.log("Translation requested:", {
      language: requestLanguage,
      reason: requestReason,
    });
    handleRequestDialogClose();
    setSnackbarOpen(true);
  };

  const open = Boolean(anchorEl);
  const currentLanguage =
    languages.find((lang) => lang.code === i18n.language) || languages[0];
  const Flag = currentLanguage.flag;

  if (isCollapsed) {
    return (
      <>
        <CustomTooltip
          title={t("language.selectLanguage", "Selecionar idioma")}
        >
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{
              width: 32,
              height: 32,
              p: 0,
              "&:hover": {
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
              },
            }}
          >
            <Box
              sx={{
                width: 28,
                height: 28,
                borderRadius: "50%",
                overflow: "hidden",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                border: "2px solid",
                borderColor: "background.paper",
                "& > svg": {
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  transform: "scale(1.4)",
                },
              }}
            >
              <Flag />
            </Box>
          </IconButton>
        </CustomTooltip>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              width: "auto",
              ml: 1,
              overflow: "hidden",
              boxShadow: (theme) => theme.shadows[8],
              borderRadius: 2,
            },
          }}
        >
          <List sx={{ p: 0.5 }}>
            {languages.map((language) => {
              const LangFlag = language.flag;
              return (
                <ListItem key={language.code} disablePadding dense>
                  <CustomTooltip
                    title={`${language.nativeName} (${language.region})`}
                  >
                    <ListItemButton
                      selected={language.code === i18n.language}
                      onClick={() => handleLanguageSelect(language.code)}
                      sx={{
                        borderRadius: 1,
                        p: 0.75,
                        minWidth: 36,
                        position: "relative",
                        "&.Mui-selected": {
                          bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, 0.08),
                        },
                      }}
                    >
                      <Box
                        sx={{
                          width: 28,
                          height: 28,
                          borderRadius: "50%",
                          overflow: "hidden",
                          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                          border: "2px solid",
                          borderColor: "background.paper",
                          transition: "transform 0.2s",
                          "&:hover": {
                            transform: "scale(1.05)",
                          },
                          "& > svg": {
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            transform: "scale(1.4)",
                          },
                        }}
                      >
                        <LangFlag />
                      </Box>
                      {language.code === i18n.language && (
                        <Box
                          sx={{
                            position: "absolute",
                            right: -4,
                            top: -4,
                            width: 16,
                            height: 16,
                            borderRadius: "50%",
                            bgcolor: "background.paper",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
                          }}
                        >
                          <Check
                            sx={{
                              color: "primary.main",
                              fontSize: 12,
                            }}
                          />
                        </Box>
                      )}
                    </ListItemButton>
                  </CustomTooltip>
                </ListItem>
              );
            })}
            <Divider sx={{ my: 0.5 }} />
            <ListItem disablePadding dense>
              <CustomTooltip title={t("language.request")}>
                <ListItemButton
                  onClick={handleRequestDialogOpen}
                  sx={{
                    borderRadius: 1,
                    p: 0.75,
                    minWidth: 36,
                    color: "primary.main",
                  }}
                >
                  <Add sx={{ fontSize: 20 }} />
                </ListItemButton>
              </CustomTooltip>
            </ListItem>
          </List>
        </Popover>
      </>
    );
  }

  return (
    <>
      <CustomTooltip
        title={`${currentLanguage.name} (${currentLanguage.region})`}
        placement="right"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            px: 2,
            py: 1,
            mx: -1,
            borderRadius: 2,
            cursor: "pointer",
            "&:hover": {
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
            },
          }}
          onClick={handleClick}
        >
          <Box
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 40,
              height: 40,
              borderRadius: "50%",
              bgcolor: "background.paper",
              overflow: "hidden",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              border: "2px solid",
              borderColor: (theme) => alpha(theme.palette.primary.main, 0.1),
              transition: "transform 0.2s",
              "&:hover": {
                transform: "scale(1.05)",
              },
              "& > svg": {
                width: "100%",
                height: "100%",
                objectFit: "cover",
                transform: "scale(1.4)",
              },
            }}
          >
            <Flag />
          </Box>

          <Box sx={{ flex: 1, display: { xs: "none", sm: "block" } }}>
            <Typography variant="body2" color="text.primary" fontWeight={500}>
              {currentLanguage.nativeName}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {currentLanguage.region}
            </Typography>
          </Box>
          <KeyboardArrowRight sx={{ color: "text.secondary", opacity: 0.5 }} />
        </Box>
      </CustomTooltip>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            mt: 0,
            ml: 1,
            width: 280,
            overflow: "hidden",
          },
        }}
      >
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            sx={{ mb: 0.5 }}
          >
            {t("language.select")}
          </Typography>
        </Box>
        <List sx={{ pt: 0 }}>
          {languages.map((language) => {
            const LangFlag = language.flag;
            return (
              <ListItem
                key={language.code}
                disablePadding
                secondaryAction={
                  language.code === i18n.language && (
                    <Check sx={{ color: "primary.main", mr: 1 }} />
                  )
                }
              >
                <ListItemButton
                  selected={language.code === i18n.language}
                  onClick={() => handleLanguageSelect(language.code)}
                  sx={{
                    py: 1.5,
                    borderRadius: 1,
                    mx: 1,
                    "&.Mui-selected": {
                      bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, 0.08),
                      "&:hover": {
                        bgcolor: (theme) =>
                          alpha(theme.palette.primary.main, 0.12),
                      },
                    },
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 44 }}>
                    <Box
                      sx={{
                        width: 32,
                        height: 32,
                        borderRadius: "50%",
                        overflow: "hidden",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                        border: "2px solid",
                        borderColor: "background.paper",
                        transition: "transform 0.2s",
                        "&:hover": {
                          transform: "scale(1.05)",
                        },
                        "& > svg": {
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          transform: "scale(1.4)",
                        },
                      }}
                    >
                      <LangFlag />
                    </Box>
                  </ListItemIcon>
                  <ListItemText
                    primary={language.name}
                    secondary={language.region}
                    primaryTypographyProps={{
                      fontWeight: language.code === i18n.language ? 600 : 400,
                    }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        <Divider />
        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            startIcon={<Add />}
            onClick={handleRequestDialogOpen}
            sx={{
              justifyContent: "flex-start",
              px: 2,
              py: 1.5,
              borderRadius: 1,
              color: "text.secondary",
              "&:hover": {
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
              },
            }}
          >
            {t("language.request")}
          </Button>
        </Box>
      </Popover>

      <BaseDialog
        open={requestDialogOpen}
        onClose={handleRequestDialogClose}
        title={t("language.requestTitle", "Solicitar novo idioma")}
        icon={Translate}
        useCustomHeader={true}
        actions={
          <>
            <Button onClick={handleRequestDialogClose} variant="outlined">
              {t("common.cancel", "Cancelar")}
            </Button>
            <Button
              onClick={handleRequestSubmit}
              variant="contained"
              disabled={!requestLanguage.trim() || !requestReason.trim()}
            >
              {t("language.submit", "Enviar")}
            </Button>
          </>
        }
      >
        <DialogContentText sx={{ mb: 3 }}>
          {t("language.requestDescription")}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label={t("language.requestLanguageLabel")}
          fullWidth
          variant="outlined"
          value={requestLanguage}
          onChange={(e) => setRequestLanguage(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          margin="dense"
          label={t("language.requestReasonLabel")}
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          value={requestReason}
          onChange={(e) => setRequestReason(e.target.value)}
        />
      </BaseDialog>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t("language.requestSent", "Solicitação enviada com sucesso!")}
        severity="success"
        autoHideDuration={3000}
      />
    </>
  );
};

export default LanguageSelector;
