import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  Typography,
  Alert,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getDatabase, ref, get, update } from 'firebase/database';
import { useTheme } from '@mui/material/styles';
import AuthCard from '../components/common/AuthCard';
import TypewriterText from '../components/common/TypewriterText';

export default function GuestLogin() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const welcomeMessages = t('guestLogin.welcomeMessages', { returnObjects: true });

  // Utility function to normalize email
  function normalizeEmail(email) {
    if (!email) return '';
    
    // Remove all whitespace
    let normalized = email.replace(/\s+/g, '');
    
    // Convert to lowercase
    normalized = normalized.toLowerCase();
    
    // Remove dots from Gmail addresses (Gmail ignores dots)
    if (normalized.includes('@gmail.com')) {
      normalized = normalized.replace(/\./g, '');
    }
    
    return normalized;
  }

  // Utility function to encode email for Firebase path
  function encodeEmailForFirebase(email) {
    return email
      .toLowerCase()
      .trim()
      .replace(/\./g, '_dot_')
      .replace(/@/g, '_at_')
      .replace(/\+/g, '_plus_')
      .replace(/=/g, '_eq_')
      .replace(/\//g, '_slash_');
  }

  async function handleSubmit(e) {
    e.preventDefault();
    
    if (!email) {
      setError(t('guestLogin.emailRequired'));
      return;
    }

    try {
      setError('');
      setLoading(true);

      // Normalize email
      const normalizedEmail = normalizeEmail(email);
      console.log('Attempting guest login for email:', normalizedEmail);

      const db = getDatabase();
      // Check guest access directly
      const guestAccessRef = ref(db, 'guestAccess');
      const snapshot = await get(guestAccessRef);

      if (!snapshot.exists()) {
        console.error('No guest access found in database');
        setError(t('guestLogin.noProperties'));
        return;
      }

      // Find active properties for this guest
      const activeProperties = [];
      snapshot.forEach((guestSnapshot) => {
        const guestData = guestSnapshot.val();
        
        // Check if this guest entry matches the email
        if (guestData.email === normalizedEmail) {
          const properties = guestData.properties || {};
          
          console.log('Guest properties:', properties);

          Object.entries(properties).forEach(([propertyId, accessData]) => {
            const isActive = accessData.status === 'active';
            const isNotExpired = !accessData.accessExpires || 
                                 new Date(accessData.accessExpires) > new Date();
            
            console.log(`Access check for ${propertyId}:`, { 
              isActive, 
              isNotExpired, 
              accessExpires: accessData.accessExpires 
            });

            if (isActive && isNotExpired) {
              activeProperties.push({
                id: propertyId,
                ...accessData
              });
            }
          });
        }
      });

      console.log('Active Properties:', activeProperties);

      if (activeProperties.length > 0) {
        // Update last access for the first active property
        const [firstProperty] = activeProperties;
        const encodedEmail = btoa(normalizedEmail);
        const updates = {};
        updates[`guestAccess/${encodedEmail}/lastAccess`] = new Date().toISOString();
        await update(ref(db), updates);
        
        // Store guest email and property in session
        sessionStorage.setItem('guestEmail', normalizedEmail);
        sessionStorage.setItem('propertyId', firstProperty.id);
        
        // Redirect to dashboard instead of guest-access
        navigate('/dashboard');
      } else {
        console.error('No active access found for email:', normalizedEmail);
        setError(t('guestLogin.noActiveAccess'));
      }
    } catch (error) {
      console.error('Guest login error:', error);
      setError(t('guestLogin.error'));
    } finally {
      setLoading(false);
    }
  }

  const styles = {
    textField: {
      marginBottom: { xs: theme.spacing(2), sm: theme.spacing(2.5) },
      '& .MuiOutlinedInput-root': {
        borderRadius: { xs: 3, sm: 4 },
        backgroundColor: theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.05)'
          : 'rgba(0, 0, 0, 0.02)',
        transition: theme.transitions.create([
          'background-color', 
          'box-shadow', 
          'border-color'
        ], {
          duration: 200,
        }),
        '& .MuiOutlinedInput-input': {
          padding: { xs: '12px 14px', sm: '14px 16px' },
          fontSize: { xs: '0.9rem', sm: '1rem' },
        },
        '&:hover': {
          backgroundColor: theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, 0.1)'
            : 'rgba(0, 0, 0, 0.04)',
        },
        '&.Mui-focused': {
          backgroundColor: theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, 0.15)'
            : 'rgba(0, 0, 0, 0.06)',
          boxShadow: `0 0 0 1px ${theme.palette.primary.main}25`,
        }
      }
    },
    submitButton: {
      padding: { xs: theme.spacing(1.2), sm: theme.spacing(1.5) },
      marginTop: { xs: theme.spacing(0.5), sm: theme.spacing(1) },
      marginBottom: { xs: theme.spacing(2), sm: theme.spacing(3) },
      borderRadius: { xs: 3, sm: 4 },
      textTransform: 'none',
      fontSize: { xs: '0.95rem', sm: '1rem' },
      fontWeight: 600,
      background: 'linear-gradient(45deg, #ff4b6e, #ff758c)',
      boxShadow: '0 2px 8px rgba(255, 75, 110, 0.25)',
      transition: 'all 0.2s ease-in-out',
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: '0 4px 12px rgba(255, 75, 110, 0.35)',
      },
      '&:active': {
        transform: 'translateY(1px)',
      }
    },
    buttonProgress: {
      color: 'white',
    },
    backButton: {
      padding: theme.spacing(1.5),
      borderRadius: 4,
      textTransform: 'none',
      fontSize: '0.95rem',
      fontWeight: 500,
      color: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.05)'
        : 'rgba(0, 0, 0, 0.02)',
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(0, 0, 0, 0.04)',
      }
    }
  };

  return (
    <AuthCard>
      <Box sx={{ textAlign: 'center', mb: 3 }}>
        <TypewriterText />
        <Typography variant="subtitle1" color="text.secondary">
          {t('guestLogin.subtitle')}
        </Typography>
      </Box>

      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 3, borderRadius: 2 }}
        >
          {error}
        </Alert>
      )}

      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <TextField
          fullWidth
          label={t('guestLogin.email')}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={loading}
          sx={styles.textField}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={loading}
          sx={styles.submitButton}
        >
          {loading ? (
            <CircularProgress size={24} sx={styles.buttonProgress} />
          ) : (
            t('guestLogin.submit')
          )}
        </Button>

        <Button
          variant="outlined"
          fullWidth
          onClick={() => navigate('/')}
          sx={{
            mt: 2,
            padding: theme.spacing(1.5),
            borderRadius: 4,
            textTransform: 'none',
            fontSize: '0.95rem',
            fontWeight: 500,
            color: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.mode === 'dark'
              ? 'rgba(255, 255, 255, 0.05)'
              : 'rgba(0, 0, 0, 0.02)',
            '&:hover': {
              backgroundColor: theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.1)'
                : 'rgba(0, 0, 0, 0.04)',
            }
          }}
        >
          {t('guestLogin.back')}
        </Button>
      </form>
    </AuthCard>
  );
}
