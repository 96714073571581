import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function PrivateRoute({ children }) {
  const { currentUser, getGuestProperties } = useAuth();

  // If no user is logged in, redirect to landing page
  if (!currentUser) {
    return <Navigate to="/" />;
  }

  // If user is a host, allow access
  if (currentUser.isHost) {
    return children;
  }

  // For guests, check if they have active properties
  const checkGuestAccess = async () => {
    try {
      const properties = await getGuestProperties(currentUser.email);
      return properties.length > 0;
    } catch (error) {
      console.error('Error checking guest properties:', error);
      return false;
    }
  };

  // For now, we'll allow guest access. In a real-world scenario, you might want to add more robust checking
  return children;
}
