import React, { useState, useEffect } from "react";
import { Container, Alert, AlertTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useProperty } from "../contexts/PropertyContext";

import GuideList from "../components/settings/GuideList";
import UserManagement from "../components/settings/UserManagement";
import SettingsHeader from "../components/settings/SettingsHeader";
import SettingsSnackbar from "../components/settings/SettingsSnackbar";
import AppearanceSettings from "../components/settings/AppearanceSettings";
import AccountManagement from "../components/settings/AccountManagement";

const Settings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentUser, getUserGuides, getGuestProperties } = useAuth();
  const { currentPropertyId, selectProperty } = useProperty();

  const [guides, setGuides] = useState([]);
  const [selectedGuide, setSelectedGuide] = useState(null);
  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [currentTab, setCurrentTab] = useState("guide");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Appearance states
  const [brandColor, setBrandColor] = useState("#444CE7");
  const [chartStyle, setChartStyle] = useState("default");
  const [cookieBanner, setCookieBanner] = useState("default");

  // Account states
  const [guidesCount, setGuidesCount] = useState(5);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [currentUserData] = useState({
    name: "João Silva",
    email: "joao.silva@email.com",
    avatar: "JS",
    plan: "basic",
    joinDate: "2023-12-01",
    nextBilling: "2024-02-01",
  });

  const guidesMarks = [
    { value: 1, label: "1" },
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 20, label: "20" },
    { value: 25, label: "25" },
  ];

  const calculateProPrice = (guides) => {
    if (guides === 1) return 9.9;
    const basePrice = 29;
    const pricePerGuide = 5;
    if (guides <= 5) return basePrice;
    return basePrice + (guides - 5) * pricePerGuide;
  };

  const plans = [
    {
      name: "Básico",
      price: "0",
      period: t("settings.account.plans.period.free"),
      discount: null,
      features: [
        "1 guia digital",
        "Até 2 anfitriões por guia",
        "Personalização básica do guia",
        "Integração com Airbnb",
      ],
      current: true,
    },
    {
      name: "Profissional",
      price: calculateProPrice(guidesCount),
      period: t("settings.account.plans.period.monthly"),
      discount: "35%",
      features: [
        `Até ${guidesCount} ${
          guidesCount === 1 ? "guia digital" : "guias digitais"
        }`,
        "Até 10 anfitriões por guia",
        "Personalização avançada do guia",
        "Exportar guia em PDF",
        "Integração com Airbnb",
      ],
      popular: true,
      hasSlider: true,
    },
    {
      name: "Personalizado",
      price: "Consulte",
      period: "",
      discount: null,
      features: [
        "Mais de 25 guias digitais",
        "Anfitriões ilimitados por guia",
        "Personalização total do guia",
        "Exportar guia em PDF",
        "Integração com Airbnb",
        "Integração com múltiplas plataformas",
      ],
      enterprise: true,
    },
  ];

  useEffect(() => {
    const checkGuestAccess = async () => {
      if (!currentUser) {
        navigate("/login");
        return;
      }

      if (currentUser.isHost) {
        setIsAuthorized(true);
        return;
      }

      try {
        const properties = await getGuestProperties(currentUser.email);
        setIsAuthorized(properties.length > 0);
      } catch (error) {
        console.error("Erro ao verificar acesso do guest:", error);
        setIsAuthorized(false);
      }
    };

    checkGuestAccess();
  }, [currentUser, getGuestProperties, navigate]);

  useEffect(() => {
    const loadGuides = async () => {
      if (currentUser) {
        try {
          const userGuides = await getUserGuides();
          if (Array.isArray(userGuides)) {
            const fullGuides = await Promise.all(
              userGuides.map(async (guide) => {
                const ptData = guide.languages?.ptbr?.property_data || {};
                const locationDetails =
                  guide.languages?.ptbr?.property_data?.location_details?.[0] ||
                  {};

                return {
                  id: guide.id,
                  title: ptData.name || guide.name || "Propriedade sem título",
                  description: ptData.description || "Sem descrição",
                  lastUpdate: guide.updatedAt || new Date().toISOString(),
                  status: guide.status || "active",
                  hosts: 1,
                  propertyType: ptData.category || "Propriedade",
                  location:
                    locationDetails.title ||
                    guide.location ||
                    "Localização não especificada",
                  image: ptData.image || null,
                  users: [{ email: currentUser.email, role: "admin" }],
                };
              })
            );

            setGuides(fullGuides);

            const savedGuideId = localStorage.getItem("selectedGuideId");
            if (savedGuideId && fullGuides.some((g) => g.id === savedGuideId)) {
              handleSelectGuide(savedGuideId);
            }
          }
        } catch (error) {
          console.error("Erro ao buscar guias:", error);
          setSnackbar({
            open: true,
            message: "Erro ao carregar guias",
            severity: "error",
          });
          setGuides([]);
        }
      }
    };

    loadGuides();
  }, [currentUser, getUserGuides]);

  const handleSelectGuide = async (guideId) => {
    if (!Array.isArray(guides) || guides.length === 0) return;

    const guide = guides.find((g) => g.id === guideId);
    if (!guide) return;

    if (currentPropertyId !== guideId) {
      try {
        await selectProperty(guideId);
        setSnackbar({
          open: true,
          message: t("settings.guide.guideSelected", { name: guide.title }),
          severity: "success",
        });
      } catch (error) {
        console.error("Erro ao selecionar guia:", error);
        setSnackbar({
          open: true,
          message: t("settings.guide.error.selectGuide"),
          severity: "error",
        });
      }
    }
  };

  const handleSync = (guide) => {
    setSnackbar({
      open: true,
      message: t("settings.guide.syncSuccess", { name: guide.title }),
      severity: "success",
    });
  };

  const handleManageUsers = (guide) => {
    setSelectedGuide(guide);
    setUserDialogOpen(true);
  };

  const handleAddUser = () => {
    if (!newUserEmail || !selectedGuide) return;

    setSnackbar({
      open: true,
      message: t("settings.users.userAdded", { email: newUserEmail }),
      severity: "success",
    });

    setNewUserEmail("");
    setUserDialogOpen(false);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {!isAuthorized ? (
        <Alert severity="warning" sx={{ mb: 4 }}>
          <AlertTitle>{t("settings.unauthorized.title")}</AlertTitle>
          {t("settings.unauthorized.message")}
        </Alert>
      ) : (
        <>
          <SettingsHeader currentTab={currentTab} onTabChange={setCurrentTab} />

          {currentTab === "guide" && (
            <GuideList
              guides={guides}
              onManageUsers={handleManageUsers}
              onSync={handleSync}
              onSelectGuide={handleSelectGuide}
              currentPropertyId={currentPropertyId}
            />
          )}

          {currentTab === "appearance" && (
            <AppearanceSettings
              brandColor={brandColor}
              onBrandColorChange={setBrandColor}
              chartStyle={chartStyle}
              onChartStyleChange={setChartStyle}
              cookieBanner={cookieBanner}
              onCookieBannerChange={setCookieBanner}
            />
          )}

          {currentTab === "account" && (
            <AccountManagement
              currentUserData={currentUserData}
              guidesCount={guidesCount}
              onGuidesCountChange={setGuidesCount}
              guidesMarks={guidesMarks}
              plans={plans}
            />
          )}

          <UserManagement
            open={userDialogOpen}
            onClose={() => {
              setUserDialogOpen(false);
              setSelectedGuide(null);
              setNewUserEmail("");
            }}
            guide={selectedGuide}
            newUserEmail={newUserEmail}
            onNewUserEmailChange={setNewUserEmail}
            onAddUser={handleAddUser}
          />

          <SettingsSnackbar
            open={snackbar.open}
            message={snackbar.message}
            severity={snackbar.severity}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          />
        </>
      )}
    </Container>
  );
};

export default Settings;
