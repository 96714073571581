import React, { useRef, useState } from 'react';
import {
  Box,
  IconButton,
  TextField,
  Grid,
  CircularProgress,
  Button,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Close as CloseIcon,
  Save as SaveIcon,
  Add as AddIcon,
  Security as SecurityIcon,
  ListAlt as ListAltIcon,
  InfoOutlined as InfoOutlinedIcon
} from '@mui/icons-material';
import { useTheme, alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ConfigSection from './ConfigSection';
import CustomTooltip from '../common/CustomTooltip';
import InstructionItem from './InstructionItem';

const ElectronicKeySection = ({
  electronicKeyForm,
  isEditingKey,
  isSavingKey,
  onKeyChange,
  onStartEditing,
  onCancelEditing,
  onSave,
  onAddInstruction,
  onRemoveInstruction,
  onInstructionChange,
  onAddNote,
  onRemoveNote,
  onNoteChange,
  onMoveInstruction
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const electronicKeyRef = useRef(null);
  const [newNote, setNewNote] = useState('');

  return (
    <ConfigSection 
      title={t('propertyManagement.electronicKey.title')}
      icon={<SecurityIcon />}
      action={
        <Box>
          {!isEditingKey ? (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <CustomTooltip title={t('common.edit')}>
                <IconButton
                  onClick={onStartEditing}
                  size="large"
                  sx={{ 
                    width: 32,
                    height: 32,
                    bgcolor: alpha(theme.palette.primary.main, 0.1),
                    color: 'primary.main',
                    '&:hover': {
                      bgcolor: alpha(theme.palette.primary.main, 0.2)
                    }
                  }}
                >
                  <EditIcon fontsize="large" />
                </IconButton>
              </CustomTooltip>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <CustomTooltip title={t('common.cancel')}>
                <IconButton
                  onClick={onCancelEditing}
                  size="large"
                  disabled={isSavingKey}
                  sx={{ 
                    width: 32,
                    height: 32,
                    bgcolor: alpha(theme.palette.text.secondary, 0.1),
                    color: 'text.secondary',
                    '&:hover': {
                      bgcolor: alpha(theme.palette.text.secondary, 0.2)
                    }
                  }}
                >
                  <CloseIcon fontsize="large" />
                </IconButton>
              </CustomTooltip>
              <CustomTooltip title={t('common.save')}>
                <IconButton
                  onClick={onSave}
                  size="large"
                  disabled={isSavingKey}
                  sx={{ 
                    width: 32,
                    height: 32,
                    bgcolor: alpha(theme.palette.error.main, 0.1),
                    color: 'error.main',
                    '&:hover': {
                      bgcolor: alpha(theme.palette.error.main, 0.2)
                    }
                  }}
                >
                  {isSavingKey ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <SaveIcon fontsize="large" />
                  )}
                </IconButton>
              </CustomTooltip>
            </Box>
          )}
        </Box>
      }
    >
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="large"
              type="text"
              label={t('propertyManagement.electronicKey.code')}
              inputRef={electronicKeyRef}
              value={electronicKeyForm.code}
              onChange={(e) => onKeyChange('code', e.target.value)}
              disabled={!isEditingKey}
              sx={{ mb: 2 }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" color="primary" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
              <ListAltIcon fontsize="large" /> {t('propertyManagement.electronicKey.instructions')}
            </Typography>

            <DndProvider backend={HTML5Backend}>
              <Box sx={{ 
                bgcolor: alpha(theme.palette.primary.main, 0.04),
                borderRadius: 2,
                p: 2,
                mt: 2
              }}>
                {electronicKeyForm.instructions.map((instruction, index) => (
                  <InstructionItem
                    key={index}
                    id={index}
                    index={index}
                    instruction={instruction}
                    moveInstruction={(dragIndex, hoverIndex) => {
                      const updatedInstructions = [...electronicKeyForm.instructions];
                      const dragItem = updatedInstructions[dragIndex];
                      updatedInstructions.splice(dragIndex, 1);
                      updatedInstructions.splice(hoverIndex, 0, dragItem);
                      onKeyChange('instructions', updatedInstructions);
                    }}
                    handleInstructionChange={onInstructionChange}
                    handleRemoveInstruction={onRemoveInstruction}
                    isEditingKey={isEditingKey}
                  />
                ))}

                {isEditingKey && (
                  <Button
                    startIcon={<AddIcon />}
                    onClick={onAddInstruction}
                    variant="outlined"
                    fullWidth
                    sx={{ 
                      mt: 2,
                      borderStyle: 'dashed',
                      borderWidth: 1,
                      borderColor: theme => alpha(theme.palette.primary.main, 0.3),
                      color: 'primary.main',
                      '&:hover': {
                        borderStyle: 'dashed',
                        borderColor: theme => alpha(theme.palette.primary.main, 0.5),
                        bgcolor: alpha(theme.palette.primary.main, 0.08)
                      }
                    }}
                  >
                    {t('propertyManagement.electronicKey.addInstruction')}
                  </Button>
                )}
              </Box>
            </DndProvider>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" color="primary" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2, mt: 2 }}>
              <InfoOutlinedIcon fontsize="large" /> {t('propertyManagement.electronicKey.notes')}
            </Typography>

            <Box sx={{ 
              bgcolor: alpha(theme.palette.primary.main, 0.04),
              borderRadius: 2,
              p: 2
            }}>
              {electronicKeyForm.notes.map((note, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    mb: 1
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      flex: 1,
                      p: 1,
                      borderRadius: 1,
                      bgcolor: theme => alpha(theme.palette.warning.main, 0.08),
                      border: '1px solid',
                      borderColor: theme => alpha(theme.palette.warning.main, 0.2),
                    }}
                  >
                    {note}
                  </Typography>
                  {isEditingKey && (
                    <CustomTooltip title={t('common.remove')}>
                      <IconButton
                        onClick={() => onRemoveNote(index)}
                        size="small"
                        sx={{
                          color: 'error.main',
                          '&:hover': {
                            bgcolor: theme => alpha(theme.palette.error.main, 0.1)
                          }
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </CustomTooltip>
                  )}
                </Box>
              ))}

              {isEditingKey && (
                <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder={t('propertyManagement.electronicKey.addNotePlaceholder')}
                    value={newNote}
                    onChange={(e) => setNewNote(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && newNote.trim()) {
                        const updatedNotes = [...electronicKeyForm.notes, newNote.trim()];
                        onKeyChange('notes', updatedNotes);
                        setNewNote('');
                      }
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        bgcolor: 'white',
                        '& fieldset': {
                          borderColor: theme => alpha(theme.palette.warning.main, 0.3),
                        },
                        '&:hover fieldset': {
                          borderColor: theme => alpha(theme.palette.warning.main, 0.5),
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'warning.main',
                        }
                      }
                    }}
                  />
                  <CustomTooltip title={t('propertyManagement.electronicKey.addNote')}>
                    <IconButton
                      onClick={() => {
                        if (newNote.trim()) {
                          const updatedNotes = [...electronicKeyForm.notes, newNote.trim()];
                          onKeyChange('notes', updatedNotes);
                          setNewNote('');
                        }
                      }}
                      sx={{
                        bgcolor: theme => alpha(theme.palette.warning.main, 0.1),
                        color: 'warning.main',
                        '&:hover': {
                          bgcolor: theme => alpha(theme.palette.warning.main, 0.2)
                        }
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </CustomTooltip>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ConfigSection>
  );
};

export default ElectronicKeySection;
