import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Box, useTheme, alpha } from '@mui/material';
import { keyframes } from '@mui/system';
import { throttle } from 'lodash';

const floatAnimation = keyframes`
  0% {
    transform: translate(0, 0) rotate(0deg) scale(1);
    opacity: 0.1;
    filter: blur(0px);
  }
  25% {
    transform: translate(30px, 30px) rotate(45deg) scale(1.05);
    opacity: 0.15;
    filter: blur(1px);
  }
  50% {
    transform: translate(60px, 60px) rotate(90deg) scale(1);
    opacity: 0.2;
    filter: blur(0px);
  }
  75% {
    transform: translate(30px, 30px) rotate(135deg) scale(0.95);
    opacity: 0.15;
    filter: blur(1px);
  }
  100% {
    transform: translate(0, 0) rotate(180deg) scale(1);
    opacity: 0.1;
    filter: blur(0px);
  }
`;

const pulseAnimation = keyframes`
  0% {
    transform: scale(1) rotate(0deg);
    opacity: 0.1;
    filter: blur(0px);
  }
  50% {
    transform: scale(1.05) rotate(45deg);
    opacity: 0.2;
    filter: blur(1px);
  }
  100% {
    transform: scale(1) rotate(90deg);
    opacity: 0.1;
    filter: blur(0px);
  }
`;

const waveAnimation = keyframes`
  0% {
    transform: translateY(0px);
    opacity: 0.1;
    filter: blur(0px);
  }
  50% {
    transform: translateY(-5px);
    opacity: 0.2;
    filter: blur(1px);
  }
  100% {
    transform: translateY(0px);
    opacity: 0.1;
    filter: blur(0px);
  }
`;

const glowAnimation = keyframes`
  0% {
    filter: drop-shadow(0 0 2px currentColor);
  }
  50% {
    filter: drop-shadow(0 0 10px currentColor);
  }
  100% {
    filter: drop-shadow(0 0 2px currentColor);
  }
`;

const AnimatedBackground = () => {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Calcular número de elementos baseado no tamanho da tela
  const elementCount = useMemo(() => {
    const area = windowSize.width * windowSize.height;
    return Math.min(Math.floor(area / 1500), 100); // Aumentado para 60 elementos e reduzida a área por elemento
  }, [windowSize]);

  const shapes = [
    {
      // Avião
      type: 'path',
      d: 'M20.56 3.91c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0l-3.54 3.54-4.24-1.41L9.25 2.5 6.5.75c-.29-.29-.68-.44-1.06-.44s-.77.15-1.06.44L3.13 2C2.84 2.29 2.69 2.68 2.69 3.06s.15.77.44 1.06l1.75 2.75-1.41 1.41-3.54 3.54c-.59.59-.59 1.54 0 2.12.29.29.68.44 1.06.44s.77-.15 1.06-.44l3.54-3.54 1.41-1.41 2.75 1.75c.29.29.68.44 1.06.44s.77-.15 1.06-.44l1.25-1.25 1.41-4.24 3.54-3.54z',
      color: primaryColor,
      size: 40,
      animation: floatAnimation,
      depth: 1,
    },
    {
      // Mala de viagem
      type: 'path',
      d: 'M20 8h-3V6c0-1.1-.9-2-2-2H9c-1.1 0-2 .9-2 2v2H4c-1.1 0-2 .9-2 2v10h20V10c0-1.1-.9-2-2-2zM9 6h6v2H9V6z',
      color: alpha(primaryColor, 0.8),
      size: 40,
      animation: pulseAnimation,
      depth: 1,
    },
    {
      // Casa
      type: 'path',
      d: 'M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z',
      color: alpha(primaryColor, 0.6),
      size: 40,
      animation: waveAnimation,
      depth: 1,
    },
    {
      // Localização
      type: 'path',
      d: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
      color: alpha(primaryColor, 0.7),
      size: 35,
      animation: pulseAnimation,
      depth: 1,
    },
    {
      // Câmera
      type: 'path',
      d: 'M12 15.2c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm8-3h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65h-4.24c-.56 0-1.1.24-1.47.65L7.17 6.2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2z',
      color: alpha(primaryColor, 0.5),
      size: 30,
      animation: waveAnimation,
      depth: 1,
    },
    {
      // Bússola
      type: 'path',
      d: 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z',
      color: alpha(primaryColor, 0.9),
      size: 45,
      animation: floatAnimation,
      depth: 1,
    },
    {
      // Passaporte
      type: 'path',
      d: 'M20 4H4c-1.11 0-2 .89-2 2v12c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm-7 2h7v12h-7V6zm-4 12H4V6h5v12zm3-6c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3z',
      color: alpha(primaryColor, 0.75),
      size: 35,
      animation: pulseAnimation,
      depth: 1,
    },
    {
      // Mapa
      type: 'path',
      d: 'M20.5 3l-.16.03L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5c0 .28.22.5.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V3.5c0-.28-.22-.5-.5-.5zM15 19l-6-2.11V5l6 2.11V19z',
      color: alpha(primaryColor, 0.65),
      size: 40,
      animation: waveAnimation,
      depth: 1,
    },
    {
      // Estrela (Favoritos)
      type: 'path',
      d: 'M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z',
      color: alpha(primaryColor, 0.55),
      size: 30,
      animation: pulseAnimation,
      depth: 1,
    },
    {
      // Globo
      type: 'path',
      d: 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z',
      color: alpha(primaryColor, 0.85),
      size: 45,
      animation: floatAnimation,
      depth: 1,
    },
    {
      // Cama
      type: 'path',
      d: 'M7 13c1.66 0 3-1.34 3-3S8.66 7 7 7s-3 1.34-3 3 1.34 3 3 3zm12-6h-8v7H3V7H1v10h22v-6c0-2.21-1.79-4-4-4zm-2 8h-8v-5h8v5z',
      color: alpha(primaryColor, 0.7),
      size: 35,
      animation: waveAnimation,
    },
    {
      // Wifi
      type: 'path',
      d: 'M1 9l2 2c4.97-4.97 13.03-4.97 18 0l2-2C16.93 2.93 7.08 2.93 1 9zm8 8l3 3 3-3c-1.65-1.66-4.34-1.66-6 0zm-4-4l2 2c2.76-2.76 7.24-2.76 10 0l2-2C15.14 9.14 8.87 9.14 5 13z',
      color: alpha(primaryColor, 0.65),
      size: 30,
      animation: pulseAnimation,
    },
    {
      // Chave
      type: 'path',
      d: 'M12.65 10C11.83 7.67 9.61 6 7 6c-3.31 0-6 2.69-6 6s2.69 6 6 6c2.61 0 4.83-1.67 5.65-4H17v4h4v-4h2v-4H12.65zM7 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z',
      color: alpha(primaryColor, 0.75),
      size: 35,
      animation: floatAnimation,
    },
    {
      // Calendário
      type: 'path',
      d: 'M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z',
      color: alpha(primaryColor, 0.6),
      size: 32,
      animation: waveAnimation,
    },
    {
      // Coração
      type: 'path',
      d: 'M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z',
      color: alpha(primaryColor, 0.55),
      size: 30,
      animation: pulseAnimation,
    },
    {
      // Garfo e Faca
      type: 'path',
      d: 'M11 9H9V2H7v7H5V2H3v7c0 2.12 1.66 3.84 3.75 3.97V22h2.5v-9.03C11.34 12.84 13 11.12 13 9V2h-2v7zm5-3v8h2.5v8H21V2c-2.76 0-5 2.24-5 4z',
      color: alpha(primaryColor, 0.7),
      size: 35,
      animation: waveAnimation,
    },
    {
      // Praia/Guarda-sol
      type: 'path',
      d: 'M13.127 14.56l1.43-1.43 6.44 6.443L19.57 21l-6.44-6.44zM17.42 8.83l2.86-2.86c-3.95-3.95-10.35-3.96-14.3-.02 3.93-1.3 8.31-.25 11.44 2.88zM5.95 5.98c-3.94 3.95-3.93 10.35.02 14.3l2.86-2.86C5.7 14.29 4.65 9.91 5.95 5.98zm.02-.02l-.01.01c-.38 3.01 1.17 6.88 4.3 10.02l5.73-5.73c-3.13-3.13-7.01-4.68-10.02-4.3z',
      color: alpha(primaryColor, 0.65),
      size: 35,
      animation: waveAnimation,
    },
    {
      // Montanha
      type: 'path',
      d: 'M14 6l-3.75 5 2.85 3.8-1.6 1.2C9.81 13.75 7 10 7 10l-6 8h22L14 6z',
      color: alpha(primaryColor, 0.7),
      size: 40,
      animation: floatAnimation,
    },
    {
      // Cartão de Crédito
      type: 'path',
      d: 'M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z',
      color: alpha(primaryColor, 0.6),
      size: 32,
      animation: pulseAnimation,
    },
    {
      // Presente/Gift
      type: 'path',
      d: 'M20 6h-2.18c.11-.31.18-.65.18-1 0-1.66-1.34-3-3-3-1.05 0-1.96.54-2.5 1.35l-.5.67-.5-.68C10.96 2.54 10.05 2 9 2 7.34 2 6 3.34 6 5c0 .35.07.69.18 1H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-5-2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM9 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm11 15H4v-2h16v2zm0-5H4V8h5.08L7 10.83 8.62 12 11 8.76l1-1.36 1 1.36L15.38 12 17 10.83 14.92 8H20v6z',
      color: alpha(primaryColor, 0.55),
      size: 35,
      animation: waveAnimation,
    },
    {
      // Táxi
      type: 'path',
      d: 'M18.92 6.01C18.72 5.42 18.16 5 17.5 5H15V3H9v2H6.5c-.66 0-1.21.42-1.42 1.01L3 12v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.5 16c-.83 0-1.5-.67-1.5-1.5S5.67 13 6.5 13s1.5.67 1.5 1.5S7.33 16 6.5 16zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM5 11l1.5-4.5h11L19 11H5z',
      color: alpha(primaryColor, 0.75),
      size: 38,
      animation: floatAnimation,
    },
    {
      // Piscina
      type: 'path',
      d: 'M22 21c-1.11 0-1.73-.37-2.18-.64-.37-.22-.6-.36-1.15-.36-.56 0-.78.13-1.15.36-.46.27-1.07.64-2.18.64s-1.73-.37-2.18-.64c-.37-.22-.6-.36-1.15-.36-.56 0-.78.13-1.15.36-.46.27-1.08.64-2.19.64v-2c.56 0 .78-.13 1.15-.36.46-.27 1.08-.64 2.19-.64s1.73.37 2.18.64c.37.22.6.36 1.15.36.56 0 .78-.13 1.15-.36.46-.27 1.08-.64 2.19-.64 1.11 0 1.73.37 2.18.64.37.23.59.36 1.15.36v2zm0-4.5c-1.11 0-1.73-.37-2.18-.64-.37-.22-.6-.36-1.15-.36-.56 0-.78.13-1.15.36-.45.27-1.07.64-2.18.64s-1.73-.37-2.18-.64c-.37-.22-.6-.36-1.15-.36-.56 0-.78.13-1.15.36-.45.27-1.07.64-2.18.64s-1.73-.37-2.18-.64c-.37-.22-.6-.36-1.15-.36s-.78.13-1.15.36c-.47.27-1.09.64-2.2.64v-2c.56 0 .78-.13 1.15-.36.47-.27 1.09-.64 2.2-.64s1.73.37 2.18.64c.37.22.6.36 1.15.36.56 0 .78-.13 1.15-.36.45-.27 1.07-.64 2.18-.64s1.73.37 2.18.64c.37.22.6.36 1.15.36s.78-.13 1.15-.36c.45-.27 1.07-.64 2.18-.64s1.73.37 2.18.64c.37.22.6.36 1.15.36v2zm0-4.5c-1.11 0-1.73-.37-2.18-.64-.37-.22-.6-.36-1.15-.36-.56 0-.78.13-1.15.36-.45.27-1.07.64-2.18.64s-1.73-.37-2.18-.64c-.37-.22-.6-.36-1.15-.36-.56 0-.78.13-1.15.36-.45.27-1.07.64-2.18.64s-1.73-.37-2.18-.64c-.37-.22-.6-.36-1.15-.36s-.78.13-1.15.36c-.47.27-1.09.64-2.2.64v-2c.56 0 .78-.13 1.15-.36.47-.27 1.09-.64 2.2-.64s1.73.37 2.18.64c.37.22.6.36 1.15.36.56 0 .78-.13 1.15-.36.45-.27 1.07-.64 2.18-.64s1.73.37 2.18.64c.37.22.6.36 1.15.36s.78-.13 1.15-.36c.45-.27 1.07-.64 2.18-.64s1.73.37 2.18.64c.37.22.6.36 1.15.36v2z',
      color: alpha(primaryColor, 0.6),
      size: 40,
      animation: waveAnimation,
    },
    {
      // Drink
      type: 'path',
      d: 'M21 5V3H3v2l8 9v5H6v2h12v-2h-5v-5l8-9zM7.43 7L5.66 5h12.69l-1.78 2H7.43z',
      color: alpha(primaryColor, 0.65),
      size: 30,
      animation: pulseAnimation,
    },
    {
      // Tag/Etiqueta
      type: 'path',
      d: 'M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42zM5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4 7 4.67 7 5.5 6.33 7 5.5 7z',
      color: alpha(primaryColor, 0.7),
      size: 32,
      animation: floatAnimation,
    }
  ];

  // Atualizar posição do mouse com throttling mais agressivo
  const handleMouseMove = useCallback(
    throttle((event) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    }, 100),
    []
  );

  // Atualizar tamanho da janela com throttling
  const handleResize = useCallback(
    throttle(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 200),
    []
  );

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('resize', handleResize);
      handleMouseMove.cancel();
      handleResize.cancel();
    };
  }, [handleMouseMove, handleResize]);

  const generateShapes = useCallback((count) => {
    return Array.from({ length: count }, (_, index) => {
      const shape = shapes[index % shapes.length];
      const randomX = Math.random() * windowSize.width;
      const randomY = Math.random() * windowSize.height;
      const randomDelay = -(Math.random() * 30);
      const randomDuration = 60 + Math.random() * 40;
      const scale = 0.5 + Math.random() * 0.3;
      const depth = Math.random() * 0.5;

      return {
        ...shape,
        x: randomX,
        y: randomY,
        delay: randomDelay,
        duration: randomDuration,
        scale,
        depth,
      };
    });
  }, [windowSize, shapes]);

  const calculateParallax = useCallback((x, y, depth) => {
    const moveX = (mousePosition.x - windowSize.width / 2) * depth * 0.01;
    const moveY = (mousePosition.y - windowSize.height / 2) * depth * 0.01;
    return { moveX, moveY };
  }, [mousePosition, windowSize]);

  const renderShape = useCallback((shape, index) => {
    const { moveX, moveY } = calculateParallax(shape.x, shape.y, shape.depth);
    
    const commonProps = {
      style: {
        animation: `
          ${shape.animation} ${shape.duration}s infinite ease-in-out,
          ${glowAnimation} ${15 + Math.random() * 10}s infinite ease-in-out
        `,
        animationDelay: `${shape.delay}s`,
        position: 'absolute',
        left: shape.x,
        top: shape.y,
        width: shape.size,
        height: shape.size,
        transform: `
          scale(${shape.scale})
          translate(${moveX}px, ${moveY}px)
        `,
        transition: 'all 0.5s ease-out',
        willChange: 'transform, opacity, filter',
        zIndex: Math.floor(shape.depth * 10),
        filter: `blur(${(1 - shape.depth) * 2}px)`,
      },
    };

    return (
      <svg 
        key={index} 
        {...commonProps} 
        viewBox="0 0 24 24"
        style={{
          ...commonProps.style,
          filter: `drop-shadow(0 2px 8px ${alpha(shape.color, 0.3)})`,
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.transform = `
            scale(${shape.scale * 1.2})
            translate(${moveX}px, ${moveY}px)
          `;
          e.currentTarget.style.filter = `drop-shadow(0 4px 12px ${alpha(shape.color, 0.4)})`;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.transform = `
            scale(${shape.scale})
            translate(${moveX}px, ${moveY}px)
          `;
          e.currentTarget.style.filter = `drop-shadow(0 2px 8px ${alpha(shape.color, 0.3)})`;
        }}
      >
        <path 
          d={shape.d} 
          fill={shape.color}
          style={{
            transition: 'fill 0.3s ease-out',
          }}
        />
      </svg>
    );
  }, [calculateParallax, alpha]);

  const memoizedShapes = useMemo(() => generateShapes(elementCount), [generateShapes, elementCount]);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden',
        zIndex: 0,
        background: theme.palette.mode === 'dark'
          ? `linear-gradient(135deg, ${alpha(primaryColor, 0.02)} 0%, ${alpha(primaryColor, 0.05)} 100%)`
          : `linear-gradient(135deg, ${alpha(primaryColor, 0.01)} 0%, ${alpha(primaryColor, 0.03)} 100%)`,
        transition: 'background 0.5s ease-in-out',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: theme.palette.mode === 'dark'
            ? `radial-gradient(circle at 50% 50%, ${alpha(primaryColor, 0.05)} 0%, ${alpha(primaryColor, 0)} 70%)`
            : `radial-gradient(circle at 50% 50%, ${alpha(primaryColor, 0.02)} 0%, ${alpha(primaryColor, 0)} 70%)`,
          filter: 'blur(40px)',
          opacity: 0.5,
          transition: 'all 0.5s ease-in-out',
        }
      }}
    >
      {memoizedShapes.map((shape, index) => renderShape(shape, index))}
    </Box>
  );
};

export default AnimatedBackground;
