import React from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  LocalPolice as PoliceIcon,
  LocalFireDepartment as FireIcon,
  LocalHospital as HospitalIcon,
  Security as SecurityIcon,
  Person as PersonIcon,
  Phone as PhoneIcon,
  WhatsApp as WhatsAppIcon,
  Support as SupportIcon,
} from "@mui/icons-material";
import { useProperty } from "../contexts/PropertyContext";
import { usePropertyContact } from "../hooks/usePropertyContact";
import BaseDialog from "./common/BaseDialog";

const EmergencyDialog = ({ open, onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { currentPropertyId } = useProperty();
  const { contactData, loading, error } = usePropertyContact(currentPropertyId);

  const iconMap = {
    police: <PoliceIcon />,
    fire: <FireIcon />,
    hospital: <HospitalIcon />,
    security: <SecurityIcon />,
    person: <PersonIcon />,
    owner: <PersonIcon />,
    maintenance: <SecurityIcon />,
    ambulance: <HospitalIcon />,
  };

  const colorMap = {
    police: "#1a237e",
    fire: "#b71c1c",
    hospital: "#c62828",
    security: "#2e7d32",
    person: "#0277bd",
    owner: "#0277bd",
    maintenance: "#2e7d32",
    ambulance: "#c62828",
  };

  const getEmergencyContacts = () => {
    console.log("Contact data:", contactData);

    if (!contactData?.emergency?.contact) {
      console.log("No emergency contacts found");
      return [];
    }

    const contacts = contactData.emergency.contact;
    console.log("Emergency contacts:", contacts);

    // Separar contatos em oficiais e da propriedade
    const officialContacts = contacts.filter((contact) =>
      ["police", "fire", "ambulance"].includes(contact.type)
    );

    const propertyContacts = contacts.filter((contact) =>
      ["owner", "maintenance"].includes(contact.type)
    );

    console.log("Official contacts:", officialContacts);
    console.log("Property contacts:", propertyContacts);

    return [
      {
        category: t("emergency.official"),
        contacts: officialContacts.map((contact) => ({
          name: contact.name,
          number: contact.number,
          icon: iconMap[contact.type],
          color: colorMap[contact.type],
          hasWhatsApp: contact.hasWhats === "true",
        })),
      },
      {
        category: t("emergency.property"),
        contacts: propertyContacts.map((contact) => ({
          name: contact.name,
          number: contact.number,
          icon: iconMap[contact.type],
          color: colorMap[contact.type],
          hasWhatsApp: contact.hasWhats === "true",
          notes: contact.notes !== "None" ? contact.notes : null,
        })),
      },
    ];
  };

  const handleCall = (number) => {
    window.location.href = `tel:${number}`;
  };

  const handleWhatsApp = (number) => {
    const formattedNumber = number.replace(/\D/g, "");
    window.open(`https://wa.me/${formattedNumber}`, "_blank");
  };

  const ContactCard = ({ contact }) => (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        border: `1px solid ${theme.palette.divider}`,
        transition: "all 0.2s ease",
        "&:hover": {
          borderColor: contact.color,
          transform: "translateY(-2px)",
          boxShadow: `0 4px 12px ${
            theme.palette.mode === "dark"
              ? "rgba(0, 0, 0, 0.3)"
              : "rgba(0, 0, 0, 0.1)"
          }`,
        },
      }}
    >
      <CardContent sx={{ p: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 40,
              height: 40,
              borderRadius: "50%",
              backgroundColor: `${contact.color}15`,
              color: contact.color,
            }}
          >
            {contact.icon}
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle1" component="div">
              {contact.name}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <PhoneIcon sx={{ fontSize: 16 }} />
              {contact.number}
            </Typography>
            {contact.notes && (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                {contact.notes}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
            }}
          >
            <Box
              onClick={() => handleCall(contact.number)}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 36,
                height: 36,
                borderRadius: "50%",
                backgroundColor: "#FF385C",
                color: "white",
                cursor: "pointer",
                transition: "all 0.2s ease",
                "&:hover": {
                  transform: "scale(1.1)",
                  backgroundColor: "#E31C5F",
                },
              }}
            >
              <PhoneIcon />
            </Box>
            {contact.hasWhatsApp && (
              <Box
                onClick={() => handleWhatsApp(contact.number)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 36,
                  height: 36,
                  borderRadius: "50%",
                  backgroundColor: "#25D366",
                  color: "white",
                  cursor: "pointer",
                  transition: "all 0.2s ease",
                  "&:hover": {
                    transform: "scale(1.1)",
                    backgroundColor: "#128C7E",
                  },
                }}
              >
                <WhatsAppIcon />
              </Box>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );

  const content = loading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="200px"
    >
      <CircularProgress sx={{ color: "#FF385C" }} />
    </Box>
  ) : error ? (
    <Alert severity="error">{error}</Alert>
  ) : (
    getEmergencyContacts().map((section, index) => (
      <Box key={index} sx={{ mb: 4 }}>
        <Typography
          variant="h6"
          component="div"
          sx={{
            mb: 2,
            color: theme.palette.text.primary,
            fontWeight: 500,
          }}
        >
          {section.category}
        </Typography>
        {section.contacts.map((contact, contactIndex) => (
          <ContactCard key={contactIndex} contact={contact} />
        ))}
      </Box>
    ))
  );

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={t("emergency.title", "Emergência")}
      icon={SupportIcon}
      useCustomHeader={true}
    >
      {content}
    </BaseDialog>
  );
};

export default EmergencyDialog;
