import { useState, useEffect } from 'react';
import { ref, get, child, getDatabase } from 'firebase/database';
import { useProperty } from '../contexts/PropertyContext';

export const usePropertyContact = (propertyId) => {
  const [contactData, setContactData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentLanguage } = useProperty();

  useEffect(() => {
    const fetchContactData = async () => {
      setLoading(true);
      setError(null);
      setContactData(null);

      if (!propertyId || propertyId === 'undefined') {
        console.error('Invalid propertyId:', propertyId);
        setError('Property ID is required');
        setLoading(false);
        return;
      }

      try {
        console.log('Fetching contact data for property:', propertyId);
        
        const dbRef = ref(getDatabase());
        const propertyPath = `properties/${propertyId}`;
        
        console.log('Fetching from path:', propertyPath);
        const propertySnapshot = await get(child(dbRef, propertyPath));
        
        if (propertySnapshot.exists()) {
          const propertyData = propertySnapshot.val();
          console.log('Property data found:', {
            hasLanguages: !!propertyData.languages,
            currentLanguage,
            hasEmergencyContacts: !!propertyData.languages?.[currentLanguage]?.property_config?.emergency?.contact
          });

          const contacts = propertyData.languages?.[currentLanguage]?.property_config?.emergency?.contact || [];
          
          console.log('Contact data structured:', {
            contactsCount: contacts.length
          });

          setContactData({
            emergency: {
              contact: contacts
            }
          });
        } else {
          console.error('Property not found:', propertyId);
          setError('Property not found');
        }
      } catch (err) {
        console.error('Error fetching contact data:', {
          error: err.message,
          propertyId
        });
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchContactData();
  }, [propertyId, currentLanguage]);

  return { contactData, loading, error };
};
