import React from "react";
import { Grid, Typography, Box, Button, useTheme } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import GuideCard from "./GuideCard";

const GuideList = ({
  guides,
  onManageUsers,
  onSync,
  onSelectGuide,
  currentPropertyId,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box sx={{ mt: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 600, color: "text.primary" }}
        >
          {t("settings.guide.title")}
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            {t("settings.guide.subtitle")}
          </Typography>
        </Typography>
        <Button
          variant="contained"
          startIcon={<EditIcon />}
          sx={{
            bgcolor: theme.palette.primary.main,
            color: "white",
            px: 3,
            py: 1,
            borderRadius: "50px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            "&:hover": {
              bgcolor: theme.palette.primary.dark,
              boxShadow: "0 6px 16px rgba(0, 0, 0, 0.2)",
              transform: "translateY(-1px)",
            },
            transition: "all 0.2s ease-in-out",
          }}
        >
          {t("settings.guide.addButton")}
        </Button>
      </Box>

      <Grid container spacing={3}>
        {Array.isArray(guides) &&
          guides.map((guide) => (
            <Grid item xs={12} sm={6} md={4} key={guide.id}>
              <GuideCard
                guide={guide}
                onManageUsers={onManageUsers}
                onSync={onSync}
                onSelectGuide={onSelectGuide}
                currentPropertyId={currentPropertyId}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default GuideList;
