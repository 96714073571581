import { ref, set, get, update } from 'firebase/database';
import { database } from '../config/firebase';

export async function setUserRole(uid, role = 'host') {
  try {
    const roleRef = ref(database, `users/${uid}/role`);
    await set(roleRef, role);
    return true;
  } catch (error) {
    console.error('Error setting user role:', error);
    return false;
  }
}

export async function getUserRole(uid) {
  try {
    const roleRef = ref(database, `users/${uid}/role`);
    const snapshot = await get(roleRef);
    return snapshot.exists() ? snapshot.val() : null;
  } catch (error) {
    console.error('Error getting user role:', error);
    return null;
  }
}

export async function initializeUserProperty(uid, propertyId, propertyData) {
  try {
    const propertyRef = ref(database, `users/${uid}/properties/${propertyId}`);
    
    // Verifica se a propriedade já existe
    const snapshot = await get(propertyRef);
    if (snapshot.exists()) {
      console.log('Property already exists, skipping initialization');
      return true;
    }

    const now = new Date().toISOString();
    const defaultPropertyStructure = {
      id: propertyId,
      name: propertyData.name || "",
      location: propertyData.location || "",
      createdAt: now,
      updatedAt: now,
      accessKey: {
        networkName: propertyData.accessKey?.networkName || "",
        networkPassword: propertyData.accessKey?.networkPassword || "",
        eKey: propertyData.accessKey?.eKey || ""
      },
      guestAccess: {},
      languages: {
        ptbr: {
          property_config: {
            electronicKey: {
              instructions: [
                "Digite o código no teclado numérico",
                "Pressione # para confirmar",
                "Aguarde a luz verde e gire a maçaneta"
              ],
              notes: [
                "O código é válido durante toda sua estadia",
                "Em caso de problemas, entre em contato com o proprietário"
              ]
            },
            emergency: {
              official: [
                {
                  name: "Polícia",
                  number: "190",
                  type: "police"
                },
                {
                  name: "Bombeiros",
                  number: "193",
                  type: "fire"
                },
                {
                  name: "Ambulância",
                  number: "192",
                  type: "ambulance"
                }
              ],
              contacts: []
            }
          },
          property_data: {
            name: propertyData.name || "",
            image: propertyData.image || "",
            description: propertyData.description || "",
            category: "Stays",
            amenities: [],
            location_details: [
              {
                title: propertyData.location || "",
                value: propertyData.locationDescription || ""
              }
            ]
          }
        },
        enus: {
          property_config: {
            electronicKey: {
              instructions: [
                "Enter the code on the numeric keypad",
                "Press # to confirm",
                "Wait for the green light and turn the handle"
              ],
              notes: [
                "The code is valid throughout your stay",
                "In case of problems, contact the owner"
              ]
            },
            emergency: {
              official: [
                {
                  name: "Police",
                  number: "190",
                  type: "police"
                },
                {
                  name: "Fire Department",
                  number: "193",
                  type: "fire"
                },
                {
                  name: "Ambulance",
                  number: "192",
                  type: "ambulance"
                }
              ],
              contacts: []
            }
          },
          property_data: {
            name: propertyData.name || "",
            image: propertyData.image || "",
            description: propertyData.description || "",
            category: "Stays",
            amenities: [],
            location_details: [
              {
                title: propertyData.location || "",
                value: propertyData.locationDescription || ""
              }
            ]
          }
        }
      }
    };

    await set(propertyRef, defaultPropertyStructure);
    return true;
  } catch (error) {
    console.error('Error initializing property:', error);
    return false;
  }
}

export async function initializeUserData(uid, userData) {
  try {
    const userRef = ref(database, `users/${uid}`);
    const snapshot = await get(userRef);

    // Se os dados já existem, não faz nada
    if (snapshot.exists()) {
      console.log('User data already exists, skipping initialization');
      return true;
    }

    // Inicializa apenas os dados básicos do usuário
    const basicUserData = {
      name: userData.name || '',
      email: userData.email || '',
      role: 'host',
      global_settings: {
        language: {
          defaultLanguage: "ptbr",
          enabledLanguages: ["ptbr", "enus", "esp"],
          lastUpdated: new Date().toISOString()
        }
      }
    };

    await set(userRef, basicUserData);
    return true;
  } catch (error) {
    console.error('Error initializing user data:', error);
    return false;
  }
}

export async function addGuestAccess(uid, propertyId, guestEmail, accessData) {
  try {
    const guestId = guestEmail.replace(/[.@]/g, '_');
    const guestAccessRef = ref(database, `users/${uid}/properties/${propertyId}/guestAccess/${guestId}`);
    
    await set(guestAccessRef, {
      accessGranted: accessData.accessGranted || new Date().toISOString(),
      accessExpires: accessData.accessExpires,
      status: 'active',
      email: guestEmail
    });

    // Adiciona referência cruzada na coleção guestAccess
    const guestPropertyRef = ref(database, `guestAccess/${guestId}/properties/${propertyId}`);
    await set(guestPropertyRef, {
      accessGranted: accessData.accessGranted || new Date().toISOString(),
      accessExpires: accessData.accessExpires,
      status: 'active'
    });

    return true;
  } catch (error) {
    console.error('Error adding guest access:', error);
    return false;
  }
}
